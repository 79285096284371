<!--
报告详情(pdf预览)
-->
<template>
  <a-modal title="文档预览" :visible="showVisiable" :footer="null" :width="950" @cancel="onClose">
    <VueDocPreview :value="docValue" :type="docType" />
    <template slot="footer">
      <a-button @click="onClose">关闭</a-button>
    </template>
  </a-modal>
</template>
<script>
import VueDocPreview from 'vue-doc-preview'
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 }
}
export default {
  name: 'PdfModal',
  components: {
    // pdf,
    VueDocPreview
  },
  props: ['docValue', 'docType'],
  data () {
    return {
      loading: false,
      formItemLayout,
      form: {},
      showVisiable: false,
      pdfSrc: '',
      numPages: null // pdf 总页数
    }
  },
  created () { },
  mounted () {
    // this.getPdfSrc()
  },
  methods: {
    onClose () {
      this.showVisiable = false
    }
  }
}
</script>
<style scoped></style>
