<template>
  <div class="anchor-container">
    <div >
        <ul>
          <li :class="['anchor-item']" v-for="item in itemList" :key="item.id">
            <a :class="['anchor-item-inner', item.id === selectedItem ? 'selected' : '']" @click="handleClick(item)">{{item.title}}</a>
          </li>
        </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthAnchor',
  components: {
  },
  props: {
    itemList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      selectedItem: ''
    }
  },
  created () {},
  mounted () {
  },
  watch: {
  },
  methods: {
    handleClick (item) {
      const element = document.getElementById(item.id)
      element.scrollIntoView({ behavior: 'smooth' })
      this.selectedItem = item.id
    }
  }
}
</script>

<style lang="less" scoped>
.anchor-container {
  width: 300px;
  border-right: 1px solid #E0EAF3;
  height: 100%;
  margin-right: 24px;
  padding-top: 30px;
  overflow: auto;
}
.anchor-item {
  padding-top: 24px;
  font-size: 16px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0px;
  color: #13A8BF;
  &-inner {
    color: #0B2850;
  }
  &-inner:hover {
    color: #13A8BF;
  }
}

.selected {
  color: #13A8BF;
}

ul {
  list-style: initial;
  padding-left: 40px;
}
</style>
