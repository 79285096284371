<!--
背调列表
-->
<template>
  <div class="container">
    <div class="package-usage-title">
      <div class="package-title">背调项占比</div>
    </div>
    <div class="package-usage-item">
      <div class="date-query">
        <a-range-picker
          :show-time="{ format: 'HH:mm' }"
          format="YYYY-MM-DD HH:mm"
          :placeholder="['开始时间', '结束时间']"
          v-model="dateTime"
          @change="handleTimeClear"
          @ok="handleTimeChange"
        />
        <a-button class="search-btn btn" type="primary" @click="search"
          >查询</a-button
        >
        <a-button class="btn" @click="handleExport">导出</a-button>
      </div>
      <!--  -->
      <div
        id="riskCheckChart"
        :style="{ zoom: '125%' }"
        class="platform-item"
      ></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as echarts from 'echarts'
export default {
  name: 'RiskCheckItems',
  components: {
  },
  props: {
    chartData: {
      type: Object,
      default: () => { },
    },
  },
  data () {
    return {
      dateTime: [],
      riskCheckChart: null
    }
  },
  computed: {
  },
  mounted () {
    setTimeout(() => {
      this.drawLine()
    }, 200)
  },
  methods: {
    drawLine () {
      // 基于准备好的dom，初始化echarts实例
      let riskCheckChart = this.$echarts.init(document.getElementById('riskCheckChart'))
      this.riskCheckChart = riskCheckChart
      const option = {
        tooltip: {
          trigger: 'item',
          formatter (name) {
            return (
              `${name.seriesName}<br/>${name.marker}${name.name}${name.value}%`
            )
          }
        },
        legend: {
          //   top: '5%',
          //   left: 'center',
          bottom: 16,
        },
        xAxis: {
          type: 'category',
          // data: ['连续民事诉讼', '公民身份验证', '中国护照核查', '工作履历', '工作表现', '驾驶证核查', '海外教育背景核查']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '背调项占比',
            type: 'bar',
            barWidth: 22,
            data: [33, 33, 33],
            itemStyle: {
              color: function (params) {
                var colors = ['#46B8FF', '#0CD9B5', '#6E5EFF', '#FFA444', '#CA7BFF', '#496D98', '#FFEA32', '#8BFFF0']   // 自定义颜色列表

                return colors[params.dataIndex]   // params.dataIndex 为当前柱子对应的索引位置
              }
            },
          }
        ]
      }
      option.xAxis.data = this.chartData.riskCheckItemsList.map(item => {
        return item.itemName
      })
      option.series[0].data = this.chartData.riskCheckItemsList.map(item=>{
        return item.riskItemPercent
      })
      // 绘制图表
      riskCheckChart.setOption(option)
    },
    handleTimeClear () {

    },
    handleTimeChange () {

    },
    search () {

    },
    handleExport () {
      const img = new Image()
      img.src = this.riskCheckChart.getDataURL({
        type: 'png',
        pixelRatio: 1,
        backgroundColor: '#fff'
      })
      img.onload = function () {
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0)
        const dataUrl = canvas.toDataURL('image/png')
        const a = document.createElement('a')
        const event = new MouseEvent('click')
        a.download = "背调项占比.png"
        a.href = dataUrl
        a.dispatchEvent(event)
        a.remove()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  .package-usage-title {
    display: flex;
    flex-direction: row;
    color: #0b2850;
    font-size: 18px;
    font-weight: bold;
    .package-title {
      margin-bottom: 12px;
    }
    .package-title:last-child {
      margin-right: 0;
    }
  }
  .package-usage-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px rgba(112, 144, 176, 0.12);
    .date-query {
      padding: 16px 32px 2px 0px;
      text-align: right;
      .search-btn {
        margin: 0 8px 0px 16px;
      }
      .btn {
        width: 88px;
      }
    }
    .platform-item {
      width: 100%;
      height: 400px;
    }
  }
}
</style>
