<template>
  <div class="login-container">
    <a-menu v-model="current" mode="horizontal">
      <a-menu-item key="psw" v-if="role !== 'candidate'">密码登录</a-menu-item>
      <a-menu-item key="msg">{{role === 'candidate' ? '验证码登录' : '短信验证码登录'}}</a-menu-item>
    </a-menu>
    <a-form v-if="current[0] === 'psw'" @submit.prevent="handleLogin" :form="pswForm">
      <a-form-item v-for="item in loginWithPswForm" :key="item.key">
          <a-input
            :style="{ borderRadius: '8px !important' }"
            size="large"
            :type="item.type"
            :placeholder="item.placeholder"
            v-decorator="[
              item.key,
              {
                rules: item.rules,
              },
            ]"
          >
          </a-input>
        </a-form-item>
    </a-form>
    <a-form v-if="current[0] === 'msg'" @submit.prevent="handleLogin" :form="msgForm">
        <a-form-item :key="loginWithMsgForm[0].key">
          <a-input
            :style="{ borderRadius: '8px !important' }"
            size="large"
            :placeholder="loginWithMsgForm[0].placeholder"
            v-decorator="[
              loginWithMsgForm[0].key,
              {
                rules: loginWithMsgForm[0].rules,
              },
            ]"
          >
          </a-input>
        </a-form-item>
        <div class="validate-code-wrapper">
          <a-form-item :key="loginWithMsgForm[1].key">
            <a-input
              :style="{ borderRadius: '8px !important' }"
              size="large"
              :placeholder="loginWithMsgForm[1].placeholder"
              v-decorator="[
                loginWithMsgForm[1].key,
                {
                  rules: loginWithMsgForm[1].rules,
                },
              ]"
            >
            </a-input>
          </a-form-item>
          <a-button :loading="codeLoading" :disabled="codeFlag" class="validate-code-btn" size="large" type="primary" @click="getValidateCode">获取验证码 {{ codeFlag ? `(${codeTime})` : "" }}</a-button>
        </div>
    </a-form>
    <div class="options">
      <a-checkbox :checked="nextTimeAutoLogin" @change="onChange">下次自动登录</a-checkbox>
      <a  v-if="current[0] === 'psw'" @click="handleForgetPassword">忘记密码?</a>
    </div>
    <a-button :loading="loading" @click="handleLogin" class="login-button">{{role === "personal" ? "个人" : role === "enterprise" ? "" : "候选人"}}登录</a-button>
    <div v-if="role !== 'candidate'" class="login-register-text">没有帐号？<a :href="`/#/register?role=${role}`">直接注册</a></div>
    <!-- <div v-else class="login-register-text">无账号用户，首次登录即默认为注册用户</div> -->
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'LoginInfoForm',
  props: {
    role: {
      type: String,
      default: ''
    }
  },
  mounted () {
    if (this.role === 'candidate') {
      this.current = ['msg']
    }
  },
  data () {
    return {
      current: ['psw'],
      pswForm: this.$form.createForm(this),
      msgForm: this.$form.createForm(this),
      nextTimeAutoLogin: false,
      loading: false,
      codeFlag: false,
      codeTime: 60,
      codeEvent: null,
      codeLoading: false,
      loginWithPswForm: [
        {
          key: 'username',
          placeholder: '请输入手机号或邮箱账号',
          rules: [
            { required: true, message: '请输入手机号或邮箱账号', whitespace: true, trigger: 'blur' }
          ]
        },
        {
          key: 'password',
          placeholder: '请输入密码',
          type: 'password',
          rules: [
            { required: true, message: '请输入密码', whitespace: true }
          ]
        }
      ],
      loginWithMsgForm: [
        {
          key: 'username',
          placeholder: this.role === 'candidate' ? '请输入邮箱' : '请输入手机号',
          rules: [
            { required: true, message: this.role === 'candidate' ? '请输入邮箱' : '请输入手机号', whitespace: true, trigger: 'blur' }
          ]
        },
        {
          key: 'password',
          placeholder: '请输入验证码',
          rules: [
            { required: true, message: '请输入验证码', whitespace: true }
          ]
        }
      ]
    }
  },
  methods: {
    handleForgetPassword () {
      this.$emit('forget', true)
    },
    onChange (e) {
      this.nextTimeAutoLogin = e.target.checked
    },
    getValidateCode () {
      if (this.codeEvent) {
        clearInterval(this.codeEvent)
        this.codeTime = 60
      }
      const mobile = this.msgForm.getFieldValue('username')
      this.msgForm.validateFields(['username'], (errors, values) => {
        if (errors) return
        this.codeLoading = true
        if (this.role === 'candidate') {
          this.$get('web/login/sendEmailLoginVerificationCode/' + mobile, {})
            .then((r) => {
              if (r.data.code !== 200) {
                this.$message.error(r.data.message)
                this.codeLoading = false
                return
              }
              this.$message.success(r.data.message)
              this.codeLoading = false
              this.codeFlag = true
              this.codeInterval()
            })
            .catch((e) => {
              console.error(e)
              this.codeLoading = false
            })
        } else {
          this.$get('web/login/sendMobileLoginVerificationCode/' + mobile, {})
            .then((r) => {
              if (r.data.code !== 200) {
                this.$message.error(r.data.message)
                this.codeLoading = false
                return
              }
              this.$message.success(r.data.message)
              this.codeLoading = false
              this.codeFlag = true
              this.codeInterval()
            })
            .catch((e) => {
              console.error(e)
              this.codeLoading = false
            })
        }
      })
    },
    codeInterval () {
      this.codeEvent = setInterval(() => {
        if (this.codeTime === 0) {
          clearInterval(this.codeEvent)
          this.codeTime = 60
          this.codeFlag = false
        } else {
          this.codeTime--
        }
      }, 1000)
    },
    handleLogin () {
      const form = (this.current[0] === 'msg') ? this.msgForm : this.pswForm
      form.validateFields((errors, values) => {
        let loginType
        if (errors) {
          return
        }
        this.loading = true
        if (this.current[0] === 'msg') {
          loginType = 2
        } else {
          if (values.username.includes('@')) {
            loginType = 0
          } else {
            loginType = 1
          }
        }
        const params = {
          loginType,
          account: values.username,
          password: loginType === 2 ? values.password : this.$aesEncrypt.encrypt(values.password)
        }
        this.$post('web/login/login', params).then((r) => {
          const data = r.data.data
          this.saveLoginData(data)
          setTimeout(() => {
            this.loading = false
          }, 500)
          if (this.role !== 'candidate') {
            this.getDicts()
          }
          this.$router.push('/')
        }).catch((e) => {
          console.error(e)
          setTimeout(() => {
            this.loading = false
          }, 500)
        })
      })
    },
    saveLoginData (data) {
      this.$db.clear()
      this.setToken(data.token)
      this.setExpireTime(data.exipreTime)
      this.setUser(data.user)
      this.setPermissions(data.permissions)
      this.setRoles(data.roles)
      this.setTheme(data.userConfig.theme)
      this.setLayout(data.userConfig.layout)
      this.setMultipage(data.userConfig.multiPage === '1')
      this.fixSiderbar(data.userConfig.fixSiderbar === '1')
      this.fixHeader(data.userConfig.fixHeader === '1')
      this.setColor(data.userConfig.color)
    },
    ...mapMutations({
      setToken: 'account/setToken',
      setExpireTime: 'account/setExpireTime',
      setPermissions: 'account/setPermissions',
      setRoles: 'account/setRoles',
      setUser: 'account/setUser',
      setTheme: 'setting/setTheme',
      setLayout: 'setting/setLayout',
      setMultipage: 'setting/setMultipage',
      fixSiderbar: 'setting/fixSiderbar',
      fixHeader: 'setting/fixHeader',
      setColor: 'setting/setColor',
      setDicts: 'dict/setDicts'
    }),
    getDicts () {
      this.$get('dict/trim').then((r) => {
        const data = r.data
        this.saveDictData(data)
      }).catch((e) => {
        console.error(e)
      })
    },
    saveDictData (data) {
      this.setDicts(data)
    }
  },
  computed: {

  }

}
</script>

<style lang="less" scoped>
.login-container {
  /deep/ .ant-form-item {
    margin-top: 24px !important;
    margin-bottom: 0px !important;
    input {
      background: #F3F6FF;
    }
  }
  .validate-code-wrapper {
    display: flex;
    justify-content: space-between;
    .ant-form-item {
      flex-grow: 1 !important;
    }
    .validate-code-btn {
      min-width: 120px;
      margin-left: 16px;
      margin-top: 24px !important;
      border-radius: 8px !important;
    }
  }
  .options {
    margin-top: 24px;
    text-align: right;
    display: flex;
    justify-content: space-between;
  }
  .login-button {
    margin-top: 40px;
    width: 100%;
    height: 50px;
    border-radius: 32px !important;
    opacity: 1;

    color: white;
    font-size: 18px !important;
    font-weight: 500;
    line-height: 18px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 0px;
    gap: 8px;

    /* logo绿 */
    background: #13A8BF;
  }
  .login-register-text {
      margin-top: 12px;
      font-size: 16px;
      font-weight: normal;
      line-height: 16px;
      letter-spacing: 0px;

      /* 没有帐号？ */
      color: #6E7070;

      span {
        /* 直接注册 */
        color: #13A8BF
      }
    }
}
</style>
