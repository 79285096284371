<template>
  <div class="identity-verification">
    <div class="identity-verification-warp">
      <h1 class="title">公民身份验证</h1>
      <a-form layout="vertical">
        <a-form-item v-bind="formItemLayout" label="姓名">
          <a-input
            style="width: 100%"
            v-model="form.name"
            placeholder="请输入姓名"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="手机号">
          <a-input
            style="width: 100%"
            v-model="form.mobile"
            placeholder="请输入手机号"
          />
        </a-form-item>
      </a-form>
    </div>
    <!-- <span class="my-primary-color" @click="show"
        >《候选人信息收集协议》</span
      > -->
    <a-checkbox v-model="checkFlag" class="bottom_mar"
      >您已知晓并同意<span class="my-primary-color" @click="showPri"
        >《隐私保护协议》</span
      ></a-checkbox
    >
    <div class="submit-btn" @click="submit">提交</div>
    <TextModal ref="textRef" />
  </div>
</template>

<script>
import TextModal from '../manage/startBack/components/TextModal.vue'
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

export default {
  name: 'IdentityVerification',
  components: { TextModal },
  data () {
    return {
      formItemLayout,
      form: {},
      checkFlag: false,
    }
  },
  methods: {
    submit () {
      if (!this.checkFlag) {
        this.$message.warning('请勾选同意候选人信息收集协议议')
      } else {
        this.form.code = this.$route.query.code
        this.$post('v1/autoRecording/identity', this.form)
          .then((r) => {
            if (r.data.data.result) {
              this.$message.success('认证成功')
              setTimeout(() => {
                this.$router.push(
                  `/mrecord?identity=identity&code=${this.$route.query.code}`
                )
              }, 1000)
            } else {
              this.$message.error('认证失败')
            }
          })
          .catch(() => { })
      }
    },
    // 查看隐私协议
    showPri () {
      this.$get('web/platform-param/selectByKey/policyProtect')
        .then((r) => {
          const data = r.data.data
          this.$refs.textRef.text = data.paramDetail
          this.$refs.textRef.title = data.paramName
          this.$refs.textRef.visiable = true
        })
        .catch(() => { })
    },
  }
}
</script>

<style scoped lang="less">
.identity-verification {
  padding: 40px 50px 0 50px;
  .identity-verification-warp {
    border: 1px solid #999;
    border-radius: 8px;
    padding: 15px;
    .title {
      font-size: 15px;
      margin-bottom: 20px;
    }
  }
  .submit-btn {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #13a8bf;
    margin-top: 30px;
    border-radius: 30px;
  }
   .bottom_mar {
    margin-top: 15px;
    font-size: 15px;
    text-align: center;
  }
}
</style>
