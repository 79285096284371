<template>
  <div class="modal" v-if="show">
    <div class="modal-mask" @click="close"></div>
    <div class="modal-content">
      <div class="modal-title">
        <div class="text">{{ title }}</div>
      </div>
      <div class="modal-body">
        <slot></slot>
      </div>
      <div class="modal-footer">
        <a-button class="btn btn-1" @click="onClickBtn1">{{ btn1 }}</a-button>
        <a-button class="btn btn-2" @click="onClickBtn2">{{ btn2 }}</a-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MModal',
  props: ['title', 'btn1', 'btn2'],
  data () {
    return {
      show: false
    }
  },
  methods: {
    close () {
      this.show = false
      this.$emit('close')
    },
    open () {
      this.show = true
    },
    onClickBtn1 () {
      this.$emit('click1')
    },
    onClickBtn2 () {
      this.$emit('click2')
    }
  }
}
</script>

<style scoped lang="less">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100vw;
  height: 100%;
  background: rgba(16, 28, 46, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .modal-mask {
    flex-grow: 1;
  }
  .modal-content {
    bottom: 0;
    width: 100%;
    min-height: 215px;
    border-radius: 16px 16px 0px 0px;
    opacity: 1;

    background: #ffffff;

    box-shadow: 0px 0px 6px 0px rgba(186, 192, 193, 0.3);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 30px;
    padding-top: 20px;
    .modal-title {
      font-size: 17px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0px;

      /* 文字1 */
      color: #0b2850;
      // padding-top: 10px;
      // padding-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .modal-body {
      width: 100%;
    }
    .modal-footer {
      display: flex;
      .btn {
        height: 41px !important;
        border-radius: 32px !important;
        text-align: center;
        width: 174px !important;

        font-size: 17px !important;
        font-weight: normal;
        line-height: 17px;
        text-align: center;
        letter-spacing: 0px;
      }
      .btn-2 {
        background: #13a8bf;
        color: white;
        width: 80px;
        margin-left: 15px;
      }
      .btn-1 {
        box-sizing: border-box;
        /* logo绿 */
        border: 1px solid #13a8bf;
        color: #13a8bf;
      }
    }
  }
}
</style>
