<template>
  <div class="forget-container">
    <a-form @submit.prevent="handleLogin" :form="forgetForm">
            <div v-for="item in forgetFormModel" :key="item.key">
              <div class="form-label">
                <span style="color: red">* </span>{{ item.name }}
              </div>
              <a-form-item v-if="item.key !== 'code'">
                <a-input
                  :style="{ borderRadius: '8px !important' }"
                  size="large"
                  :type="item.type"
                  :placeholder="item.placeholder"
                  v-decorator="[
                    item.key,
                    {
                      rules: item.rules,
                    },
                  ]"
                >
                </a-input>
              </a-form-item>
              <div class="validate-code-wrapper" v-else>
                <a-form-item :key="item.key">
                  <a-input
                    :style="{ borderRadius: '8px !important' }"
                    size="large"
                    :placeholder="item.placeholder"
                    v-decorator="[
                      item.key,
                      {
                        rules: item.rules,
                      },
                    ]"
                  >
                  </a-input>
                </a-form-item>
                <a-button :loading="codeLoading" :disabled="codeFlag" class="validate-code-btn" size="large" type="primary" @click="getValidateCode">获取验证码 {{ codeFlag ? `(${codeTime})` : "" }}</a-button>
              </div>
            </div>
          </a-form>
          <a-button
            :loading="loading"
            @click="handleResetPassword"
            class="login-button"
            >提交修改</a-button
          >
  </div>
</template>

<script>

export default {
  name: 'LoginInfoForm',
  props: {
    role: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      forgetForm: this.$form.createForm(this),
      nextTimeAutoLogin: false,
      loading: false,
      codeFlag: false,
      codeTime: 60,
      codeEvent: null,
      codeLoading: false,
      forgetFormModel: [
        {
          key: 'phoneOrEmail',
          name: '手机号或邮箱',
          placeholder: '请输入手机号或邮箱',
          rules: [
            {
              required: true,
              message: '请输入手机号或邮箱',
              whitespace: true,
              trigger: 'blur'
            }
          ]
        },
        {
          key: 'code',
          name: '验证码',
          placeholder: '请输入验证码',
          rules: [
            { required: true, message: '请输入验证码', whitespace: true }
          ]
        },
        {
          key: 'password',
          name: '新密码',
          placeholder: '请输入新密码',
          type: 'password',
          rules: [
            { required: true, message: '请输入新密码', whitespace: true },
            { validator: this.handlePasswordLevel }
          ]
        },
        {
          key: 'passwordAgain',
          name: '确认新密码',
          placeholder: '请再次输入新密码',
          type: 'password',
          rules: [
            { required: true, message: '请再次输入新密码', whitespace: true },
            { validator: this.handlePasswordCheck }
          ]
        }
      ]
    }
  },
  methods: {
    handleForgetPassword () {
      this.$emit('forget')
    },
    onChange (e) {
      this.nextTimeAutoLogin = e.target.checked
    },
    getValidateCode () {
      if (this.codeEvent) {
        clearInterval(this.codeEvent)
        this.codeTime = 60
      }
      const contacts = this.forgetForm.getFieldValue('phoneOrEmail')
      if (contacts === undefined) {
        this.$message.warning('请输入手机号或邮箱')
      }
      this.forgetForm.validateFields(['phoneOrEmail'], (errors, values) => {
        if (!errors) {
          this.codeLoading = true
          let url
          if (contacts.includes('@')) {
            url = 'web/user/sendForgetPwdEmailVerificationCode/' + contacts
          } else {
            url = 'web/user/sendForgetPwdMobileVerificationCode/' + contacts
          }
          this.$get(url, {})
            .then((r) => {
              if (r.data.code !== 200) {
                this.$message.error(r.data.message)
                this.codeLoading = false
                return
              }
              this.$message.success(r.data.message)
              this.codeLoading = false
              this.codeFlag = true
              this.codeInterval()
            })
            .catch((e) => {
              console.error(e)
              this.codeLoading = false
            })
        }
      })
    },
    codeInterval () {
      this.codeEvent = setInterval(() => {
        if (this.codeTime === 0) {
          clearInterval(this.codeEvent)
          this.codeTime = 60
          this.codeFlag = false
        } else {
          this.codeTime--
        }
      }, 1000)
    },
    handleResetPassword () {
      this.forgetForm.validateFields((errors, { phoneOrEmail, code, password }) => {
        if (!errors) {
          this.loading = true
          const params = {
            password: this.$aesEncrypt.encrypt(password),
            verificationCode: code
          }

          if (phoneOrEmail.includes('@')) {
            params.email = phoneOrEmail
            params.verificationType = 1
          } else {
            params.mobile = phoneOrEmail
            params.verificationType = 0
          }

          this.$post('web/user/forgetPwd', params).then((r) => {
            this.$message.success('修改成功')
            setTimeout(() => {
              this.loading = false
              this.$emit('forget', false)
            }, 500)
          }).catch((e) => {
            this.$message.error(e.message)
            setTimeout(() => {
              this.loading = false
            }, 500)
          })
        }
      })
    },
    handlePasswordLevel (rule, value, callback) {
      let level = 0

      // 判断这个字符串中有没有数字
      if (/[0-9]/.test(value)) {
        level++
      }
      // 判断字符串中有没有字母
      if (/[a-zA-Z]/.test(value)) {
        level++
      }
      // 判断字符串中有没有特殊符号
      if (/[^0-9a-zA-Z_]/.test(value)) {
        level++
      }
      // let percent = level * 30
      if (level >= 2 && value.length >= 8) {
        // if (level >= 3) {
        //   percent = 100
        // }
        callback()
      } else {
        // if (level === 0 || level === 1 || value.length < 8) {
        //   percent = 10
        // }
        callback(
          new Error(
            '密码强度不够，需要8位以上密码并包含数字、英文字母或特殊符号中的两种'
          )
        )
      }
    },
    handlePasswordCheck (rule, value, callback) {
      const password = this.forgetForm.getFieldValue('password')
      // if (value === undefined) {
      //   callback(new Error('请输入密码'))
      // }
      if (value && password && value.trim() !== password.trim()) {
        callback(new Error('两次密码不一致'))
      }
      callback()
    }

  },
  computed: {

  }

}
</script>

<style lang="less" scoped>
.forget-container {
        display: flex;
        flex-direction: column;
        .form-label {
          margin-bottom: 8px;
          font-size: 14px;
          font-weight: normal;
          line-height: 14px;
          letter-spacing: 0px;

          /* 文字2 */
          color: #6e7070;
        }
        .validate-code-wrapper {
          display: flex;
          justify-content: space-between;
          .ant-form-item {
            flex-grow: 1 !important;
          }
          .validate-code-btn {
            min-width: 120px;
            margin-left: 16px;
            border-radius: 8px !important;
          }
        }
        .login-button {
          margin-top: 24px;
          width: 100%;
          height: 50px;
          border-radius: 32px !important;
          opacity: 1;

          color: white;
          font-size: 18px !important;
          font-weight: 500;
          line-height: 18px;

          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 16px 0px;
          gap: 8px;

          /* logo绿 */
          background: #13a8bf;
        }
        .text-below-btn {
          margin-top: 12px;
          font-size: 16px;
          font-weight: normal;
          line-height: 16px;
          letter-spacing: 0px;

          /* 文字2 */
          color: #6E7070;
        }
    }
</style>
