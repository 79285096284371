<!-- 用户信息授权填写 -->
<template>
  <div class="container_">
    <div class="header">
      <div class="title">
        <img src="../../assets/img/logo.png" style="height: 30px" />
        <div class="my-margin-l-10">求职问是 雇佣归真</div>
      </div>
    </div>
    <a-spin :spinning="loading">
      <div class="wrapper">
        <div class="header2">
          <div class="title">个人授权信息表</div>
        </div>
        <div class="container-1">
          <div class="content-1">
            <anchor :itemList="anchorItems"></anchor>
            <!-- <a-anchor @click="handleClickAnchor" :offsetTop="100">
                <a-anchor-link
                  v-for="(item, index) in anchorItems"
                  :key="item.itemKey"
                  :href="`#${item.itemKey}`"
                  :title="item.itemName"
                />

            </a-anchor> -->
          </div>
          <div class="content-2">
            <div
              v-for="(item, index) in checkItems"
              :key="index"
              style="width: 100%"
            >
              <div v-if="item.candidateFillIns">
                <InfoBox
                  v-show="!item.hide"
                  :title="item.itemName"
                  :remark="item.remark"
                  :id="item.itemKey"
                  :candidateFillInsList="item.candidateFillIns"
                  :index="index"
                  @change="infoChange($event, index)"
                />
              </div>
            </div>
            <a-checkbox v-model="checkFlag"
              >您已知晓并同意<span class="my-primary-color" @click="show"
                >《候选人信息收集协议》</span
              ><span class="my-primary-color" @click="showPri"
                >《隐私政策》</span
              ></a-checkbox
            >
            <a-space>
              <a-button style="width: 150px" @click="submitTemp"
                >保存草稿</a-button
              >
              <a-button type="primary" style="width: 150px" @click="check"
                >确认提交并签名</a-button
              >
            </a-space>
          </div>
        </div>
      </div>
    </a-spin>

    <TextModal ref="textRef" />
  </div>
</template>

<script>
import TextModal from '../manage/startBack/components/TextModal.vue'
import Auth from '../reportAuth/components/Auth.vue'
import BaseInfo from '../reportAuth/components/BaseInfo.vue'
import EduBg from '../reportAuth/components/EduBg.vue'
import WorkEx from '../reportAuth/components/WorkEx.vue'
import InfoBox from './components/InfoBox.vue'
import Anchor from './components/Anchor.vue'
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 }
}
export default {
  name: 'ReportAuth',
  components: { BaseInfo, EduBg, WorkEx, Auth, InfoBox, TextModal, Anchor },
  data () {
    return {
      formItemLayout,
      reportUuid: '',
      checkItems: [],
      originReportInfo: {},
      checkFlag: false,
      loading: false
    }
  },
  created () { },
  mounted () {
    console.log('进入PC端')
    document.body.style.zoom = 1
    if (this.$route.query.reportUuid) {
      console.log('进入PC端id')
      this.reportUuid = this.$route.query.reportUuid
      this.checkReport()
    }
    // 在线客服代码
    var _hmt = _hmt || [];
    (function () {
      var hm = document.createElement("script")
      hm.src = "https://hm.baidu.com/hm.js?f1622e57234423a9472e0a7240c52c6a"
      var s = document.getElementsByTagName("script")[0]
      s.parentNode.insertBefore(hm, s)
    })()
  },
  computed: {
    anchorItems () {
      return this.checkItems.filter((item) => {
        return !item.hide
      }).map((item) => ({
        id: item.itemKey,
        title: item.itemName
      }))
    }
  },
  methods: {
    handleClickAnchor (e, link) {
      e.preventDefault()
      console.log(link)
    },
    // 查看协议
    show () {
      this.$get('web/check-report/getCollectionAgreement/' + this.reportUuid)
        .then((r) => {
          const data = r.data.data
          this.$refs.textRef.text = data.paramDetail
          this.$refs.textRef.title = data.paramName
          this.$refs.textRef.visiable = true
        })
        .catch(() => { })
    },
    // 查看隐私协议
    showPri () {
      this.$get('web/platform-param/selectByKey/privacyPolicy')
        .then((r) => {
          const data = r.data.data
          this.$refs.textRef.text = data.paramDetail
          this.$refs.textRef.title = data.paramName
          this.$refs.textRef.visiable = true
        })
        .catch(() => { })
    },
    // 获取报告信息
    checkReport () {
      const that = this
      this.loading = true
      this.$get(
        'web/check-report/selectFillInCheckReportByCandidate/' + that.reportUuid
      )
        .then((r) => {
          const data = r.data.data
          this.loading = false
          this.originReportInfo = data
          this.checkItems = JSON.parse(JSON.stringify(data.checkItems))
          switch (data.fillInStatus) {
            case 4:
              that.$warning({
                title: '当前报告尚未完成易签宝未授权',
                content: '是否前往授权？',
                okText: '前往授权',
                okType: 'danger',
                keyboard: false,
                maskClosable: false,
                onOk () {
                  that.getPsnAuthUrl()
                }
              })
              break
            case 5:
              this.loading = true
              that.$message.info('已完成该核查报告填写')
              setTimeout(() => {
                this.loading = false
                window.open('https://www.checkey.net/#/webHome', '_self')
              }, 1000)
              break
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 获取e签宝链接
    getPsnAuthUrl () {
      this.$get('web/check-report/getSignUrl/' + this.reportUuid)
        .then((r) => {
          const data = r.data.data
          window.open(data, '_self')
        })
        .catch(() => { })
    },
    // 核查项信息变更
    infoChange (e, index) {
      this.checkItems[index].candidateFillIns = e
    },
    // 勾选协议校验
    check () {
      console.log(this.checkItems)
      if (!this.checkFlag) {
        this.$message.warning('请勾选同意候选人信息收集协议议')
      } else {
        this.checkSub()
      }
    },
    // 判断证明人是否有重复数据
    isRepeat (arr, key) {
      const obj = {}
      for (let i = 0; i < arr.length; i++) {
        if (obj[arr[i][key]]) {
          return false //有重复
        } else {
          obj[arr[i][key]] = arr[i]
        }
      }
      return true
    },
    checkSub () {
      this.checkItems.forEach((e1) => {
        if (e1.candidateFillIns) {
          e1.candidateFillIns.forEach((e2) => {
            let randomInterviewFlag = false
            if (e2.candidateNecessaryInfos) {
              const arr = e2.candidateNecessaryInfos.filter(item => item.key === 'certifier')
              if (arr.length > 0) {
                if (this.isRepeat(arr, 'candidateFillIn')) {
                  console.log('没有重复')
                } else {
                  console.log('有重复')
                  this.$message.error('证明人不能重复')
                  throw "error" // eslint-disable-line
                }
              }
              e2.candidateNecessaryInfos.forEach((e3) => {
                if (e3.key === 'random_interview' && e3.candidateFillIn === 'true') {
                  randomInterviewFlag = true
                }
                if (randomInterviewFlag &&
                  (e3.key === 'work_time_begin_date' ||
                    e3.key === 'work_time_end_date')) {
                  e3.candidateFillIn = ''
                }
                if (randomInterviewFlag &&
                  (e3.key === 'work_relationship' ||
                    e3.key === 'certifier' ||
                    e3.key === 'certifier_mobile' ||
                    e3.key === 'certifier_position' ||
                    e3.key === 'work_time' ||
                    e3.key === 'work_time_begin_date' ||
                    e3.key === 'work_time_end_date')) {
                  return
                }
                if (!e2.autoSearchCertifier && e3.key === 'random_interview') {
                  return
                }
                if (!this.$checkIsNotNull(e3.candidateFillIn)) {
                  this.$message.error('请补充完全信息: ' + e3.name)
                  throw "error" // eslint-disable-line
                }
              })
            }

          })
        }
      })
      this.handleSubmit()
    },
    submitTemp () {
      this.loading = true
      this.$post('web/check-report/userFillInStaging', {
        uuid: this.reportUuid,
        checkItems: this.checkItems
      })
        .then((r) => {
          if (r.data.code === 200) {
            this.$message.success('保存成功')
            setTimeout(() => {
              this.loading = false
              // this.$router.push('home')
              // window.open('https://www.checkey.net/#/webHome', '_self')
            }, 1000)
          }
        })
        .catch((data) => {
          this.loading = false
          this.$message.error(data.message)
        })
    },
    // 提交报告信息
    handleSubmit () {
      this.loading = true
      this.$post('web/check-report/userFillInReport', {
        uuid: this.reportUuid,
        checkItems: this.checkItems
      })
        .then((r) => {
          if (r.data.code === 200) {
            this.$message.success('提交成功')
            setTimeout(() => {
              this.loading = false
              // this.$router.push('home')
              console.log('打印数据')
              console.log(r.data.data)
              window.open(r.data.data, '_self')
              // window.open('https://checkey.web.yuntaitec.com/#/webHome', '_self')
            }, 1000)
          }
        })
        .catch((data) => {
          this.loading = false
          this.$message.error(data.message)
        })
    },
    anchorContainer: () => {
      console.log('container:', document.getElementsByClassName('content-2')[0])
      return document.getElementsByClassName('content-2')[0]
    }
  }
}
</script>

<style lang="less" scoped>
.container_ {
  display: flex;
  flex-direction: column;
  // height: 100vh;
  overflow: auto;
  background-size: 100%;
  background: #f3f6ff;
  .header {
    width: 100%;
    height: 48px;
    padding: 4px 20px;
    background: #002853;
    .title {
      font-size: 20px;
      color: #fff;
      line-height: 40px;
      display: flex;
      align-items: center;
    }
  }
  .header2 {
    width: 100%;
    height: 50px;
    padding: 15px 20%;
    background: #248aa3;
    border-radius: 12px 12px 0 0;

    .title {
      text-align: center;
      font-size: 20px;
      color: #fff;
      line-height: 20px;
    }
  }
  .content-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow: auto;
    background: white;
    padding: 24px;
    flex-grow: 1;
  }
  .wrapper {
    margin: 24px 15%;
  }
  .container-1 {
    display: flex;
    flex-direction: row;
    // background: #f0f0f0;
    background: white;
    height: calc(100vh - 180px);
    overflow: auto;
    background-clip: content-box;
    border-radius: 0 0 12px 12px;
  }
}
</style>
