<template>
  <div>
    <a-dropdown
      style="display: inline-block; height: 100%; vertical-align: initial"
      @visibleChange="dropShow"
      placement="bottomRight"
    >
      <a-badge :count="$store.state.account.messageNum">
        <span style="cursor: pointer">
          <!-- <a-badge :count="$store.state.account.messageNum"></a-badge> -->
          <img src="../../assets/img/remind.svg" />
        </span>
      </a-badge>
      <a-menu style="width: 350px" slot="overlay">
        <a-spin :spinning="loading">
          <div v-if="messageList.length > 0">
            <div v-for="(item, index) in messageList" :key="index">
              <a-menu-item class="messageBox" @click="detail(item)">
                <div>
                  <div class="my-flex my-item-jc-sb">
                    <span class="my-weight-bold">{{ item.title }}</span>
                    <span>{{ item.createTime }}</span>
                  </div>
                  <span class="my-text-ellipsis-2 my-gray-color">{{
                    item.content
                  }}</span>
                </div>
              </a-menu-item>
              <a-menu-divider></a-menu-divider>
            </div>
          </div>
          <div class="my-center" v-else>
            <a-empty :image="simpleImage" description="暂无消息" />
          </div>
        </a-spin>
        <a-menu-item class="my-center" @click="toAll">
          <a class="my-primary-color">查看所有消息</a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <!-- 消息详情 -->
    <MessageDetail ref="detailRef" @success="search" />
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import { mapState, mapMutations } from 'vuex'
import MessageDetail from '../manage/message/MessageDetail.vue'
export default {
  name: 'HeaderMessage',
  components: {
    MessageDetail
  },
  data () {
    return {
      loading: false,
      messageList: [],
      // messageNum: this.$db.get('MESSAGENUM'),
      simpleImage: ''
    }
  },
  beforeCreate () {
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE
  },
  computed: {
    ...mapState({
      messageNum: state => state.account.messageNum
    })
  },
  mounted () {
  },
  methods: {
    ...mapMutations({
      setMessageNum: 'account/setMessageNum'
    }),
    detail (record) {
      this.$refs.detailRef.getDetail(record.uuid)
      this.$refs.detailRef.visiable = true
    },
    search () {

    },
    dropShow () {
      this.getMessageNum()
      this.getMessageList()
    },
    toAll () {
      this.$router.push('/message')
    },
    readOne (uuid) {
      this.$get('web/message-notify/setRead/' + uuid)
        .then((r) => {
          this.$message.success('操作成功')
          this.getMessageList()
        })
        .catch(() => {
        })
    },
    getMessageNum () {
      this.loading = true
      this.$get('web/message-notify/selectUnreadMessageNum')
        .then((r) => {
          this.loading = false
          let messageNum = r.data.data
          this.setMessageNum(messageNum)
          // this.$db.save('MESSAGENUM', messageNum)
          this.$forceUpdate()
        })
        .catch(() => {
          this.loading = false
        })
    },
    getMessageList (params = {}) {
      this.loading = true
      params.pageSize = 5
      params.pageNum = 1
      params.readed = false
      this.$post('web/message-notify/list', params)
        .then((r) => {
          let data = r.data
          this.loading = false
          this.messageList = data.data.list || []
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="less" scoped>
.messageBox {
  margin: 8px;
}
</style>
