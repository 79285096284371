!<template>
  <div>
    <div class="title">请选择用户角色</div>
    <div class="selector-wrapper">
      <a-card hoverable @click="() => {handleClick(1)}"  :class="role === 'enterprise' && 'package__check'">
        <div class="card">
          <!-- <div class="avatar"><img src="../../assets/img/compimg.png" alt=""></div> -->
          <div class="text">企业用户</div>
        </div>
        <div v-if="role === 'enterprise'" class="package__check--mark"></div>
      </a-card>
      <a-card hoverable @click="() => {handleClick(0)}" :class="role === 'personal' && 'package__check'">
        <div class="card">
          <!-- <div class="avatar"><img src="../../assets/img/personimg.png" alt=""></div> -->
          <div class="text">个人用户</div>
        </div>
        <div v-if="role === 'personal'" class="package__check--mark"></div>
      </a-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoleSelector',
  props: ['role'],
  methods: {
    handleClick (index) {
      if (index) {
        this.$emit('select-role', 'enterprise')
      } else {
        this.$emit('select-role', 'personal')
      }
    }
  }
}
</script>

<style scoped lang="less">
.package__check--mark{
  display: none;
}
.package__check{
  border: 1px solid #13A8BF !important;
  .package__check--mark{
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    bottom: -1px;
    left: -1px;
    background: url("../../assets/img/checkmark.png") no-repeat center;
    background-size: contain;
  }
}

.selector-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
  gap: 20px;
}
  /deep/ .ant-card {
    border: 1px solid #E0EAF3;
    border-radius: 12px;
    margin-top: 24px;
    flex-grow: 1;
  }
  /deep/ .ant-card-body {
    padding: 12px;
  }
  .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    color: #0B2850;
  }
  .card {
    display: flex;
    align-items: center;
    .avatar {
      margin-right: 40px;
    }
    .text {
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0px;

      /* 文字2 */
      color: #6E7070;
      width: 100%;
      text-align: center;
    }
  }
</style>
