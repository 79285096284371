<template>
  <div class="container">
    <div class="float-left-text">求职问是 雇佣归真</div>
    <a
      ><img @click="toWeb" class="logo" src="../../assets/img/logo3.svg" alt=""
    /></a>
    <div class="left">
      <div class="left-inner-1">
        <div class="left-inner-1-text-1">CHECKEY</div>
        <div class="left-inner-1-text-2">
          致力于为企业提供最快、最公平的背景核查
        </div>
      </div>
      <div class="left-inner-2">
        <left-inner-2
          v-for="item in leftViewData"
          :key="item.text1"
          :text1="item.text1"
          :text2="item.text2"
          :img="item.img"
        ></left-inner-2>
      </div>
      <img src="../../assets/img/zhuce.png" />
    </div>
    <div class="right">
      <div class="mobile-titile">
        <div class="mobile-titile-text-1">CHECKEY</div>
        <div class="mobile-titile-text-2">
          致力于为企业提供最快、最公平的背景核查
        </div>
      </div>
      <div class="right-inner">
        <div class="right-inner-header">
            <div class="header-text">
              新用户注册
            </div>
          <!-- <a-tooltip overlayClassName="login-tooltip" placement="left">
            <template slot="title">
              <div style="background: #13a8bf">已有账户登录</div>
            </template>
            <a
              :href="`/#/login?${
                role === 'personal' ? 'role=personal' : 'role=enterprise'
              }`"
              ><img src="../../assets/img/qrback.png" alt=""
            /></a>
          </a-tooltip> -->
        </div>
        <div class="right-content">
          <!-- <role-selector :role="role" @select-role="handleSelectRole"></role-selector> -->
          <a-form @submit.prevent="handleLogin" :form="registerForm">
            <div v-for="item in registerFormModel" :key="item.key">
              <div class="form-label">
                <span v-if="item.rules && item.rules[0] && item.rules[0].required" style="color: red">* </span>{{ item.name }}
              </div>
              <a-form-item v-if="item.key !== 'code'">
                <a-input
                  :style="{ borderRadius: '8px !important' }"
                  size="large"
                  :type="item.type"
                  :placeholder="item.placeholder"
                  v-decorator="[
                    item.key,
                    {
                      rules: item.rules,
                    },
                  ]"
                >
                </a-input>
              </a-form-item>
              <div class="validate-code-wrapper" v-else>
                <a-form-item :key="item.key">
                  <a-input
                    :style="{ borderRadius: '8px !important' }"
                    size="large"
                    :placeholder="item.placeholder"
                    v-decorator="[
                      item.key,
                      {
                        rules: item.rules,
                      },
                    ]"
                  >
                  </a-input>
                </a-form-item>
                <a-button :loading="codeLoading" :disabled="codeFlag" class="validate-code-btn" size="large" type="primary" @click="getValidateCode">获取验证码 {{ codeFlag ? `(${codeTime})` : "" }}</a-button>
              </div>
            </div>
          </a-form>
          <a-button
            :loading="loading"
            @click="handleRegister"
            class="login-button"
            >立即体验</a-button
          >
          <div class="text-below-btn">
            已有帐号？<a :href="`/#/login?${
                role === 'personal' ? 'role=personal' : 'role=enterprise'
              }`">去登录</a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-text">
        ©CHECKEY2023 <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2023024978号-1</a><a href= "https://beian.mps.gov.cn/#/query/webSearch?code=33021202002472" rel="noreferrer" target="_blank">浙公网安备33021202002472</a >
      </div>
    </div>
  </div>
</template>

<script>
import LeftInner2 from './LeftInner2'
import db from '../../utils/localstorage'
import img1 from '../../assets/img/leftinner.png'
import img2 from '../../assets/img/leftinner1.png'
import img3 from '../../assets/img/leftinner2.png'
import RoleSelector from './RoleSelector.vue'
// import Regist from './Regist1'
// import Login from './Login'
// import Regist from './Regist'

export default {
  name: 'LoginEntrance',
  components: { LeftInner2, RoleSelector },
  data () {
    return {
      registerForm: this.$form.createForm(this),
      leftViewData: [
        {
          img: img1,
          text1: '提高背调效率',
          text2: '自动化操作流程，节省50%时间'
        },
        {
          img: img2,
          text1: '保障背调结果可信度',
          text2: '采用区块链技术确保候选人背调报告的唯一性、不可篡改'
        },
        {
          img: img3,
          text1: '降低企业人才错失率',
          text2: '候选人可对不公平项发起实时申诉'
        }
      ],
      registerFormModel: [
        {
          key: 'phone',
          name: '手机号',
          placeholder: '请输入手机号',
          rules: [
            {
              required: true,
              message: '请输入手机号',
              whitespace: true,
              trigger: 'blur'
            }
          ]
        },
        {
          key: 'code',
          name: '验证码',
          placeholder: '请输入手机验证码',
          rules: [
            { required: true, message: '请输入手机验证码', whitespace: true }
          ]
        },
        {
          key: 'email',
          name: '邮箱',
          placeholder: '请输入邮箱',
          rules: [{ required: true, message: '请输入邮箱', whitespace: true }]
        },
        {
          key: 'password',
          name: '密码',
          placeholder: '请输入密码',
          type: 'password',
          rules: [
            { required: true, message: '请输入密码', whitespace: true },
            { validator: this.handlePasswordLevel }
          ]
        },
        {
          key: 'passwordAgain',
          name: '确认密码',
          placeholder: '请再次输入密码',
          type: 'password',
          rules: [
            { required: true, message: '请再次输入密码', whitespace: true },
            { validator: this.handlePasswordCheck }
          ]
        }
      ],
      componentName: 'Login',
      loading: false,
      role: '',
      codeFlag: false,
      codeTime: 60,
      codeEvent: null,
      codeLoading: false
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to, from) {
        if (
          this.$route.query.role &&
          (this.$route.query.role === 'personal' ||
            this.$route.query.role === 'enterprise')
        ) {
          this.role = this.$route.query.role
        } else {
          this.handleSelectRole('enterprise')
        }
      }
    },
    role: {
      handler (to, from) {
        if (to === 'enterprise') {
          this.registerFormModel.push({
            key: 'inviteCode',
            name: '邀请码',
            placeholder: '邀请码',
            rules: [{ required: false }]
          })
          this.$nextTick(() => {
            this.registerForm.validateFields(['inviteCode'], { force: true })
          })
        } else {
          const index = this.registerFormModel.findIndex((item) => item.key === 'inviteCode')
          if (index !== -1) {
            this.registerFormModel.pop()
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    systemName () {
      return this.$store.state.setting.systemName
    },
    copyright () {
      return this.$store.state.setting.copyright
    }
  },
  methods: {
    handlePasswordLevel (rule, value, callback) {
      let level = 0

      // 判断这个字符串中有没有数字
      if (/[0-9]/.test(value)) {
        level++
      }
      // 判断字符串中有没有字母
      if (/[a-zA-Z]/.test(value)) {
        level++
      }
      // 判断字符串中有没有特殊符号
      if (/[^0-9a-zA-Z_]/.test(value)) {
        level++
      }
      // let percent = level * 30
      if (level >= 2 && value.length >= 8) {
        // if (level >= 3) {
        //   percent = 100
        // }
        callback()
      } else {
        // if (level === 0 || level === 1 || value.length < 8) {
        //   percent = 10
        // }
        callback(
          new Error(
            '密码强度不够，需要8位以上密码并包含数字、英文字母或特殊符号中的两种'
          )
        )
      }
    },
    handlePasswordCheck (rule, value, callback) {
      const password = this.registerForm.getFieldValue('password')
      // if (value === undefined) {
      //   callback(new Error('请输入密码'))
      // }
      if (value && password && value.trim() !== password.trim()) {
        callback(new Error('两次密码不一致'))
      }
      callback()
    },
    handleRegister () {
      this.registerForm.validateFields(
        (errors, formParams) => {
          const { phone: mobile, email, code: mobileCode, password } = formParams
          if (errors) {
            console.log('validate error', errors)
            return
          }
          this.loading = true
          const params = {
            userType: this.role === 'personal' ? 2 : 1,
            mobile: mobile,
            email: email,
            password: this.$aesEncrypt.encrypt(password),
            mobileCode: mobileCode
          }
          if (this.role === 'enterprise' && formParams.inviteCode) {
            params.inviteCode = formParams.inviteCode
          }
          this.$post('web/login/register', params)
            .then((r) => {
              this.$message.success('注册成功')
              setTimeout(() => {
                this.loading = false
              }, 500)
              this.goLogin()
            })
            .catch((e) => {
              console.error(e)
              setTimeout(() => {
                this.loading = false
              }, 500)
            })
        }
      )
    },
    getValidateCode () {
      if (this.codeEvent) {
        clearInterval(this.codeEvent)
        this.codeTime = 60
      }
      const mobile = this.registerForm.getFieldValue('phone')
      if (mobile === undefined) {
        this.$message.warning('请输入手机号')
      }
      this.registerForm.validateFields(['phone'], (errors, values) => {
        if (!errors) {
          this.codeLoading = true
          this.$get('web/login/sendMobileRegisterVerificationCode/' + mobile, {}).then((r) => {
            this.$message.success(r.data.message)
            this.codeLoading = false
            this.codeFlag = true
            this.codeInterval()
          }).catch((e) => {
            console.error(e)
            this.codeLoading = false
          })
        }
      })
    },
    codeInterval () {
      this.codeEvent = setInterval(() => {
        if (this.codeTime === 0) {
          clearInterval(this.codeEvent)
          this.codeTime = 60
          this.codeFlag = false
        } else {
          this.codeTime--
        }
      }, 1000)
    },
    goLogin () {
      this.$router.push('/login?role=' + this.role)
    },
    toWeb () {
      window.open('https://www.checkey.net/#/webHome', '_self')
    },
    handleSelectRole (role) {
      role = 'enterprise'
      this.$router.push('/register?role=' + role)
      this.role = role
      if (role === 'personal') {
        this.$changeRole(2)
      } else if (role === 'enterprise') {
        this.$changeRole(1)
      }
    },
    notKeepLogin () {
      window.addEventListener('onbeforeunload', () => {
        db.clear()
      })
    }
  }
}
</script>

<style lang="less" scoped>
@media screen and (max-width: 768px) {
  .float-left-text {
    display: none !important;
  }
  .logo {
    right: calc(50% - 60px) !important;
  }
  .container {
    flex-direction: column;
    height: auto !important;
    flex-direction: column-reverse;
    padding-top: 100px;
  }
  .header-text {
    text-align: center !important;
    width: 100%;
  }
  .left-inner-1 {
    display: none;
  }
  .left-inner-2 {
    margin-top: -100px !important;
  }
  .mobile-titile {
    display: flex !important;
    flex-direction: column;
    text-align: center;
    margin-bottom: 50px;
    background: #F5FCFD;
    height: 100px;
    justify-content: center;
    border-radius: 8px;
    &-text-1 {
      font-size: 25px;
      font-weight: 500;
      line-height: 25px;
      color: #0b2850;
    }
    &-text-2 {
      margin-top: 12px;
      font-size: 15px;
      font-weight: 500;
      line-height: 1;
      color: #0b2850;
    }
  }
}
.mobile-titile {
  display: none;
}
.container {
  display: flex;
  // flex-direction: column;
  min-height: 100%;
  background: white;
  position: relative;
  .float-left-text {
    position: absolute;
    left: 40px;
    top: 20px;

    font-size: 26px;
    font-weight: 500;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: 0px;

    /* logo绿 */
    color: #13a8bf;
  }
  .logo {
    position: absolute;
    right: 78px;
    top: 20px;
    width: 120px;
  }
  .left {
    flex: 1 1 780px;
    background: #f5fcfd;
    flex-direction: column;
    padding: 3% 8%;
    display: flex;
    justify-content: center;
    img {
      margin-top: 80px;
      width: 100%;
    }
    .left-inner-1 {
      &-text-1 {
        font-size: 32px;
        font-weight: 500;
        line-height: 32px;
        color: #0b2850;
      }
      &-text-2 {
        margin-top: 12px;
        font-size: 24px;
        font-weight: 500;
        line-height: 1;
        color: #0b2850;
      }
    }
    .left-inner-2 {
      margin-top: 50px;
    }
  }
  .right {
    flex: 0.85 1 660px;
    background: white;
    padding: 0 10%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .header-text {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;

      /* 文字1 */
      color: #0b2850;
    }
    .header-subtext {
      margin-top: 12px;
      font-size: 14px;
      font-weight: normal;
      line-height: 14px;
      letter-spacing: 0px;

      /* 文字1 */
      color: #0b2850;
    }
    .right-inner {
      // min-width: 444px;
      width: 100%;
      .right-inner-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        white-space: nowrap;
      }
      .right-content {
        .ant-form {
          margin-top: 40px;
        }
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        .form-label {
          margin-bottom: 8px;
          font-size: 14px;
          font-weight: normal;
          line-height: 14px;
          letter-spacing: 0px;

          /* 文字2 */
          color: #6e7070;
        }
        .validate-code-wrapper {
          display: flex;
          justify-content: space-between;
          .ant-form-item {
            flex-grow: 1 !important;
          }
          .validate-code-btn {
            min-width: 120px;
            margin-left: 16px;
            border-radius: 8px !important;
          }
        }
        .login-button {
          margin-top: 24px;
          width: 100%;
          height: 50px;
          border-radius: 32px !important;
          opacity: 1;

          color: white;
          font-size: 18px !important;
          font-weight: 500;
          line-height: 18px;

          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 16px 0px;
          gap: 8px;

          /* logo绿 */
          background: #13a8bf;
        }
        .text-below-btn {
          margin-top: 12px;
          font-size: 16px;
          font-weight: normal;
          line-height: 16px;
          letter-spacing: 0px;

          /* 文字2 */
          color: #6E7070;
        }
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 14px;
    width: 100%;
    justify-content: center;
    text-align: center;
    &-text {
      font-size: 12px;
      font-weight: normal;
      line-height: 1;
      letter-spacing: 0px;

      /* 文字3 */
      color: #969fb6;
    }
  }
}
</style>

<style lang="less">
.login-tooltip {
  .ant-tooltip-inner {
    background-color: #13a8bf;
  }
  .ant-tooltip-arrow::before {
    background-color: #13a8bf;
  }
}
</style>
