<!--
过往工作经历
-->
<template>
  <div class="container">
    <div class="content">
      <div class="headerBox" style="padding-bottom: 8px">
        <div class="headerTitle">过往工作经历</div>
      </div>
      <a-divider />
      <div class="my-primary-padding my-flex-column">
        <a-form-model labelAlign="left">
          <a-form-model-item v-bind="formItemLayout" label="公司全称">
            <a-input v-model="form.name" placeholder="请输入公司全称" />
          </a-form-model-item>
          <a-form-model-item v-bind="formItemLayout" label="工作所在地">
            <a-input v-model="form.name" placeholder="请输入您的工作所在地" />
          </a-form-model-item>
          <a-form-model-item v-bind="formItemLayout" label="部门名称">
            <a-input v-model="form.name" placeholder="请输入您的部门名称" />
          </a-form-model-item>
          <a-form-model-item v-bind="formItemLayout" label="职位名称">
            <a-input v-model="form.name" placeholder="请输入您的职位名称" />
          </a-form-model-item>
          <a-form-model-item v-bind="formItemLayout" label="职责描述">
            <a-input v-model="form.name" placeholder="请输入职责描述" />
          </a-form-model-item>
          <a-form-model-item v-bind="formItemLayout" label="在职时间">
            <range-date
              @change="handleDateChange"
              ref="createTime"
              style="width: 100%"
            ></range-date>
          </a-form-model-item>
          <a-form-model-item v-bind="formItemLayout" label="薪酬">
            <a-input v-model="form.name" placeholder="月薪6k+绩效2k或者年薪15万+绩效5万" />
          </a-form-model-item>
          <a-form-model-item v-bind="formItemLayout" label="供职方式">
            <a-radio-group v-model="form" name="radioGroup" :default-value="0">
              <a-radio :value="0">全职</a-radio>
              <a-radio :value="1">兼职</a-radio>
              <a-radio :value="2">派遣</a-radio>
              <a-radio :value="3">实习</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item v-bind="formItemLayout" label="离职性质">
            <a-radio-group v-model="form" name="radioGroup" :default-value="0">
              <a-radio :value="0">主动</a-radio>
              <a-radio :value="1">被动</a-radio>
              <a-radio :value="2">协商</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item v-bind="formItemLayout" label="离职原因">
            <a-input v-model="form.name" placeholder="请输入离职原因" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>
<script>
import RangeDate from '@/components/datetime/RangeDate'
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 }
}
export default {
  name: 'WorkEx',
  components: {
    RangeDate
  },
  props: {
  },
  data () {
    return {
      loading: false,
      formItemLayout,
      form: {}
    }
  },
  created () {},
  mounted () {
  },
  watch: {
  },
  methods: {
    handleDateChange (value) {
      this.form.startTime = value[0]
      this.form.endTime = value[1]
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;
}

.content {
  margin: 16px;
  background-color: #fff;
}

.headerBox {
  background-color: #fff;
  padding-top: 16px;
  .headerTitle {
    border-left: 3px solid #248AA3;
    padding-left: 8px;
    margin-left: 16px;
    margin-bottom: 16px;
    font-weight: bold;
  }
}
.ant-divider {
  margin: 6px 0 !important;
}
.ant-row {
  margin: 0 !important;
}
</style>
