<template>
  <div class="footer">
    <div class="wave-container">
      <div class="wave"></div>
    </div>
    <div class="footer-content">
      <div class="footer-center" v-if="isShow" @click="handleSound">
        <img src="../../assets/img/audio-mic.svg" alt="" />
        <div class="text">点击开始录音</div>
      </div>
      <div class="footer-center" v-else>
        <img
          style="margin-bottom: 10px"
          src="../../assets/img/audio-stop.svg"
          alt=""
          @click="suspendRecording"
        />
        <div class="operation">
          <div class="text-color" @click="reRecording">重新录音</div>
          <div @click="suspendRecording">再次点击结束录音</div>
          <div class="text-color" @click="upload">发送录音</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//必须引入的核心
import Recorder from 'recorder-core'

//引入mp3格式支持文件；如果需要多个格式支持，把这些格式的编码引擎js文件放到后面统统引入进来即可
import 'recorder-core/src/engine/mp3'
import 'recorder-core/src/engine/mp3-engine'
//录制wav格式的用这一句就行
//import 'recorder-core/src/engine/wav'

//可选的插件支持项，这个是波形可视化插件
import 'recorder-core/src/extensions/waveview'

//ts import 提示：npm包内已自带了.d.ts声明文件（不过是any类型）

export default {
  name: 'MQuestion',
  props: {
    questionIndex: {
      type: Number,
      default: 0
    },
    questionText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      recorder: null,
      isRecording: false,
      stream: null,
      isShow: true
    }
  },
  mounted () {
    console.log(this.$route.query.id)
    this.recOpen()
  },
  methods: {
    // 录音
    handleSound () {
      if (!this.rec) { console.error("未打开录音"); return }
      this.rec.start()
      this.isShow = !this.isShow
      // 获取父组件参数

    },
    recOpen () {
      //创建录音对象
      this.rec = Recorder({
        type: "mp3" //录音格式，可以换成wav等其他格式
        , sampleRate: 16000 //录音的采样率，越大细节越丰富越细腻
        , bitRate: 16 //录音的比特率，越大音质越好
        , onProcess: (buffers, powerLevel, bufferDuration, bufferSampleRate, newBufferIdx, asyncEnd) => {
          //录音实时回调，大约1秒调用12次本回调
          //可实时绘制波形，实时上传（发送）数据
          if (this.wave) this.wave.input(buffers[buffers.length - 1], powerLevel, bufferSampleRate)
        }
      })

      //打开录音，获得权限
      this.rec.open(() => {
        console.log("录音已打开")
        // if (this.$refs.recwave) {//创建音频可视化图形绘制对象
        //   this.wave = Recorder.WaveView({ elem: this.$refs.recwave })
        // }
      }, (msg, isUserNotAllow) => {
        //用户拒绝了录音权限，或者浏览器不支持录音
        console.log((isUserNotAllow ? "UserNotAllow，" : "") + "无法录音:" + msg)
      })
    },
    // 重新录音
    reRecording () {
      console.log('重新录音')
      this.isShow = !this.isShow
    },
    // 暂停录音
    suspendRecording () {
      if (!this.rec) { console.error("未打开录音"); return }
      this.rec.stop((blob, duration) => {
        //blob就是我们要的录音文件对象，可以上传，或者本地播放
        this.recBlob = blob
        //简单利用URL生成本地文件地址，此地址只能本地使用，比如赋值给audio.src进行播放，赋值给a.href然后a.click()进行下载（a需提供download="xxx.mp3"属性）
        var localUrl = (window.URL || webkitURL).createObjectURL(blob)
        console.log("录音成功", blob, localUrl, "时长:" + duration + "ms")

        this.upload(blob)//把blob文件上传到服务器
        this.isShow = !this.isShow
        this.rec.close()//关闭录音，释放录音资源，当然可以不释放，后面可以连续调用start
        this.rec = null
      }, (err) => {
        console.error("结束录音出错：" + err)
        this.rec.close()//关闭录音，释放录音资源，当然可以不释放，后面可以连续调用start
        this.rec = null
      })
    },
    // 发送录音
    upload (blob) {
      console.log('发送录音')
      let formData = new FormData()
      formData.append('file', blob)
      this.$upload('/minio-file/uploadAudio', formData).then((res) => {
        console.log(res)
        this.uploadAudio(res)
        this.$message.success('上传成功')

      }).catch((r) => {
        console.error(r)
        this.$message.error('上传失败')
        this.loading = false
      })
    },
    // 上传自动录音问题
    uploadAudio (res) {
      console.log('res==', res)
      const params = {
        enterpriseName: '公司1',
        id: '247',
        name: '翁健峰',
        type: 0,
        questionAnswers: [
          {
            content: "",
            itemKey: "",
            name: "",
            question: "",
            url: ""
          }
        ],
      }
      this.$post('web/check-report/uploadAudioQuestion', params)
        .then((r) => {
          const data = r.data
          if (data.code === 200) {
            console.log(data)
          }
        })
        .catch((data) => {
          this.$message.error(data.message)
        })
    }
  }
}
</script>

<style scoped lang="less">
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 167px;
  border-radius: 16px 16px 0px 0px;
  opacity: 1;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: #ffffff;

  box-shadow: 0px 0px 6px 0px rgba(186, 192, 193, 0.3);
}

.footer-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footer-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  img {
    width: 45px;
    height: 45px;
  }
  .text {
    font-size: 15px;
    font-weight: normal;
    line-height: 15px;
    letter-spacing: 0px;

    /* 文字3 */
    color: #969fb6;
    margin-top: 10px;
  }
  .operation {
    display: flex;
    width: 100%;
    justify-content: space-around;
    .text-color {
      color: #13a8bf;
    }
  }
}
</style>
