<!--
授权声明
-->
<template>
  <div class="container">
    <div class="content">
      <div class="headerBox" style="padding-bottom: 8px">
        <div class="headerTitle">授权声明</div>
      </div>
      <a-divider />
      <div class="my-primary-padding my-flex-column">
        <a-form-model labelAlign="left">
          <a-form-model-item v-bind="formItemLayout" label="真实姓名">
            <a-input v-model="form.name" placeholder="请输入您的真实姓名" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </div>
</template>
<script>
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 }
}
export default {
  name: 'Auth',
  components: {
  },
  props: {
  },
  data () {
    return {
      loading: false,
      formItemLayout,
      form: {}
    }
  },
  created () {},
  mounted () {
  },
  watch: {
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;
}

.content {
  margin: 16px;
  background-color: #fff;
}

.headerBox {
  background-color: #fff;
  padding-top: 16px;
  .headerTitle {
    border-left: 3px solid #248AA3;
    padding-left: 8px;
    margin-left: 16px;
    margin-bottom: 16px;
    font-weight: bold;
  }
}
.ant-divider {
  margin: 6px 0 !important;
}
.ant-row {
  margin: 0 !important;
}
</style>
