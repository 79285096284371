export function formatFillIns (list) {
  const fillIns = []
  if (list && list.length) {
    list.forEach(sitem => {
      const ins = {
        selectSurveyTypes: []
      }
      ins.uuid = sitem.packageItemUuid
      ins.initiatorInputType = sitem.initiatorInputType
      ins.initiatorShowType = sitem.initiatorShowType
      ins.autoSearchCertifier = null
      if (sitem.initiatorInputType === 4 || sitem.initiatorInputType === 10 ||
        sitem.initiatorInputType === 11 || sitem.initiatorInputType === 12) {
        if (sitem.initiatorInputType === 12) {
          if (sitem.tableList && sitem.tableList.length) {
            sitem.tableList.forEach(form => {
              const selectType = {}
              selectType.uuid = sitem.surveyTypes[0].uuid || ''
              selectType.initiatorFillIn = JSON.stringify(form)
              selectType.form = form
              ins.selectSurveyTypes.push(selectType)
            })
          } else {
            if (sitem.selectSurveyTypes && sitem.selectSurveyTypes.length) {
              sitem.selectSurveyTypes.forEach(ss => {
                const selectType = {}
                selectType.uuid = ss.uuid || ''
                selectType.initiatorFillIn = ss.initiatorFillIn
                selectType.form = JSON.parse(ss.initiatorFillIn)
                ins.selectSurveyTypes.push(selectType)
              })
            }
          }
        } else {
          if (sitem.tableList && sitem.tableList.length) {
            ins.selectSurveyTypes = sitem.tableList
          } else {
            if (sitem.selectSurveyTypes && sitem.selectSurveyTypes.length) {
              ins.selectSurveyTypes = [...sitem.selectSurveyTypes]
            }
          }
        }
        // 处理特殊表格
        if (sitem.initiatorShowType === 9 &&
          sitem.inputSurveyTypes &&
          sitem.inputSurveyTypes.length > 1 &&
          sitem.inputSurveyTypes[1].inputType === 9 &&
          sitem.inputSurveyTypes[1].surveyTypes) {
          if (sitem.itemKey === 'work_experience_verify' || sitem.itemKey === 'work_performance_verify') {
            ins.selectSurveyTypes.forEach(item => {
              item.autoSearchCertifier = item.value
            })
          } else {
            ins.selectSurveyTypes.forEach(item => {
              item.candidateFill = item.value
              if (item.candidateFill) {
                item.initiatorFillIn = ' '
              }
            })
          }
        }
      } else {
        if (sitem.value) {
          const stype = {
            uuid: sitem.surveyTypes[0].uuid || '',
            initiatorFillIn: sitem.value
          }
          ins.selectSurveyTypes.push(stype)
        } else {
          if (sitem.selectSurveyTypes && sitem.selectSurveyTypes.length) {
            ins.selectSurveyTypes = [...sitem.selectSurveyTypes]
          }
        }
      }
      if (ins.selectSurveyTypes.length) {
        fillIns.push(ins)
      }
    })
  }
  return fillIns
}

export function isCandidatePage () {
  return window.location.origin.indexOf('candidate') > -1
}

export function isDev () {
  return CONFIG_TEXT === 'dev'
}

export function getDashboardSite () {
  return DASHBOARD_SITE
}
