<!--
个人信息授权表成功页面
-->
<template>
  <div class="container">
    <div class="content_image">
      <span class="content_text"
        >您的授权已完成，背调正在开展中！
        如注意查收邮件或关注公众号【7氪背调Checkey】了解您的背调进度！</span
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "Msuccess",
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {},
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;

  .content_image {
    width: 100%;
    height: 100%;
    background: url("../../assets/img/bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
  }
  .content_text {
    font-size: 23px;
    width: 85%;
    color: white;
    margin-top: 45%;
    font-weight: 700;
  }
}
</style>
