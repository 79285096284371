<!--
认证
-->
<template>
  <div class="container">
    <a-modal
      class="authModal"
      title="信息认证"
      :visible="authVisible"
      @cancel="authVisible = false"
      :footer="null"
    >
      <div v-if="userInfo.authStatus === 0">由于背调内容涉及到隐私，为了防止他人盗用信息而使您承担不必要的法律风险，当你首次使用Checkey时需要您先完成信息认证。</div>
      <div v-if="userInfo.authStatus === 1">
        <span style="color: black">您的资料已经提交成功，我们正在火速审核中，请耐心等待</span>
      </div>
      <div v-if="userInfo.auditStatus === 2">
        <span>您的认证信息未通过</span>
        <p>{{ userInfo.failReason }}</p>
      </div>
      <a-button style="float: right;margin-top: 20px" type="primary" @click="toAuth" v-if="userInfo.authStatus === 0">前往认证</a-button>
      <a-button style="float: right;margin-top: 20px" type="primary" @click="previewImage" v-if="userInfo.authStatus === 1">查看资料</a-button>
      <div style="height: 40px"></div>
    </a-modal>
    <Carousel ref="carousel" />
    <PdfModal ref="pdf" />
  </div>
</template>
<script>
import Carousel from '@/components/carousel/Carousel'
import PdfModal from '@/views/manage/report/components/PdfModal.vue'
export default {
  name: 'AuthModal',
  components: {
    Carousel,
    PdfModal
  },
  props: {
  },
  data () {
    return {
      loading: false,
      authVisible: false,
      userInfo: {
        authStatus: null,
        auditStatus: null
      }
    }
  },
  mounted () {

  },
  methods: {
    toAuth () {
      this.authVisible = false
      let flag = 'person'
      if (this.userInfo.userType === 1) {
        flag = 'enterprise'
      } else if (this.userInfo.userType === 2) {
        flag = 'person'
      }
      this.$router.push('auth?flag=' + flag)
    },
    /* 图片预览 */
    previewImage (record) {
      const picList = this.userInfo.certificationData
      if (picList[0] && picList[0].slice(-3) === 'pdf') {
        this.$refs.pdf.pdfSrc = picList[0]
        this.$refs.pdf.getPdfSrc()
        this.$refs.pdf.showVisiable = true
        return
      }
      this.$refs.carousel.picList = this.userInfo.certificationData || []
      this.$refs.carousel.picVisible = true
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;
}
.authModal {
  color: #8C8C8C;
  // padding-bottom: 40px;
  span {
    color: #FF4D4F;
  }
}
</style>
