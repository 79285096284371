<!--
背调套餐
-->
<template>
  <div class="container">
    <div class="content">
      <div
        class="headerBox my-flex my-item-jc-sb my-item-center"
        style="padding-bottom: 12px"
      >
        <div class="headerTitle">背调套餐</div>
        <div
          style="
            color: #13a8bf;
            font-family: SourceHanSansCN-Regular-Regular;
            font-size: 16px;
            cursor: pointer;
          "
          v-if="
            chartData.backTonePackageList &&
            chartData.backTonePackageList.length > 1
          "
          @click="toPackage"
        >
          查看更多>
        </div>
      </div>
      <div
        :style="{ height: height, width: width }"
        class="my-primary-padding box1"
      >
        <div
          v-if="!chartData.backTonePackageList"
          style="color: #cdcdcd; font-size: 19px"
        >
          暂无数据
        </div>
        <div
          v-else
          v-for="(item, index) in chartData.backTonePackageList.slice(0, 3)"
          :key="index"
          class="box"
        >
          <div v-if="index < 3" class="my-flex my-item-jc-sb my-margin-b-10 my-item-center">
            <div>
              <div
                style="
                  font-family: SourceHanSansCN-Regular-Regular;
                  font-size: 16px;
                  font-weight: normal;
                  line-height: 16px;
                  letter-spacing: 0px;
                  color: #0b2850;
                "
              >
                {{ item.packageName }}
              </div>
              <div
                style="
                  font-family: SourceHanSansCN-Regular-Regular;
                  font-size: 14px;
                  font-weight: normal;
                  line-height: 16px;
                  letter-spacing: 0px;
                  color: #6e7070;
                  margin-top: 12px;
                  width: 414px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
              >
                {{ item.packageDescription }}
              </div>
            </div>
            <a-button
              @click="open_back"
              shape="round"
              size="small"
              style="
                border-radius: 32px !important;
                color: #13a8bf;
                border: 1px solid #13a8bf;
                /* padding: 6px 20px; */
                width: 96px;
                height: 32px;
              "
              >开始背调</a-button
            >
          </div>
        </div>
        <!-- <div class="my-flex my-item-jc-center">
          <a-button v-if="chartData.backTonePackageList && chartData.backTonePackageList.length > 1" @click="toPackage">查看更多</a-button>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BackTonePackage',
  components: {},
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '306px'
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  created () {},
  mounted () {
    console.log(this.chartData)
  },
  methods: {
    open_back () {
      this.$router.push('/startBack')
    },
    toPackage () {
      this.$router.push('/package/list')
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;
}

.content {
  margin: 8px 24px;
  // background-color: #fff;
}

.headerBox {
  // background-color: #fff;
  // padding-top: 16px;
  .headerTitle {
    font-family: SourceHanSansCN-Medium-Medium;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0px;
    color: #0b2850;
  }
}
.ant-divider {
  margin: 0px !important;
}
.ant-row {
  margin: 0 !important;
}
.box1 {
  border-radius: 16px;
  padding: 20px;
  background: #ffffff;
}
.box {
  border: 1px solid #e0eaf3;
  padding: 16px;
  border-radius: 12px;
  margin-bottom: 20px;
  height: 78px;
}
.box:hover {
  background: #f5fcfd;
}
</style>
