<!--
背调列表
-->
<template>
  <div class="container">
    <div class="package-usage-title">
      <div class="package-title">报告统计</div>
      <div class="package-title">报告申诉</div>
    </div>
    <div class="package-usage-item">
      <!-- <div></div> -->
      <div id="myChart" :style="{ zoom: '125%' }" class="platform-item"></div>
      <div id="myChart1" :style="{ zoom: '125%' }" class="platform-item"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as echarts from 'echarts'
export default {
  name: 'packageUsage',
  components: {
  },
  props: {
    chartData: {
      type: Object,
      default: () => { },
    },
  },
  data () {
    return {

    }
  },
  computed: {
  },
  mounted () {
    // console.log('chartData==', this.chartData)
    setTimeout(() => {
      this.drawLine()
    }, 200)
  },
  methods: {
    drawLine () {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      let myChart1 = this.$echarts.init(document.getElementById('myChart1'))
      const option = {
        color: ['#46B8FF', '#0CD9B5', '#6E5EFF', '#FFA444'],
        tooltip: {
          trigger: 'item',
          formatter (name) {
            console.log(name)
            return (
              `${name.name}<br/>${name.marker}${name.data.name}次数${name.data.value}次<br/>占比${name.percent}%`
            )
          }
        },
        legend: {
          orient: 'horizontal',
          //   top: '5%',
          //   left: 'center',
          bottom: 0,
          icon: 'circle'
        },
        graphic: {
          type: 'text',
          left: 'center',
          top: 'center',
          style: {
            text: '报告总数\n' + this.chartData.reportStatisticsItem.reportTotal, //使用“+”可以使每行文字居中
            textAlign: 'center',
            // font: 'italic bolder 16px cursive',
            fill: '#000',
            width: 30,
            height: 30
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['36%', '64%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 12,
                fontWeight: 'bold',
                formatter: function (val) {   //让series 中的文字进行换行
                  return val.name.split("-").join("\n")
                }
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: this.chartData.reportStatisticsItem.reportNormal, name: '报告正常' },
              { value: this.chartData.reportStatisticsItem.reportAbnormal, name: '报告异常' },
            ]
          }
        ]
      }
      const option1 = {
        color: ['#46B8FF', '#0CD9B5', '#6E5EFF', '#FFA444'],
        tooltip: {
          trigger: 'item',
          formatter (name) {
            console.log(name)
            return (
              `${name.name}<br/>${name.marker}${name.data.name}次数${name.data.value}次<br/>占比${name.percent}%`
            )
          }
        },
        legend: {
          orient: 'horizontal',
          //   top: '5%',
          //   left: 'center',
          bottom: 0,
          icon: 'circle'
        },
        graphic: {
          type: 'text',
          left: 'center',
          top: 'center',
          style: {
            text: '报告总数\n' + this.chartData.reportAbnormalStatisticsItem.reportAppealTotal, //使用“+”可以使每行文字居中
            textAlign: 'center',
            // font: 'italic bolder 16px cursive',
            fill: '#000',
            width: 30,
            height: 30
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['36%', '64%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 12,
                fontWeight: 'bold',
                formatter: function (val) {   //让series 中的文字进行换行
                  return val.name.split("-").join("\n")
                }
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: this.chartData.reportAbnormalStatisticsItem.reportAppealReject, name: '申诉通过' },
              { value: this.chartData.reportAbnormalStatisticsItem.reportAppealPass, name: '申诉驳回' },
            ]
          }
        ]
      }
      // 绘制图表
      myChart.setOption(option)
      myChart1.setOption(option1)
    }
  }
}
</script>

<style lang="less" scoped>
// .container {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
.package-usage-title {
  display: flex;
  flex-direction: row;
  color: #0b2850;
  font-size: 18px;
  font-weight: bold;
  .package-title {
    width: 50%;
    margin-right: 24px;
    margin-bottom: 12px;
  }
  .package-title:last-child {
    margin-right: 0;
  }
}
.package-usage-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // align-items: center;
  // justify-content: center;
  .platform-item {
    width: 50%;
    height: 226px;
    padding: 0px 0px 24px 0px;
    margin-right: 24px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px rgba(112, 144, 176, 0.12);
  }
  .platform-item:last-child {
    margin-right: 0;
  }
}
// }
</style>
