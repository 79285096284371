import { render, staticRenderFns } from "./Anchor.vue?vue&type=template&id=b76ce700&scoped=true"
import script from "./Anchor.vue?vue&type=script&lang=js"
export * from "./Anchor.vue?vue&type=script&lang=js"
import style0 from "./Anchor.vue?vue&type=style&index=0&id=b76ce700&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b76ce700",
  null
  
)

export default component.exports