<!--
补充资料
-->
<template>
  <div class="container">
    <div class="content">
      <div class="headerBox" style="padding-bottom: 8px">
        <div class="headerTitle">补充资料</div>
      </div>
      <a-divider />
      <div class="my-primary-padding my-flex-column">
        <a-space direction="vertical">
          <div v-for="(item, index) in inputList" :key="index">
            <div v-if="item.initiatorInputType !== -1" class="my-flex-column">
              <!-- 完善项标题 -->
              <a-tooltip placement="topLeft">
                <template slot="title">
                  <span>{{ item.itemName }}</span>
                </template>
                <div class="my-margin-r-10 my-weight-bold" style="width:200px">{{ item.itemName }}</div>
              </a-tooltip>
              <div>
                <!--选择-->
                <a-radio-group v-model="item.value" :defaultValue="0" v-if="item.initiatorInputType == 0">
                  <a-radio v-for="(sitem, sidnex) in item.surveyTypes" :key="sidnex" :value="sidnex">{{ sitem.type }}</a-radio>
                </a-radio-group>
                <!--下拉选择-->
                <a-select style="width: 300px;" v-model="item.value" v-if="item.initiatorInputType == 1" @change="handleSelect">
                  <a-select-option v-for="(sitem, uuid) in item.surveyTypes" :key="uuid" :value="sitem.uuid">
                    {{ sitem.type }}
                  </a-select-option>
                </a-select>
                <!-- 输入 -->
                <a-input v-model="item.value" v-if="item.initiatorInputType == 2" :placeholder="item.hint || '请输入内容'" style="width: 300px;" />
                <!-- 数值输入 -->
                <a-input-number v-model="item.value" v-if="item.initiatorInputType == 5" :placeholder="item.hint || '请输入内容'" style="width: 300px;" />
                <!-- 时间选择 -->
                <div v-if="item.initiatorInputType === 6">
                  <a-date-picker @change="timeChange($event, index1, index2)" />
                </div>
                <!-- 时间段选择 -->
                <div v-if="item.initiatorInputType === 7">
                  <a-range-picker
                    ref="datePicker"
                    :show-time="false"
                    format="YYYY-MM-DD"
                    :placeholder="['开始时间', '结束时间']"
                    @change="handleTimeChange($event, index1, index2)"
                    @ok="handleTimeChange($event, index1, index2)"
                    >
                    <a-icon slot="suffixIcon" type="calendar" />
                  </a-range-picker>
                </div>
                <!-- 多文件上传 -->
                <div v-if="item.initiatorInputType === 8">
                  <upload-file fileType="all" :fileNum="999" :fileSize="1024" @callback="mulTiCallback($event, index1, index2)"/>
                </div>
                <!-- 表格 -->
                <div v-if="item.tableList && item.tableList.length > 0 && item.initiatorShowType == 9 && item.initiatorInputType == 4" class="tableBox">
                  <a-row :gutter="40" class="my-flex-column">
                    <div v-for="(titem, tindex) in item.tableList" :key="tindex" class="my-margin-t-5">
                      <a-col :span="2">{{ tindex + 1 }}</a-col>
                      <a-col :span="9">
                        <a-input v-model="titem.initiatorFillIn" :placeholder="item.hint || '请输入内容'"  style="width: 100%" @change="tableInputChange($event, index, tindex)" />
                      </a-col>
                      <a-col :span="9">
                        <a-select style="width: 100%;" v-model="titem.uuid" @change="tableSelectChange($event, index, tindex)">
                          <a-select-option v-for="(sitem, uuid) in item.surveyTypes" :key="uuid" :value="sitem.uuid">
                            {{ sitem.type }}
                          </a-select-option>
                        </a-select>
                      </a-col>
                      <a-col :span="4">
                        <a-space :size="20">
                          <a @click="del(index, tindex)">删除</a>
                        </a-space>
                      </a-col>
                    </div>
                    <!-- 特殊表格项。额外增加一个开关 -->
                    <div style="margin: 20px 0 0 20px;" v-if="item.initiatorShowType === 9 && item.inputSurveyTypes && item.inputSurveyTypes.length>1 && item.inputSurveyTypes[1].inputType === 9 && item.inputSurveyTypes[1].surveyTypes">
                      {{ item.inputSurveyTypes[1].surveyTypes[0].type || '' }}<a-switch class="my-margin-l-10" v-model="item.inputSurveyTypes[1].surveyTypes[0].value" checked-children="是" un-checked-children="否" />
                    </div>
                    <div class="addBox" @click="add(index)">
                      + 添加
                    </div>
                  </a-row>
                </div>
                <div v-else-if="item.tableList && item.tableList.length > 0 && item.initiatorShowType !== 9 && item.initiatorInputType == 4" class="tableBox">
                  <a-row :gutter="40" class="my-flex-column">
                    <div v-for="(titem, tindex) in item.tableList" :key="tindex" class="my-margin-t-5">
                      <a-col :span="1">{{ tindex + 1 }}</a-col>
                      <a-col :span="9">
                        <a-input v-model="titem.initiatorFillIn" :placeholder="item.hint || '请输入内容'"  style="width: 100%" @change="tableInputChange($event, index, tindex)" />
                      </a-col>
                      <a-col :span="9">
                        <a-select style="width: 100%;" v-model="titem.uuid" @change="tableSelectChange($event, index, tindex)">
                          <a-select-option v-for="(sitem, uuid) in item.surveyTypes" :key="uuid" :value="sitem.uuid">
                            {{ sitem.type }}
                          </a-select-option>
                        </a-select>
                      </a-col>
                      <a-col :span="4">
                        <a-space :size="20">
                          <a @click="del(index, tindex)">删除</a>
                        </a-space>
                      </a-col>
                    </div>
                    <div class="addBox" @click="add(index)">
                      + 添加
                    </div>
                  </a-row>
                </div>
              </div>
            </div>
          </div>
        </a-space>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import 'moment/locale/zh-cn'
import UploadFile from '../../../../components/upload/UploadFile.vue'
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 }
}
export default {
  name: 'AddInfo',
  components: {
    UploadFile
  },
  props: {
  },
  data () {
    return {
      loading: false,
      formItemLayout,
      inputList: [
      ],
      initData: {},
      tableList: []
    }
  },
  created () {},
  mounted () {
  },
  watch: {
    initData: {
      deep: true,
      handler (val) {
        this.inputList = this.initData.mainPackageSurveyItemList
        this.inputList.forEach(e => {
          if (e.initiatorInputType === 4 && (!e.tableList || e.tableList.length === 0)) {
            e.tableList = [{initiatorFillIn: '', type: '', uuid: ''}]
          }
          if (e.initiatorInputType === 4 &&
          e.initiatorShowType === 9 &&
          e.inputSurveyTypes &&
          e.inputSurveyTypes.length > 1 &&
          e.inputSurveyTypes[1].inputType === 9 &&
          e.inputSurveyTypes[1].surveyTypes) {
            if (!this.$checkIsNotNull(e.inputSurveyTypes[1].surveyTypes[0].defaulted)) {
              e.value = e.defaulted
            }
          }
        })
        this.dataChange()
      }
    }
  },
  methods: {
    // 获取候选人端必要信息及文件
    getInputList (params = {}) {
      params.pageSize = 1
      params.pageNum = 100
      params.packageType = this.form.packageType
      this.$post('web/package-item/listCandidateNecessaryInfo', params).then((r) => {
        let data = r.data
        if (data.code === 200) {
          this.inputList = data.data || []
          this.inputList.sort()
        } else {
          this.$message.error(data.message)
          this.loading = false
        }
      })
    },
    handleSelect (e) {
      this.dataChange()
    },
    dataChange () {
      this.initData.mainPackageSurveyItemList = this.inputList
      this.$emit('change', this.initData)
    },
    add (index) {
      this.inputList[index].tableList.push(
        {initiatorFillIn: '', type: '', uuid: ''}
      )
      this.dataChange()
      this.$forceUpdate()
    },
    del (index, tindex) {
      this.inputList[index].tableList.splice(tindex, 1)
      this.dataChange()
      this.$forceUpdate()
    },
    tableInputChange (e, index, tindex) {
      // this.inputList[index].tableList[tindex].initiatorFillIn = e.data
      this.dataChange()
      this.$forceUpdate()
    },
    tableSelectChange (e, index, tindex) {
      this.inputList[index].tableList[tindex].uuid = e
      this.dataChange()
      this.$forceUpdate()
    },
    mulTiCallback (imgList, index) {
      this.inputList[index].value = imgList.join(',')
      this.dataChange()
    },
    handleTimeClear (value, index) {
      if (value.length === 0) {
        this.inputList[index].value = ''
      }
      this.dataChange()
    },
    handleTimeChange (date, index, tindex) {
      if (date.length === 0) {
        this.inputList[index].value = ''
      } else if (date.value === 2) {
        this.inputList[index].value = moment(date[0]).format('YYYY/MM/DD') + '-' + moment(date[1]).format('YYYY/MM/DD')
      }
      this.dataChange()
    },
    timeChange (value, index) {
      this.inputList[index].value = moment(value).format('YYYY/MM/DD')
      this.dataChange()
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;
}

.content {
  margin: 16px;
  background-color: #fff;
}

.headerBox {
  background-color: #fff;
  padding-top: 16px;
  .headerTitle {
    border-left: 3px solid #248AA3;
    padding-left: 8px;
    margin-left: 16px;
    margin-bottom: 16px;
    font-weight: bold;
  }
}
.ant-divider {
  margin: 6px 0 !important;
}
.ant-row {
  margin: 0 !important;
}
// .ant-col {
//   padding: 0 !important;
// }
.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.box1 {
  .subTitle1 {
    font-size: 12px;
    color: #248AA3;
  }
  .subTitle2 {
    font-size: 16px;
    color: #248AA3;
  }
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  background: #F7F8FA;
  border: 1px solid #248AA3;
}
.tableHeader {
  height: 32px;
  background: #FAFAFA;
}
.tableBox {
  width: 100%;
  border: 1px solid #999;
  margin: 5px 0;
}
.addBox {
  text-align: center;
  border: 1px dashed #D9D9D9;
  border-radius: 2px;
  margin: 10px 25px;
  min-width: 100px;
}
</style>
