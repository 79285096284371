<!--
我的背调
-->
<template>
  <div class="container">
    <div style="" class="header">
      <a-tabs
        :default-active-key="1"
        @change="tabChange"
        style="margin: 0 16px"
      >
        <a-tab-pane :key="1" tab="订单数据"> </a-tab-pane>
        <a-tab-pane :key="2" tab="报告数据" force-render> </a-tab-pane>
        <template #tabBarExtraContent>
          <a-dropdown>
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item key="1">导出图片</a-menu-item>
                <a-menu-item key="2">导出PDF</a-menu-item>
              </a-menu>
            </template>
            <a-button style="margin-top: 20px"> 导出数据 </a-button>
          </a-dropdown>
        </template>
      </a-tabs>
    </div>
    <div class="content">
      <OrderData ref="orderData" id="orderData" v-if="tabKey == 1" />
      <ReportData v-if="tabKey == 2" />
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import OrderData from './orderData.vue'
import ReportData from './reportData.vue'
export default {
  name: 'DataPage',
  components: {
    OrderData,
    ReportData
  },
  data () {
    return {
      tabKey: 1
    }
  },
  computed: {},
  mounted () { },
  methods: {
    tabChange (e) {
      this.tabKey = e
    },
    handleMenuClick (e) {
      if (e.key == '1') {
        // html2canvas(document.querySelector("#orderData"), { logging: false }).then(canvas => {
        //   document.body.appendChild(canvas)
        //   var dataUrl = canvas.toDataURL("image/png")
        //   const a = document.createElement('a')
        //   const event = new MouseEvent('click')
        //   a.download = "订单数据.png"
        //   a.href = dataUrl
        //   a.dispatchEvent(event)
        //   a.remove()
        // })
      }
    }
  },
  generateImage () {
    const elements = [document.querySelector("#orderData")] // 获取所有包含Echarts的DOM元素

    Promise.all(elements.map((element) => new Promise((resolve) => {
      html2canvas(element).then((canvas) => resolve({ element, canvas }))
    })))
      .then((results) => {
        const images = results.map((result) => result.canvas) // 提取每个Canvas对象
        const mergedCanvas = document.createElement('canvas') // 创建新的Canvas对象
        const context = mergedCanvas.getContext('2d')

        let totalWidth = 0
        for (let i = 0; i < images.length; i++) {
          const image = images[i]

          if (!image || !context) continue

          totalWidth += image.width + 10 // 设定间距
          mergedCanvas.height = Math.max(mergedCanvas.height, image.height)

          context.drawImage(image, totalWidth - image.width / 2, 0) // 根据位置绘制到新的Canvas上
        }

        const link = document.createElement('a')
        link.href = mergedCanvas.toDataURL() // 转换为Base64格式的图像地址
        link.download = '订单数据.png' // 指定保存文件名
        link.dispatchEvent(new MouseEvent('click')) // 模拟点击下载
      })
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
}

.header {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  background-color: #fff;
}

.content {
  margin: 16px 24px;
  background: none !important;
  padding: 0;
}
</style>
