<!--
岗位订单统计
-->
<template>
  <div class="container">
    <div class="content">
      <div class="headerBox" style="padding-bottom: 12px">
        <div class="headerTitle">岗位订单统计</div>
      </div>
      <div class="box1">
        <div id="postOrderChart" :class="className" :style="{ height: height, width: width }" />
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 }
}
export default {
  name: 'PostOrder',
  components: {
  },
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '306px'
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      formItemLayout,
      form: {},
      chart: null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler (val) {
        this.setOptions(val)
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart () {
      this.chart = echarts.init(document.getElementById('postOrderChart'))
      this.setOptions(this.chartData)
    },
    setOptions ({ positionOrderStatisticsList } = {}) {
      if (positionOrderStatisticsList) {
        this.chart.setOption({
          tooltip: {
            trigger: 'item'
          },
          grid: { // 让图表占满容器
            left: '0',
            right: '20%',
            top: '0',
            bottom: '0'
          },
          graphic: {
            type: 'text',
            left: 'center',
            top: '45%',
            style: {
              text: '',
              textAlign: 'center',
              fill: '#ccc',
              fontSize: 20
            }
          },
          legend: {
            orient: 'vertical',
            x: 'right',
            y: 'center',
            align: 'left',
            formatter: (name) => {
              let data = positionOrderStatisticsList || []
              let target
              let value
              if (data) {
                for (let i = 0, l = data.length; i < l; i++) {
                  if (data[i].name === name) {
                    target = data[i].scale
                    value = data[i].value
                  }
                }
                let arr = [
                  name + '  ' + target + '%' + '  ' + value + '个' + '  '
                ]
                return arr.join('\n')
              }
            }
          },
          series: [
            {
              type: 'pie',
              left: '0%',
              center: ['24%', '40%'],
              radius: ['40%', '60%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: false,
                  fontSize: 40,
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: positionOrderStatisticsList || []
            }
          ]
        })
      } else {
        this.chart.setOption({
          title: {
            text: '',
            left: 'center',
            top: 20,
            textStyle: {
              color: '#ccc'
            }
          },
          graphic: {
            type: 'text',
            left: 'center',
            top: '45%',
            style: {
              text: '暂无数据',
              textAlign: 'center',
              fill: '#ccc',
              fontSize: 20
            }
          }
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;
}

.content {
  margin: 8px 24px;
  // background-color: #fff;
}

.headerBox {
  // padding-top: 16px;
  .headerTitle {
    font-family: SourceHanSansCN-Medium-Medium;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0px;
    color: #0b2850;
  }
}
.ant-divider {
  margin: 0px !important;
}
.ant-row {
  margin: 0 !important;
}
.box1{
  border-radius: 16px;
  background: #FFFFFF;
}

</style>
