<template>
<div class="container">
  <div class="content">
    <h3>当前连续背调状态：{{status ? '已开启' : '未开启'}}</h3>
    <h3>选择连续背调开始时间</h3>
    <div style="">
      <a-date-picker
        :placeholder="`请选择日期时间`"
        @change="timeChange($event)"
      />
    </div>
  </div>
</div>
</template>

<script>
import moment from 'moment'
import 'moment/locale/zh-cn'

export default {
  name: 'AmongOptions',
  data () {
    return {
      startTime: ''
    }
  },
  props: ['id', 'status'],
  methods: {
    timeChange (value) {
      this.startTime = moment(value).format('YYYY-MM-DD')
    },
    edit () {
      console.log('edit', this.startTime)
      if (this.startTime) {
        const params = {
          id: this.id,
          nextMiddleEmployee: this.startTime
        }
        this.$post('/web/back-tone-order/resetNextMiddleEmployee', params)
          .then((res) => {
            const data = res.data
            if (data.code === 200) {
              this.$message.success('修改开始时间成功')
            } else {
              this.$message.error(data.message)
              this.loading = false
            }
          })
      }
    }
  }
}
</script>

<style>
.container {
  width: 100%;
}

.content {
  margin: 16px;
  background-color: #fff;
  box-sizing: content-box;
  padding: 16px 32px;
}

.freq {
  margin-top: 16px;
  margin-bottom: 16px;
}
</style>
