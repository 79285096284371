<!--
风险项统计
-->
<template>
  <div class="container">
    <div class="content">
      <div class="headerBox" style="padding-bottom: 12px">
        <div class="headerTitle">风险项统计</div>
      </div>
      <div class="box1">
        <div id="riskItemChart" :class="className"
          :style="{ height: height, width: width, zoom:'120%' }" />
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
export default {
  name: "RiskItem",
  components: {},
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "350px",
    },
    chartData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      formItemLayout,
      form: {},
      chart: null,
      zoom: ''
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.zoom = 1 / document.body.style.zoom;
      console.log( document.body.style.zoom)
      window.addEventListener("resize", () => {
        this.zoom = 1 / document.body.style.zoom;
      })
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(document.getElementById("riskItemChart"));
      this.setOptions(this.chartData);
    },
    setOptions({ riskItemStatisticsList } = {}) {
      if (riskItemStatisticsList) {
        let titleList = [];
        let dataList = [];
        if (riskItemStatisticsList) {
          riskItemStatisticsList.forEach((e) => {
            titleList.push(e.itemName);
            dataList.push(e.riskNum);
          });
        }
        this.chart.setOption({
          tooltip: {
            trigger: 'axis'
          },
          grid: {
            // 让图表占满容器
            left: "5%",
            right: "5%",
            top: "10%",
            bottom: "10%",
          },
          graphic: {
            type: "text",
            left: "center",
            top: "45%",
            style: {
              text: "",
              textAlign: "center",
              fill: "#ccc",
              fontSize: 20,
            },
          },
          xAxis: {
            type: "category",
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
              },
            },
            data: titleList,
          },
          yAxis: {
            type: "value",
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
              },
            },
          },
          series: [
            {
              data: dataList,
              type: "bar",
              barWidth: "30",
              label: {
                show: true,
                position: "top",
              },
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                {
                  offset: 0,
                  color: "#7ED0D6",
                },
                {
                  offset: 1,
                  color: "#DAF3F4",
                },
              ]),
              itemStyle: {
                emphasis: {
                  color: "#13A8BF"
                },
              },
            },
          ],
        });
      } else {
        this.chart.setOption({
          graphic: {
            type: "text",
            left: "center",
            top: "45%",
            style: {
              text: "暂无数据",
              textAlign: "center",
              fill: "#ccc",
              fontSize: 20,
            },
          },
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.echart-box {
  zoom: 80%
}

.container {
  width: 100%;
}

.content {
  margin: 8px 24px;
  // background-color: #fff;
}

.headerBox {
  // background-color: #fff;
  padding-top: 8px;

  .headerTitle {
    font-family: SourceHanSansCN-Medium-Medium;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0px;
    color: #0b2850;
  }
}

.ant-divider {
  margin: 0px !important;
}

.ant-row {
  margin: 0 !important;
}

.box1 {
  border-radius: 16px;
  background: #ffffff;
}
</style>
