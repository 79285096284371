<!-- 用户信息授权填写 -->
<template>
  <div class="container">
    <div class="header">
      <img src="../assets/img/logo3.svg" class="logoimg" />
      <!-- <span class="header_text">求职问是 雇佣归真</span> -->
    </div>
    <div class="sound-list">
      <div
        class="sound-item"
        v-for="(item, index) in audioData"
        :key="index + 1"
      >
        <div class="sound-title">{{ item.question }}</div>
        <div class="audio-box">
          <audio
            ref="audioPlayer"
            :src="item.audioFile.url"
            controls
            controlsList="nodownload noplaybackrate"
            @play="onPlay(index)"
            @pause="onPause(index)"
          ></audio>
        </div>
        <div v-if="item.audioFile.content">
          <div class="title">录音转文字</div>
          <div class="sound-content">
            {{ item.audioFile.content }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 }
}
export default {
  name: 'SoundDetails',
  data () {
    return {
      audioData: []
    }
  },
  created () { },
  mounted () {
    this.getAudioData()
  },
  computed: {
  },
  methods: {
    // playAudio () {
    //   this.$refs.audioPlayer.play()
    // },
    getAudioData () {
      this.$get('web/check-report/audioData', {
        type: this.$route.query.type,
        company: this.$route.query.company,
        certifier: this.$route.query.certifier,
        reportId: this.$route.query.reportId,
      }).then((r) => {
        let data = r.data
        this.loading = false
        if (data.code === 200) {
          this.loading = false
          this.audioData = data.data
        } else {
          this.$message.error(data.message)
          this.loading = false
        }
      })
    },
    onPlay (index) {
      // 通过ref获取对应的audio元素，并触发播放事件
      const audioElement = this.$refs.audioPlayer[index] // 假设你的ref命名为audios
      if (audioElement) {
        audioElement.play() // 播放音频
      }
      this.audioData.forEach((audio, i) => {
        if (i !== index) {
          const audioElement = this.$refs.audioPlayer[i] // 获取对应的audio元素
          audioElement.pause() // 停止播放
        }
      })
    },
    onPause (index) {
      const audioElement = this.$refs.audioPlayer[index] // 获取对应的audio元素
      if (audioElement) {
        audioElement.pause() // 暂停音频
      }
    }
  }
}
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.container {
  /* iPhone 14 Pro */
  position: relative;
  width: 100%;
  // height: 852px;
  height: 100%;

  /* logo头部 */
  .header {
    padding: 10px 20px;
    border-bottom: 1px solid #e0eaf3;
    .logoimg {
      height: 28px;
    }
  }
  //   录音内容
  .sound-list {
    padding: 20px 0;
    margin: 0 20px;
    .sound-item {
      border-bottom: 1px solid #e0eaf3;
      margin-bottom: 20px;
      .sound-title {
        font-size: 15px;
        color: #0b2850;
      }
      .audio-box {
        margin: 15px 0;
        // width: 100%;
        // background: #f5fcfd;
        // border-radius: 8px;
      }
      .title {
        font-size: 14px;
        color: #6e7070;
        margin-bottom: 10px;
      }
      .sound-content {
        border-radius: 8px;
        border: 1px solid #e0eaf3;
        padding: 5px 12px;
        line-height: 22px;
        letter-spacing: 0px;
        font-size: 14px;
        color: #0b2850;
        margin-bottom: 20px;
      }
    }
    .sound-list:last-child {
      border-bottom: 0px;
      margin-bottom: 0px;
    }
  }
}
</style>
