<!--
消息详情
-->
<template>
  <a-modal
    title="消息详情"
    :visible="visiable"
    :width="650"
    @cancel="onClose"
  >
    <a-row :gutter="40">
      <a-col :span="24">
        <a-form-model labelAlign="left">
          <a-form-model-item v-bind="formItemLayout" label="消息标题">
            {{ form.title }}
          </a-form-model-item>
          <a-form-model-item v-bind="formItemLayout" label="消息正文">
            {{ form.content }}
          </a-form-model-item>
          <a-form-model-item v-bind="formItemLayout" label="是否已读">
            <a-tag v-if="form.readed">已读</a-tag>
            <a-tag v-else>未读</a-tag>
          </a-form-model-item>
          <a-form-model-item v-bind="formItemLayout" label="创建时间">
            {{ form.createTime }}
          </a-form-model-item>
        </a-form-model>
      </a-col>
    </a-row>
    <template slot="footer">
      <a-button @click="onClose">关闭</a-button>
    </template>
  </a-modal>
</template>
<script>
import {mapMutations} from 'vuex'
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 }
}
export default {
  name: 'MessageDetail',
  components: {
  },
  data () {
    return {
      loading: false,
      formItemLayout,
      form: {},
      visiable: false
    }
  },
  created () {},
  computed: {
  },
  methods: {
    ...mapMutations({
      setMessageNum: 'account/setMessageNum'
    }),
    getDetail (uuid) {
      this.loading = true
      this.$get('web/message-notify/selectByUUID/' + uuid)
        .then((r) => {
          this.loading = false
          this.form = r.data.data
        })
        .catch(() => {
          this.loading = false
        })
    },
    getMessageNum () {
      this.$get('web/message-notify/selectUnreadMessageNum')
        .then((r) => {
          let messageNum = r.data.data
          this.setMessageNum(messageNum)
          // this.$db.save('MESSAGENUM', messageNum)
          this.$forceUpdate()
        })
        .catch(() => {
        })
    },
    onClose () {
      this.visiable = false
      this.getMessageNum()
      this.$emit('success')
    }
  }
}
</script>
<style scoped>
</style>
