var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"float-left-text"},[_vm._v("求职问是 雇佣归真")]),_c('a',[_c('img',{staticClass:"logo",attrs:{"src":require("../../assets/img/logo3.svg"),"alt":""},on:{"click":_vm.toWeb}})]),_c('div',{staticClass:"left"},[_vm._m(0),_c('div',{staticClass:"left-inner-2"},_vm._l((_vm.leftViewData),function(item){return _c('left-inner-2',{key:item.text1,attrs:{"text1":item.text1,"text2":item.text2,"img":item.img}})}),1),_c('img',{attrs:{"src":require("../../assets/img/zhuce.png")}})]),_c('div',{staticClass:"right"},[_vm._m(1),_c('div',{staticClass:"right-inner"},[_vm._m(2),_c('div',{staticClass:"right-content"},[_c('a-form',{attrs:{"form":_vm.registerForm},on:{"submit":function($event){$event.preventDefault();return _vm.handleLogin.apply(null, arguments)}}},_vm._l((_vm.registerFormModel),function(item){return _c('div',{key:item.key},[_c('div',{staticClass:"form-label"},[(item.rules && item.rules[0] && item.rules[0].required)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("* ")]):_vm._e(),_vm._v(_vm._s(item.name)+" ")]),(item.key !== 'code')?_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  item.key,
                  {
                    rules: item.rules,
                  },
                ]),expression:"[\n                  item.key,\n                  {\n                    rules: item.rules,\n                  },\n                ]"}],style:({ borderRadius: '8px !important' }),attrs:{"size":"large","type":item.type,"placeholder":item.placeholder}})],1):_c('div',{staticClass:"validate-code-wrapper"},[_c('a-form-item',{key:item.key},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    item.key,
                    {
                      rules: item.rules,
                    },
                  ]),expression:"[\n                    item.key,\n                    {\n                      rules: item.rules,\n                    },\n                  ]"}],style:({ borderRadius: '8px !important' }),attrs:{"size":"large","placeholder":item.placeholder}})],1),_c('a-button',{staticClass:"validate-code-btn",attrs:{"loading":_vm.codeLoading,"disabled":_vm.codeFlag,"size":"large","type":"primary"},on:{"click":_vm.getValidateCode}},[_vm._v("获取验证码 "+_vm._s(_vm.codeFlag ? `(${_vm.codeTime})` : ""))])],1)],1)}),0),_c('a-button',{staticClass:"login-button",attrs:{"loading":_vm.loading},on:{"click":_vm.handleRegister}},[_vm._v("立即体验")]),_c('div',{staticClass:"text-below-btn"},[_vm._v(" 已有帐号？"),_c('a',{attrs:{"href":`/#/login?${
              _vm.role === 'personal' ? 'role=personal' : 'role=enterprise'
            }`}},[_vm._v("去登录")])])],1)])]),_vm._m(3)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-inner-1"},[_c('div',{staticClass:"left-inner-1-text-1"},[_vm._v("CHECKEY")]),_c('div',{staticClass:"left-inner-1-text-2"},[_vm._v(" 致力于为企业提供最快、最公平的背景核查 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-titile"},[_c('div',{staticClass:"mobile-titile-text-1"},[_vm._v("CHECKEY")]),_c('div',{staticClass:"mobile-titile-text-2"},[_vm._v(" 致力于为企业提供最快、最公平的背景核查 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right-inner-header"},[_c('div',{staticClass:"header-text"},[_vm._v(" 新用户注册 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer-text"},[_vm._v(" ©CHECKEY2023 "),_c('a',{attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("浙ICP备2023024978号-1")]),_c('a',{attrs:{"href":"https://beian.mps.gov.cn/#/query/webSearch?code=33021202002472","rel":"noreferrer","target":"_blank"}},[_vm._v("浙公网安备33021202002472")])])])
}]

export { render, staticRenderFns }