import axios from 'axios'
import { message, Modal, notification } from 'ant-design-vue'
import moment from 'moment'
import store from '../store'
import db from 'utils/localstorage'
import router from '../router'
moment.locale('zh-cn')

// const BACKEND_API_HOST = `${BACKEND_API_HOST}`
// const ENTERPRISE_API_HOST = `${ENTERPRISE_API_HOST}`
// const PERSON_API_HOST = `${PERSON_API_HOST}`
// const CANDIDATE_API_HOST = `${CANDIDATE_API_HOST}`

// 统一配置
const YTVUE_REQUEST = axios.create({
  baseURL: `${ENTERPRISE_API_HOST}`,
  responseType: 'json',
  validateStatus (status) {
    // 200 外的状态码都认定为失败
    return status === 200
  }
})

// 拦截请求
YTVUE_REQUEST.interceptors.request.use((config) => {
  const expireTime = store.state.account.expireTime
  const now = moment().format('YYYYMMDDHHmmss')
  // 让token早10秒种过期，提升“请重新登录”弹窗体验
  if (now - expireTime >= -10) {
    Modal.error({
      title: '登录已过期',
      content: '很抱歉，登录已过期，请重新登录',
      okText: '重新登录',
      mask: false,
      onOk: () => {
        return new Promise((resolve, reject) => {
          db.clear()
          location.reload()
        }).catch(function (reason) {
          console.log('catch:', reason)
        })
      }
    })
  }
  // 有 token就带上
  if (store.state.account.token) {
    config.headers.Authentication = store.state.account.token
  }

  return config
}, (error) => {
  return Promise.reject(error)
})

// 拦截响应
YTVUE_REQUEST.interceptors.response.use((config) => {
  return config
}, (error) => {
  if (error.response) {
    const errorMessage = error.response.data === null ? '系统内部异常，请联系网站管理员' : error.response.data.message
    switch (error.response.status) {
      case 404:
        notification.error({
          message: '系统提示',
          description: '很抱歉，资源未找到',
          duration: 4
        })
        break
      case 403:
      case 401:
        notification.warn({
          message: '系统提示',
          description: '账号已过期，请重新登录！',
          duration: 4
        })
        router.push({ path: '/login' })
        break
      default:
        notification.error({
          message: '系统提示',
          description: errorMessage,
          duration: 4
        })
        break
    }
  }
  return Promise.reject(error)
})

const request = {
  post (url, params) {
    const contentType = (url === 'login') || (url === 'regist') ? 'application/x-www-form-urlencoded' : 'application/json'
    return YTVUE_REQUEST.post(url, params, {
      transformRequest: [(params) => {
        let result = ''
        if (url === 'login' || url === 'regist') {
          Object.keys(params).forEach((key) => {
            if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
              result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
            }
          })
        } else {
          result = JSON.stringify(params)
        }
        return result
      }],
      headers: {
        'Content-Type': contentType
      }
    })
  },
  put (url, params) {
    const contentType = (url.indexOf('login') > -1 || url.indexOf('avatar') > -1 || url.indexOf('password') > -1 || url.indexOf('password/reset') > -1) ? 'application/x-www-form-urlencoded' : 'application/json'
    return YTVUE_REQUEST.put(url, params, {
      transformRequest: [(params) => {
        let result = ''
        if (url.indexOf('login') > -1 || url.indexOf('avatar') > -1 || url.indexOf('password') > -1 || url.indexOf('password/reset') > -1) {
          Object.keys(params).forEach((key) => {
            if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
              result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
            }
          })
        } else {
          result = JSON.stringify(params)
        }
        return result
      }],
      headers: {
        'Content-Type': contentType
      }
    })
  },
  get (url, params) {
    let _params
    if (Object.is(params, undefined)) {
      _params = ''
    } else {
      _params = '?'
      for (const key in params) {
        if (params.hasOwnProperty(key) && params[key] !== null) {
          _params += `${key}=${params[key]}&`
        }
      }
    }
    return YTVUE_REQUEST.get(`${url}${_params}`)
  },
  delete (url, params) {
    let _params
    if (Object.is(params, undefined)) {
      _params = ''
    } else {
      _params = '?'
      for (const key in params) {
        if (params.hasOwnProperty(key) && params[key] !== null) {
          _params += `${key}=${params[key]}&`
        }
      }
    }
    return YTVUE_REQUEST.delete(`${url}${_params}`)
  },
  export (url, params = {}, fileName) {
    message.loading('导出数据中')
    return YTVUE_REQUEST.post(url, params, {
      transformRequest: [(params) => {
        // let result = ''
        // Object.keys(params).forEach((key) => {
        //   if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
        //     result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
        //   }
        // })
        return JSON.stringify(params)
      }],
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob'
    }).then((r) => {
      const content = r.data
      const blob = new Blob([content])
      // const fileName = `${new Date().getTime()}_导出结果.xlsx`
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a')
        elink.download = fileName
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href)
        document.body.removeChild(elink)
      } else {
        navigator.msSaveBlob(blob, fileName)
      }
    }).catch((r) => {
      console.error(r)
      message.error('导出失败')
    })
  },
  download (url, params, filename) {
    message.loading('文件传输中')
    return YTVUE_REQUEST.post(url, params, {
      transformRequest: [(params) => {
        let result = ''
        Object.keys(params).forEach((key) => {
          if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
            result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
          }
        })
        return result
      }],
      responseType: 'blob'
    }).then((r) => {
      const content = r.data
      const blob = new Blob([content])
      if ('download' in document.createElement('a')) {
        const elink = document.createElement('a')
        elink.download = filename
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href)
        document.body.removeChild(elink)
      } else {
        navigator.msSaveBlob(blob, filename)
      }
    }).catch((r) => {
      console.error(r)
      message.error('下载失败')
    })
  },
  upload (url, params) {
    return YTVUE_REQUEST.post(url, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  resumeOcr (url, params) {
    return YTVUE_REQUEST.post(url, params, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  originalGet (url, params) {
    let _params
    if (Object.is(params, undefined)) {
      _params = ''
    } else {
      _params = '?'
      for (const key in params) {
        if (params.hasOwnProperty(key) && params[key] !== null) {
          _params += `${key}=${params[key]}&`
        }
      }
    }
    return axios.get(`${url}${_params}`)
  },

  changeRole (role) {
    if (role === 1) {
      YTVUE_REQUEST.defaults.baseURL = ENTERPRISE_API_HOST
    }
    if (role === 2) {
      YTVUE_REQUEST.defaults.baseURL = PERSON_API_HOST
      // YTVUE_REQUEST.defaults.baseURL = ENTERPRISE_API_HOST
    }
    if (role === 0) {
      YTVUE_REQUEST.defaults.baseURL = CANDIDATE_API_HOST
    }
  }
}

export default request
