<template>
  <a-layout style="min-height: 100%">
    <drawer v-if="isMobile" :openDrawer="collapsed" @change="onDrawerChange">
      <sider-menu
        :theme="theme"
        :menuData="menuData"
        :collapsed="false"
        :collapsible="false"
        @menuSelect="onMenuSelect"
      />
    </drawer>
    <sider-menu
      :theme="theme"
      v-else-if="layout === 'side'"
      :menuData="menuData"
      :collapsed="collapsed"
      :collapsible="true"
    />
    <drawer :open-drawer="settingBar" placement="right">
      <setting />
    </drawer>
    <a-layout :style="{ paddingLeft: paddingLeft }" style="background-color: #F4F6FF;">
      <!-- <global-header :menuData="menuData" :collapsed="collapsed" @toggleCollapse="toggleCollapse"/> -->
      <div class="my-flex my-item-jc-sb" style="margin-right: 24px;">
        <div class="fixed-route-name">{{ routeName }}</div>
        <div class="right-name">
          <!-- <img src="../../../static/img/serch.svg" style="margin-left: 20px;display: inline-block;"> -->
          <header-message style="margin-top: 10px;margin-left: 16px;display: inline-block;"></header-message>
          <a-popover v-if="user && user.userType === 0" trigger="click" placement="bottomRight">
            <template slot="content">
              <a><p style="margin: 0" @click="open_back()">对个人发起背调</p></a>
              <a-divider  style="margin: 15px 0"></a-divider>
              <a><p style="margin: 0" @click="$message.warning('该功能正在内测中...')">对企业发起背调</p></a>
            </template>
            <!-- <a-button type="primary"> -->
              <img src="../../assets/img/Button.png" style="width: 112px;height: 40px;margin-left: 16px;display: inline-block;">
            <!-- </a-button> -->
          </a-popover>
          <img v-else src="../../assets/img/Button.png" style="width: 112px;height: 40px;margin-left: 16px;display: inline-block;" @click="open_back()">
        </div>
      </div>
      <a-layout-content
        :style="{ minHeight: minHeight }"
        :class="fixHeader ? 'fixed-header-content' : null"
        style="margin-top: 10px;"
      >
        <slot></slot>
      </a-layout-content>
      <a-layout-footer style="padding: 0.29rem 0;background-color: transparent;" class="copyright" >
        <global-footer :copyright="copyright" />
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
import GlobalHeader from './GlobalHeader'
import GlobalFooter from './GlobalFooter'
import Drawer from '~/tool/Drawer'
import SiderMenu from '~/menu/SiderMenu'
import Setting from '~/setting/Setting'
import { mapState, mapMutations } from 'vuex'
import { triggerWindowResizeEvent } from 'utils/common'
import HeaderMessage from './HeaderMessage.vue'
import { getDashboardSite } from 'utils/utils'

const minHeight = window.innerHeight - 64 - 24 - 66

let menuData = []

export default {
  name: 'GlobalLayout',
  components: { Setting, SiderMenu, Drawer, GlobalFooter, GlobalHeader, HeaderMessage },
  data () {
    return {
      minHeight: minHeight + 'px',
      collapsed: false,
      menuData: menuData,
      routeName: ''
    }
  },
  mounted () {
    this.getBreadcrumb()
  },
  updated () {
    this.getBreadcrumb()
  },
  computed: {
    paddingLeft () {
      return this.fixSiderbar && this.layout === 'side' && !this.isMobile
        ? `${this.sidebarOpened ? 236 : 80}px`
        : '0'
    },
    ...mapState({
      sidebarOpened: (state) => state.setting.sidebar.opened,
      isMobile: (state) => state.setting.isMobile,
      theme: (state) => state.setting.theme,
      layout: (state) => state.setting.layout,
      copyright: (state) => state.setting.copyright,
      fixSiderbar: (state) => state.setting.fixSiderbar,
      fixHeader: (state) => state.setting.fixHeader,
      settingBar: (state) => state.setting.settingBar.opened,
      user: (state) => state.account.user
    })
  },
  methods: {
    open_back () {
      if (this.user && this.user.userType === 0) {
        window.open(DASHBOARD_SITE + '/#/login?role=personal', '_blank')
        return
      }
      this.$router.push('/startBack')
    },
    ...mapMutations({ setSidebar: 'setting/setSidebar' }),
    toggleCollapse () {
      this.collapsed = !this.collapsed
      this.setSidebar(!this.collapsed)
      triggerWindowResizeEvent()
    },
    onDrawerChange (show) {
      this.collapsed = show
    },
    onMenuSelect () {
      this.toggleCollapse()
    },
    getBreadcrumb () {
      this.routeName = this.$route.name
    }
  },
  beforeCreate () {
    const routers = this.$db.get('USER_ROUTER')
    menuData = routers
      .find((item) => item.path === '/')
      .children.filter((menu) => {
        const meta = menu.meta
        if (typeof meta.isShow === 'undefined') {
          return true
        } else return meta.isShow
      })
  }
}
</script>

<style lang="less" scoped>
.setting {
  background-color: #1890ff;
  color: #fff;
  border-radius: 5px 0 0 5px;
  line-height: 40px;
  font-size: 22px;
  width: 40px;
  height: 40px;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.fixed-header-content {
  // margin: 16px 16px 0 !important;
}
.fixed-route-name {
  // margin: 56px 0px 0 !important;
  // background: #fff;
  padding:16px 24px ;
  font-family: SourceHanSansCN-Medium-Medium;
  font-size: 28px;
  font-weight: 500;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0px;

  /* 文字1 */
  color: #0b2850;
}
.right-name{
  border-radius: 30px;
  box-shadow: 14px 8px 20px 0px rgba(112, 144, 176, 0.08);
  background: #FFFFFF;
  width: 208px;
  height: 60px;
  box-sizing: border-box;
  margin-top: 5px;
}
</style>
