<!--
个人信息授权表
-->
<template>
  <div class="container">
    <div class="content">
      <div class="head_graphical"></div>
      <div class="headerBox">
        <a-tooltip placement="topLeft" trigger="click">
          <template slot="title">
            <div class="remark">{{ remark }}</div>
          </template>
          <span class="headerTitle">{{ title }}</span>
        </a-tooltip>
        <template v-if="title === '银行信用记录'">
          <a-tooltip placement="topLeft" trigger="click">
            <template slot="title">
              <span v-html="BankCreditAppendix"></span>
            </template>
            <a-icon type="question-circle" :style="{ fontSize: '17px', color: '#13A8BF', marginLeft: '6px' }" />
          </a-tooltip>
        </template>
      </div>
      <div class="my-primary-padding my-flex-column">
        <div v-for="(fillItem, index1) in candidateFillIns" :key="index1">
          <div v-if="candidateFillIns.length > 1">
          <br>
          <div>{{title + (1 + index1)}}</div>
          </div>
          <div class="company-name" v-if="companyList[index1]">
            {{ "公司" + (index1 + 1) + "名称：" }}
            <span>{{ companyList[index1] }}</span>
          </div>
          <div v-if="fillItem.candidateNecessaryInfos">
              <div
                v-for="(info, index2) in fillItem.candidateNecessaryInfos"
                :key="index2"
              >
                <template v-if="info.inputType !== 0 || info.key !== 'random_interview' || fillItem.autoSearchCertifier">
                  <div class="label_name">{{ info.name }}</div>
                  <a-form-model labelAlign="left" style="width: 100%">
                      <a-form-model-item v-bind="formItemLayout" :validateStatus="(!checking || info.candidateFillIn) ? 'success' : 'error'">
                        <template slot="extra" v-if="checking && !info.candidateFillIn">
                          <span style="color:red">{{`请输入${info.name}!`}}</span>
                        </template>
                        <template v-if="info.inputType === 0">
                          <a-radio-group
                            :placeholder="`请选择${info.name}`"
                            v-model="info.candidateFillIn"
                            :defaultValue="0"
                          >
                            <a-radio
                              v-for="op in info.config.options"
                              :key="op.key"
                              :value="op.value"
                              >{{ op.key }}</a-radio
                            >
                          </a-radio-group>
                          <span
                            class="my-margin-l-10 my-gray-color"
                            v-if="info.config && info.config.hint"
                            >{{ `(${info.config.hint})` || "" }}</span
                          >
                        </template>
                        <!--下拉选择-->
                        <a-select
                          :placeholder="`请选择${info.name}`"
                          v-model="info.candidateFillIn"
                          v-if="info.inputType === 1"
                          :disabled="info.config.disableFill"
                        >
                          <a-select-option
                            v-for="op in info.config.options"
                            :key="op.key"
                            :value="op.value"
                          >
                            {{ op.key }}
                          </a-select-option>
                        </a-select>
                        <!-- 输入 -->
                        <template v-if="info.inputType === 2">
                          <a-input
                            :disabled="info.config.disableFill"
                            v-model="info.candidateFillIn"
                            :placeholder="`请输入${info.name}`"
                          /><span class="my-margin-l-10" v-if="info.config">{{
                            info.config.unit || ""
                          }}</span>
                        </template>
                        <!-- 文件上传 -->
                        <template v-if="info.inputType === 3">
                          <upload-file
                            :placeholder="`请上传${info.name}`"
                            fileType="all"
                            :fileSize="7"
                            @callback="imgCallback($event, index1, index2)"
                          />
                        </template>
                        <!-- 数值输入 -->
                        <template v-if="info.inputType === 5">
                          <a-input-number
                            :min="1"
                            :default-value="1"
                            v-model="info.candidateFillIn"
                            :placeholder="`请输入${info.name}`"
                          /><span class="my-margin-l-10" v-if="info.config">{{
                            info.config.unit || ""
                          }}</span>
                        </template>
                        <!-- 时间选择 -->
                        <template v-if="info.inputType === 6">
                          <a-month-picker
                            :placeholder="`请选择${info.name}`"
                            :value="defaultDateValue(info.candidateFillIn)"
                            @change="timeChange($event, index1, index2)"
                          />
                        </template>
                        <!-- 时间段选择 -->
                        <template v-if="info.inputType === 7">
                          <MRangePicker
                            class="m-range-picker"
                            :defaultValue="defaultDateRangeValue(info.candidateFillIn)"
                            @change="handleTimeChange($event, index1, index2)"
                            @ok="handleTimeChange($event, index1, index2)"
                          />
                          <!-- <a-range-picker
                            picker="month"
                            :show-time="false"
                            format="YYYY-MM-DD"
                            :placeholder="['开始时间', '结束时间']"
                            :defaultValue="defaultDateRangeValue(info.candidateFillIn)"
                            @change="handleTimeChange($event, index1, index2)"
                            @ok="handleTimeChange($event, index1, index2)"
                          > -->
                            <!-- <a-icon slot="suffixIcon" type="calendar" />
                          </a-range-picker> -->
                        </template>
                        <!-- 多文件上传 -->
                        <template v-if="info.inputType === 8">
                          <upload-file
                            :placeholder="`请上传${info.name}`"
                            fileType="all"
                            :fileNum="999"
                            :fileSize="1024"
                            :resumeOcr="isResumeOcr(info)"
                            :passportOcr="isPassportOcr(info)"
                            @onPassportOcr="onPassportOcr($event, index1, index2)"
                            @onResumeOcr="onResumeOcr($event, index1, index2)"
                            @callback="mulTiCallback($event, index1, index2)"
                            :initList="info.candidateFillIn ? info.candidateFillIn.split(',') : []"
                          />
                        </template>
                      </a-form-model-item>
                  </a-form-model>
                </template>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import 'moment/locale/zh-cn'
// import UploadFile from '../../../components/upload/UploadFile.vue'
import UploadFile from '../../components/upload/UploadFile.vue'
import MRangePicker from './MRangePicker'
import Mixins from '../../mixins/reportAuth'
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 }
}
export default {
  name: 'MInfoBox',
  mixins: [Mixins],
  components: {
    UploadFile,
    MRangePicker
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    remark: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    },
    candidateFillInsList: {
      type: Array,
      default: () => []
    },
    checking: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      formItemLayout,
      form: {},
      candidateFillIns: [],
      itemStates: []
    }
  },
  created () {},
  mounted () {
    this.candidateFillIns = this.candidateFillInsList
  },
  computed: {
    companyList () {
      return this.candidateFillIns.map(item1 => {
        return this.title === '工作履历' || this.title === '工作表现' ? item1.initiatorFillIn : ''
      })
    },
  },
  watch: {
    candidateFillInsList: {
      deep: true,
      handler (val) {
        console.log(val)
        this.candidateFillIns = this.candidateFillInsList
      }
    },
    candidateFillIns: {
      deep: true,
      handler (val) {
        this.$emit('change', val)
      }
    }
  },
  methods: {
    defaultDateValue (input) {
      return input ? moment(input) : undefined
    },
    defaultDateRangeValue (input) {
      if (input) {
        const dates = input.split('-')
        return [moment(dates[0]), moment(dates[1])]
      } else return []
    },
    imgCallback (imgList, index1, index2) {
      this.candidateFillIns[index1].candidateNecessaryInfos[
        index2
      ].candidateFillIn = imgList[0]
    },
    mulTiCallback (imgList, index1, index2) {
      this.candidateFillIns[index1].candidateNecessaryInfos[
        index2
      ].candidateFillIn = imgList.join(',')
    },
    handleTimeChange (value, index1, index2) {
      if (value.length === 0) {
        this.candidateFillIns[index1].candidateNecessaryInfos[
          index2
        ].candidateFillIn = ''
      } else if (value.length === 2) {
        this.candidateFillIns[index1].candidateNecessaryInfos[
          index2
        ].candidateFillIn =
          moment(value[0]).format('YYYY/MM') +
          '-' +
          moment(value[1]).format('YYYY/MM')
      }
      this.$forceUpdate()
    },
    timeChange (value, index1, index2) {
      this.candidateFillIns[index1].candidateNecessaryInfos[
        index2
      ].candidateFillIn = moment(value).format('YYYY/MM')
      this.$forceUpdate()
    },
    validate () {
      return Promise.resolve()
      // console.log(this.$refs.formRef)
      // return Promise.all(this.$refs.formRef.map(ref => ref.validate()))
      // return this.$refs.formRef.forEach(ref => {
      //   ref.validate && ref.validate(valid => {
      //     if (!valid) {
      //       throw 'error' // eslint-disable-line
      //     }
      //   })
      // })
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;
  border: 1px solid #E0EAF3;

  border-radius: 12px;
}

.content {
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 8px;
  background: #ffffff;
  position: relative;
}
.head_graphical {
  height: 17px;
  width: 5px;
  position: absolute;
  top: 15px;
  left: 0;
  opacity: 1;
  background: #13a8bf;
}

.headerBox {
  background-color: #fff;
  .headerTitle {
    font-family: Source Han Sans;
    font-size: 17px;
    font-weight: bold;
    line-height: 17px;
    vertical-align: top;
    font-weight: bold;
    color: #0b2850;
    font-feature-settings: "kern" on;
  }
}
// 别的页面的样式影响
.my-primary-padding {
  padding: 0px !important;
}
.my-margin-l-10 {
  display: flex;
  font-size: 15px !important;
  align-items: center;
  margin-left: 0px !important;
}
// 组件库里的样式，需要样式穿透才能生效
/deep/ .ant-form-item-control {
  line-height: normal !important;
}
/deep/ .ant-btn,
.ant-input,
.input,
.ant-pagination-item,
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link,
.ant-tag,
.ant-modal-content,
// .ant-select-selection,
.ant-select-dropdown,
.ant-input-group-addon,
.ant-input-number-input,
.ant-input-number,
.ant-pagination-options-quick-jumper input,
.m-range-picker,
.ant-alert {
  border-radius: 8px !important;
  height: 40px !important;
  width: 100% !important;
  align-items: center;
  font-size: 15px !important;
  padding: 0 16px;
  background: #f3f6ff !important;
}
/deep/ .ant-select-selection {
  border-radius: 8px !important;
  height: 40px !important;
  font-size: 15px !important;
  padding: 0 16px;
  background: #f3f6ff !important;
}
/deep/ .ant-calendar-picker-input.ant-input {
  display: flex;
  align-items: center;
  border-radius: 8px !important;
  min-height: 40px !important;
  height: fit-content;
  font-size: 14px !important;
  background: #f3f6ff !important;
  padding: 8px;
}
/deep/ .ant-calendar-range-picker-separator {
  height: unset !important;
}
/deep/ .ant-select-selection__rendered {
  height: 100%;
  line-height: 40px !important;
  margin-left: 0px;
}
/deep/ .ant-input-number-input {
  height: 40px !important;
  // line-height: 35px
}
/deep/ .ant-col-14 {
  width: 100%;
}
.label_name {
  font-family: SourceHanSansCN-Regular-Regular;
  font-size: 15px;
  font-weight: normal;
  line-height: 15px;
  letter-spacing: 0px;
  color: #6e7070;
  margin-top: 15px;
  margin-bottom: 5px;
}
.ant-divider {
  margin: 6px 0 !important;
}
.ant-row {
  margin: 0 !important;
}
.company-name {
  width: 100%;
  height: 40px;
  border-radius: 8px 8px 0px 0px;
  line-height: 40px;
  opacity: 1;
  text-align: center;
  /* BG disable */
  background: #f3f6ff;
  color: #6e7070;
  font-size: 14px;
  margin-top: 20px;
  span {
    font-size: 14px;
    font-weight: 500;
    color: #0b2850;
  }
}
</style>
