<template>
  <div class="clearfix flex-wrapper-all-center">
    <a-upload
      name="file"
      list-type="picture-card"
      class="yt-uploader"
      :show-upload-list="!icon.name"
      :file-list="imageList"
      :before-upload="beforeUpload"
      :remove="handleRemove"
      :customRequest="handleUploadImage"
      @change="handleChange"
      @preview="handlePreview"
    >
      <div v-if="icon.name && imageList.length > 0">
        <!-- 小图展示（icon模式下） -->
        <div v-if="icon.scale">
          <img v-for="(item, itemIndex) in imageList" v-bind:key="itemIndex" :src="item.url" class="img-limit-icon" :style="{width: icon.scale+'em', height: icon.scale+'em' }">
        </div>
        <div v-else>
          <img v-for="(item, itemIndex) in imageList" v-bind:key="itemIndex" :src="item.url" class="img-limit-icon" :style="{width: iconDefaultScale+'em', height: iconDefaultScale+'em' }">
        </div>
      </div>
      <div v-if="imageList.length < fileNum">
        <a-space class="flex-wrapper-all-center" size="large" align="center">
          <img v-if="backgroundImage" :src="backgroundImage" alt=""
               :class="icon.name ? 'img-limit-icon' : 'img-limit-pic'" />
          <!-- <a-space direction="vertical"
                   :class="['my-width-100', 'height-100', 'flex-wrapper-all-center', icon.name ? 'img-limit-icon' : 'img-limit-pic']">
            <a-icon v-if="loading" type="loading"/>
            <div v-if="!icon.name" class="ant-upload-text">
              {{ text }}
            </div>
          </a-space> -->
        </a-space>
      </div>
    </a-upload>
    <!-- 预览框 -->
    <a-modal :visible="previewVisible" :footer="null" :destroyOnClose="true" @cancel="handleCancelPreview">
      <img alt="预览详情" style="width: 100%" :src="previewImage"/>
    </a-modal>
  </div>
</template>

<script>

function getBase64 (img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default {
  name: 'UploadImage',
  props: {
    // 初始化列表
    initList: {
      type: Array,
      required: false,
      default: () => ([])
    },
    // 设置上传文本的显示
    text: {
      type: String,
      required: false,
      default: '上传'
    },
    // 文件类型：all-全部 image-图片 video-视频 audio-音频 zip-压缩包 custom-自己定义（使用fileTypeList 参考 https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept https://developer.mozilla.org/zh-CN/docs/Web/HTTP/Basics_of_HTTP/MIME_types）
    fileType: {
      type: String,
      required: false,
      default: 'image'
    },
    // 文件类型列表
    fileTypeList: {
      type: Array,
      required: false,
      default: () => ([])
    },
    // 设置上传文件的个数 默认1（当大于1时为列表展示）
    fileNum: {
      type: Number,
      required: false,
      default: 1
    },
    // 设置上传文件的大小 单位M，默认5M
    fileSize: {
      type: Number,
      required: false,
      default: 5
    },
    // 设置上传的背景图片（路径）
    backgroundImage: {
      type: String,
      required: false,
      default: ''
    },
    // TODO: 新增背景图片位置（左中右上下）
    // icon 只展示icon部分 格式为Object {name: 'iconName', scale='1.2'}
    icon: {
      type: Object,
      required: false,
      default: () => ({})
    },
    // 携带的无效数据，用于数据返回（如指定某一列时可以传递某一列索引等）
    stamp: {
      required: false,
      default: ''
    },
    // 返回类型：object: 返回文件对象数组 url: 返回url数组
    returnType: {
      required: false,
      default: 'url'
    },
    // 来源
    from: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      fileList: [],
      imageList: [],
      // 预览
      previewVisible: false,
      previewImage: '',
      iconDefaultScale: 1.2 // 默认icon缩放比例
    }
  },
  watch: {
    initList (newVal) {
      this.imageList = this.handleFileData(newVal)
    }
  },
  created () {
    this.imageList = this.handleFileData(this.initList)
  },
  methods: {
    // 文件修改事件
    handleChange ({ file, fileList }) {
      // if (file.status !== 'uploading') {
      //   console.log(file, fileList)
      // }
      // this.imageList = fileList
      // this.callback()
    },
    // 娇艳文件
    beforeUpload (file) {
      return new Promise((resolve, reject) => {
        let fileTypeList = this.getFileTypeList(this.fileType)
        if (fileTypeList.length > 0) {
          const index = fileTypeList.indexOf(file.type)
          if (index < 0) {
            this.$message.error('您不能上传该格式的文件')
            return reject(new Error('您不能上传该格式的文件'))
          }
        }
        const fileSize = file.size / 1024 / 1024
        const isFileSizeLegal = fileSize < this.fileSize
        if (!isFileSizeLegal) {
          this.$message.error(`文件大小不能大于${this.fileSize}MB`)
          return reject(new Error(`文件大小不能大于${this.fileSize}MB`))
        }
        let formData = new FormData()
        formData.append('file', file)
        this.loading = true
        this.$upload('/minio-file/upload', formData).then((res) => {
          let data = res.data
          if (data.code === 200) {
            let index = {
              uid: this.genId(5),
              name: file.name,
              status: 'done',
              url: data.data.urlPath
            }
            this.imageList = [...this.imageList.filter(item => item.status === 'done'), index]
            if (this.imageList.length > this.fileNum) this.imageList.shift() // 移除超出的文件
            this.$message.success('上传成功')
          } else {
            this.$message.error(data.message)
          }
          this.loading = false
          this.callback()
        }).catch((r) => {
          console.error(r)
          this.$message.error('上传失败')
          this.loading = false
        })
        return resolve(true)
      })
    },
    // 防止请求404 自定义请求
    handleUploadImage () {
      console.debug('empty upload')
    },
    // 处理移除文件
    handleRemove (file) {
      if (this.loading) {
        this.$message.warning('文件上传中，请勿删除')
        return
      }
      const index = this.imageList.indexOf(file)
      const newFileList = this.imageList.slice()
      newFileList.splice(index, 1)
      this.imageList = newFileList
      this.callback()
    },
    // 预览
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleCancelPreview () {
      this.previewVisible = false
    },
    // 生成随机ID
    genId (length) {
      return Number(Math.random().toString().substr(3, length) + Date.now()).toString(36)
    },
    // 处理文件数据
    handleFileData (list) {
      return list.map(item => {
        let index = this.genId(5)
        return {
          uid: index,
          name: index,
          status: 'done',
          url: item
        }
      })
    },
    /**
     * 定义文件类型
     * @param fileType all-全部 image-图片 video-视频 audio-音频 zip-压缩包 custom-自定义
     * @returns {string[]}
     */
    getFileTypeList (fileType) {
      // MIME可通过 https://www.iana.org/assignments/media-types/media-types.xhtml 查询
      let fileTypeList = []
      switch (fileType) {
        case 'all':
          fileTypeList = []
          break
        case 'image':
          fileTypeList = ['image/jpeg', 'image/png', 'image/jpg']
          break
        case 'video':
          fileTypeList = ['video/mp4', 'video/ogg', 'video/MP4V-ES']
          break
        case 'audio':
          fileTypeList = ['audio/mpeg', 'audio/ogg', 'audio/mp4']
          break
        case 'zip':
          fileTypeList = ['application/zip', 'application/x-rar-compressed']
          break
        case 'custom':
          fileTypeList = this.fileTypeList
          break
        default:
          fileTypeList = []
          break
      }
      return fileTypeList
    },
    // 结束回调
    callback () {
      this.imageList = [...this.imageList.filter(item => item.status === 'done')]
      let callbackArray = [...this.imageList]
      if (this.returnType === 'url') {
        // 返回精简的url格式
        callbackArray = this.imageList.filter(item => item.url).map(item => {
          return item.url
        })
      }
      this.$emit('callback', callbackArray || [], this.stamp)
    }
  }
}
</script>

<style lang="less" scoped>

.yt-uploader {
  .ant-upload {
    width: 100%;
    height: 100%;
    background-color: transparent !important;
  }

  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }

  // icon的图片限制
  .img-limit-icon {
    min-width: 1em;
    max-width: 3em;
    min-height: 1em;
    max-height: 3em;
  }

  // pic大图的图片限制
  .img-limit-pic {
    width: 100%;
    // min-width: 6em;
    // max-width: 10em;
    // min-height: 6em;
    // max-height: 10em;
  }
}
</style>
