import { render, staticRenderFns } from "./Auth.vue?vue&type=template&id=0c3c389d&scoped=true"
import script from "./Auth.vue?vue&type=script&lang=js"
export * from "./Auth.vue?vue&type=script&lang=js"
import style0 from "./Auth.vue?vue&type=style&index=0&id=0c3c389d&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c3c389d",
  null
  
)

export default component.exports