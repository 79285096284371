<template>
  <div class="ql-snow">
      <div class="editor ql-editor yt-quill-editor" v-html="value"></div>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.bubble.css'
import 'quill/dist/quill.snow.css'
import '@/assets/css/ql-font.css'

export default {
  name: 'YtQuillEditorPreview',
  props: ['value'],
  data () {
    return {

    }
  }
}
</script>

<style lang="less" scoped>
  .editor {
    width: 100%;
    height: 100%;
    white-space: normal !important;
    // white-space: nowrap;
    word-wrap: break-word;
    word-break: normal;
  }
</style>
<style lang="less">
// .editor {
//   p {
//     white-space: pre-wrap;
//     // img {
//     //   width: 100%;
//     //   display: block;
//     // }
//   }
// }
.yt-quill-editor {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.ql-container {
  // 编辑器内容用
  min-height: 20em !important;
}

.ql-snow .ql-picker {
  line-height: 24px;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9 !important;
  overflow: visible;
  word-break: normal;
  white-space: normal;
}
</style>
