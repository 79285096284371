<!--
基础统计
-->
<template>
  <div class="container">
    <div class="content">
      <div class="headerBox my-flex my-item-jc-sb my-item-center">
        <div class="headerTitle" style="flex: 3;">基础统计</div>
        <div class="my-flex my-item-jc-sb my-item-center" style="flex:1.4;margin-right: 16px;">
          <div class="headerTitle" style="margin-left: 10px;">账号信息</div>
          <!-- <div style="color: #13A8BF;font-family: SourceHanSansCN-Regular-Regular;
font-size: 16px;cursor: pointer;">账号中心></div> -->
        </div>
      </div>
      <div class="my-primary-padding my-flex my-item-jc-sb my-item-center" style="overflow: hidden;">
        <div class="my-flex-column box1" style="flex: 2;">
          <div class="my-flex-column topBox">
            <span class="title" style="font-size: 18px;color: #0B2850;"><img src="../../../../assets/img/date.svg" style="margin-right: 10px;">已出报告</span>
            <a class="value1" @click="toReport" style="margin-top: 12px;font-weight: 900;margin-bottom: 12px;">{{
              chartData.reportIssuedNum || 0
            }}</a>
          </div>
          <a-divider />
          <div class="bottomBox">
            <a-row>
              <a-col :span="12">
                <div class="my-flex-column">
                  <span class="title"><img src="../../../../assets/img/right.svg" style="margin-right: 4px;margin-bottom: 5px;"><span>无警示</span></span>
                  <a class="value2" @click="toReport" style="margin-left: 4px;margin-top: 8px;font-weight: bold;">{{
                    chartData.noWarnReportNum || 0
                  }}</a>
                </div>
              </a-col>
              <a-col :span="12">
                <div class="my-flex-column">
                  <span class="title"><img src="../../../../assets/img/delete.svg"
                      style="margin-right: 4px;margin-bottom: 5px;"><span>有警示</span></span>
                  <a class="value2" @click="toReport" style="color: red;margin-left: 4px;margin-top: 8px;font-weight: bold;">{{
                    chartData.warnReportNum || 0
                  }}</a>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
        <div class="my-flex-column box1 my-margin-l-10" style="flex: 1;">
          <div class="my-flex-column topBox">
            <span class="title"  style="font-size: 18px;color: #0B2850;"><img src="../../../../assets/img/message.svg" style="margin-right: 10px;">调查中</span>
            <a class="value1" @click="toOrder" style="margin-top: 12px;margin-bottom: 12px;font-weight: 900;">{{
              chartData.underCheckNum || 0
            }}</a>
          </div>
          <a-divider />
          <div class="my-flex-column bottomBox">
            <span class="title">加急中</span>
            <a class="value2" @click="toOrder" style="margin-top: 8px;font-weight: bold;">{{
              chartData.expeditedNum || 0
            }}</a>
          </div>
        </div>
        <div class="my-flex-column box1 my-margin-l-10" style="flex: 1;overflow: hidden;" >
          <div class="my-flex-column topBox">
            <span class="title"  style="font-size: 18px;color: #0B2850;"><img src="../../../../assets/img/antc.svg" style="margin-right: 10px;">待授权</span>
            <a class="value1" @click="toOrder" style="margin-top: 12px;font-weight: 900;">{{ chartData.toBeAuthNum }}</a>
          </div>
          <a-divider style="margin-top: 12px;"/>
          <div class="my-flex-column bottomBox">
            <span class="title">累计授权</span>
            <a class="value2" @click="toOrder" style="margin-top: 8px;font-weight: bold;">{{
              chartData.totalAuthNum || 0
            }}</a>
          </div>
        </div>
        <div class="my-flex-column box1 my-margin-l-10" style="flex: 2;">
          <div class="my-flex-column topBox">
            <span class="title" style="font-size: 18px;color: #0B2850;"><img src="../../../../assets/img/accont.svg" style="margin-right: 10px;">账号余额</span>
            <div class="my-flex my-item-jc-sb my-item-center">
              <span class="value1" style="margin-top: 12px;margin-bottom: 12px;font-weight: 900;">{{ chartData.balance || 0 }}</span>
              <a-button type="primary" class="my-margin-l-10" @click="toCharge" shape="round" size="small" style="
                  border-radius: 32px !important;
                  background-color: #13a8bf;
                  border-color: #13a8bf;
                  width: 96px;
                  height: 32px;
                ">立即充值</a-button>
            </div>
          </div>
          <a-divider />
          <div class="my-flex-column bottomBox">
            <span class="title">可开发票</span>
            <div class="my-flex my-item-jc-sb my-item-center">
              <span class="value2" style="margin-top: 8px;font-weight: bold;">{{ chartData.invoiceAbleAmount || 0 }}</span>
              <a class="my-margin-l-10" @click="toBill"
                style="color: #13A8BF;font-size: 16px;line-height: 16px;font-family: SourceHanSansCN-Regular-Regular;">去开发票</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
export default {
  name: "BasicStatistics",
  components: {},
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      formItemLayout,
      form: {},
    };
  },
  created() { },
  mounted() { },
  methods: {
    toOrder() {
      this.$router.push("order/all", "");
    },
    toReport() {
      this.$router.push("order/report", "");
    },
    toCharge() {
      this.$router.push("person/vip/recharge", "");
    },
    toBill() {
      this.$router.push("person/bill", "");
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
}

.content {
  // margin: 16px;
  // background-color: #fff;
}

.headerBox {
  margin-left: 24px;
  // background-color: #fff;
  padding-top: 20px;

  .headerTitle {
    font-family: SourceHanSansCN-Medium-Medium;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0px;
    color: #0b2850;
  }
}

.box1 {
  min-width: 200px;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 26px 20px 20px 20px;
  align-self: stretch;
  background: #ffffff;

  .topBox {
    height: 70px;
  }

  .bottomBox {
    // margin: 12px 0;
  }

  // .ant-divider {
  //   margin: 8px 0 !important;
  // }
  .title {
    font-size: 16px;
    line-height: 16px;
    color: #6E7070;
  }

  .value1 {
    font-family: SourceHanSansCN-Heavy-Heavy;
    font-size: 32px;
    font-weight: normal;
    line-height: 32px;
    letter-spacing: 0px;
    /* 文字1 */
    color: #0B2850;
  }

  .value2 {
    font-size: 20px;
    line-height: 20px;
    font-family: SourceHanSansCN-Bold-Bold;
    color: #0B2850;
  }
}

.ant-divider {
  margin: 12px 0px !important;
}

.ant-row {
  margin: 0 !important;
}
</style>
