<!--
企业用户注册
-->
<template>
  <div class="container">
    <div class="content">
      <div class="headerBox">
        <div class="headerTitle">企业用户注册</div>
      </div>
      <a-divider />
      <div class="my-primary-padding my-flex-column">
        <div class="intro">
          <div>1、上传营业执照的图片或者扫描件</div>
          <div>2、您也可以签订背调服务协议开始背调合作，具体可联系：{{ $store.state.setting.info.mobile }}</div>
          <div>3、若您上传不了营业执照，可将其附件发送至：renzheng@checkey.net</div>
          <div>4、上传的证件或声明仅供7氪Checkey认证使用，我们不会对外展示</div>
        </div>
        <div class="box">
          <a-row :gutter="20">
            <a-col :span="3" class="title my-margin-b-10">企业名称</a-col>
            <a-col :span="21" class="my-margin-b-10">
              <a-input style="width: 300px;" v-model="enterpriseName" placeholder="请输入企业名称" />
            </a-col>
            <a-col :span="3" class="title">上传营业执照</a-col>
            <a-col :span="8">
              <div>
                <a-upload
                  name="file"
                  :multiple="true"
                  :showUploadList="false"
                  :before-upload="beforeUpload"
                  :customRequest="handleUploadImage"
                  @change="handleUpload"
                >
                  <div v-if="imageList.length > 0">
                    <img v-for="(item, index) in imageList" :key="index" :src="item" style="width: 100%;margin-bottom: 10px;" />
                  </div>
                  <img src="../../assets/img/e.png" style="width: 100%" v-else />
                </a-upload>
                <div>提交认证即表示同意<a @click="show">《实名认证协议》</a></div>
                <a-button type="primary" style="margin-top: 30px;background: #248AA3" @click="handleSubmit">提交认证</a-button>
              </div>
            </a-col>
            <a-col :span="1" class="title">示例</a-col>
            <a-col :span="6">
              <div>
                <img src="../../assets/img/demo1.png" style="width: 100%" />
              </div>
            </a-col>
            <a-col :span="5">
              <div class="">
                <div>1. 完整清晰的图片或扫描件</div>
                <div>2. 营业执照信息需无遮挡，且清晰可见</div>
                <div>3. 照片支持jpg/png/pdf格式，大小不超过7M</div>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
    <text-modal ref="textRef" />
  </div>
</template>
<script>
// import TextModal from '@/views/manage/startBack/components/TextModal.vue'
import { protocol } from './protocol'
import TextModal from '../manage/startBack/components/TextModal.vue'
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 }
}
export default {
  name: 'Enterprise',
  components: {
    TextModal
  },
  props: {
  },

  data () {
    return {
      loading: false,
      formItemLayout,
      enterpriseName: '',
      initData: {},
      fileList: [],
      imageList: [],
      fileNum: 1
    }
  },
  created () {},
  mounted () {
  },
  methods: {
    show () {
      this.$refs.textRef.text = protocol.text
      this.$refs.textRef.title = protocol.title
      this.$refs.textRef.visiable = true
    },
    // 用户认证
    handleSubmit (e) {
      if (this.imageList.length !== 1) {
        this.$message.error('请上传企业认证图片')
        return
      }
      if (!this.enterpriseName) {
        this.$message.error('请填写企业名称')
        return
      }
      this.$post('web/user/userApplyAuth', {
        certificationData: this.imageList,
        enterpriseName: this.enterpriseName
      })
        .then((r) => {
          const data = r.data
          if (data.code === 200) {
            this.$message.success('上传认证信息成功')
            setTimeout(() => {
              this.$router.push('home')
            }, 1000)
          }
        })
        .catch((data) => {
          this.$message.error(data.message)
        })
    },
    // 校验文件
    beforeUpload (file) {
      return new Promise((resolve, reject) => {
        const fileTypeList = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf']
        if (fileTypeList.length > 0) {
          const index = fileTypeList.indexOf(file.type)
          if (index < 0) {
            this.$message.error('您不能上传该格式的文件')
            return reject(new Error('您不能上传该格式的文件'))
          }
        }
        const fileSize = file.size / 1024 / 1024
        const isFileSizeLegal = fileSize < 7
        if (!isFileSizeLegal) {
          this.$message.error('文件大小不能大于7MB')
          return reject(new Error('文件大小不能大于7MB'))
        }
        const formData = new FormData()
        formData.append('file', file)
        this.loading = true
        this.$upload('/minio-file/upload', formData).then((res) => {
          const data = res.data
          if (data.code === 200) {
            const index = {
              uid: this.genId(5),
              name: file.name,
              status: 'done',
              url: data.data.urlPath
            }
            this.fileList = [...this.fileList.filter(item => item.status === 'done'), index]
            if (this.fileList.length > this.fileNum) this.fileList.shift() // 移除超出的文件
            this.$message.success('上传成功')
          } else {
            this.$message.error(data.message)
          }
          this.loading = false
          this.callback()
        }).catch((r) => {
          console.error(r)
          this.$message.error('上传失败')
          this.loading = false
        })
        return resolve(true)
      })
    },
    handleUpload (e) {
    },
    // 防止请求404 自定义请求
    handleUploadImage () {
      console.debug('empty upload')
    },
    // 生成随机ID
    genId (length) {
      return Number(Math.random().toString().substr(3, length) + Date.now()).toString(36)
    },
    // 结束回调
    callback () {
      this.fileList = [...this.fileList.filter(item => item.status === 'done')]
      let callbackArray = [...this.fileList]
      // 返回精简的url格式
      callbackArray = this.fileList.filter(item => item.url).map(item => {
        return item.url
      })
      this.imageList = callbackArray
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;
}

.content {
  margin: 16px;
  background-color: #fff;
}

.headerBox {
  background-color: #fff;
  padding-top: 16px;
  .headerTitle {
    border-left: 3px solid #248AA3;
    padding-left: 8px;
    margin-left: 16px;
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 1.2em;
  }
}
.ant-divider {
  margin: 0 !important;
}
.ant-row {
  margin: 0 !important;
}
.intro {
  background: rgba(36, 138, 163, 0.13);
  color: #248AA3;
  padding: 13px;
  font-size: 1.2em;
  line-height: 2em;
  border-left: 3px solid #248AA3;
}
.box {
  margin-top: 20px;
  font-size: 1.2em;
  line-height: 2em;
  color: rgba(0, 0, 0, 0.45);
  .title {
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
