
<template>
  <div class="container">
    <div class="package-usage-title">
      <div class="package-title">订单效率</div>
    </div>
    <div class="package-usage-item">
      <div
        id="orderEfficiency"
        :style="{ zoom: '125%' }"
        class="platform-item"
      ></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as echarts from 'echarts'
export default {
  name: 'OrderEfficiency',
  components: {
  },
  props: {
    chartData: {
      type: Object,
      default: () => [],
    },
  },
  data () {
    return {

    }
  },
  computed: {
  },
  mounted () {
    setTimeout(() => {
      this.drawLine()
    }, 200)
  },
  methods: {
    drawLine () {
      // 基于准备好的dom，初始化echarts实例
      let orderEfficiency = this.$echarts.init(document.getElementById('orderEfficiency'))
      const option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          // '订单授权周期'
          data: ['订单交付周期'],
          bottom: 16
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '14%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          // data: ['2023-01', '2023-02', '2023-03', '2023-04', '2023-05', '2023-06', '2023-07', '2023-08', '2023-09', '2023-10', '2023-11'],
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '订单交付周期',
            type: 'line',
            stack: 'Total',
            // data: [120, 132, 101, 134, 90, 230, 210, 120, 132, 101, 134, 90,],
            tooltip: {
              valueFormatter: function (value) {
                return value + '天'
              }
            },
          },
          {
            name: '订单授权周期',
            type: 'line',
            stack: 'Total',
            // data: [220, 182, 191, 234, 290, 330, 310, 120, 132, 101, 134, 90,],
            tooltip: {
              valueFormatter: function (value) {
                return value + '天'
              }
            },
          }
        ]
      }
      option.xAxis.data = this.chartData.orderDeliverDayList.map(item=>{
        return item.months
      })
      option.series[0].data = this.chartData.orderDeliverDayList.map(item=>{
        return item.avgDeliveryDay
      })
      // option.series[1].data = this.chartData.orderDeliverDayList.map(item=>{
      //   return item.avgDeliveryDay
      // })
      // 绘制图表
      orderEfficiency.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  .package-usage-title {
    display: flex;
    flex-direction: row;
    color: #0b2850;
    font-size: 18px;
    font-weight: bold;
    .package-title {
      margin-bottom: 12px;
    }
    .package-title:last-child {
      margin-right: 0;
    }
  }
  .package-usage-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px rgba(112, 144, 176, 0.12);
    .platform-item {
      width: 100%;
      height: 400px;
    }
  }
}
</style>
