<template>
  <div
    :class="questionActive === questionIndex ? 'question-active' : 'question'"
  >
    <div
      :class="
        questionActive === questionIndex
          ? 'question-active-text'
          : 'question-text'
      "
    >
      <div
        :class="
          questionActive === questionIndex
            ? 'question-active-point'
            : 'question-point'
        "
      ></div>
      问题{{ questionIndex }}：{{ questionText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'MQuestion',
  props: {
    questionIndex: {
      type: Number,
      default: 0
    },
    questionText: {
      type: String,
      default: ''
    },
    questionActive: {
      type: Number,
      default: 1
    },
  },
  data () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.question {
  max-width: 280px;
  width: fit-content;
  padding: 10px 15px;
  background: #ffffff;
  margin-top: 20px;
  border-radius: 10px 10px 10px 0px;

  &-point {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #13a8bf;
    margin-right: 10px;
    display: inline-block;
  }
  &-text {
    display: inline-block;
    font-size: 15px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0em;

    color: #0b2850;
  }
}
.question-active {
  max-width: 350px;
  width: fit-content;
  padding: 20px 25px;
  background: rgba(255, 253, 222, 1);
  margin-top: 20px;
  border-radius: 10px 10px 10px 0px;
  &-point {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #13a8bf;
    margin-right: 10px;
    display: inline-block;
  }
  &-text {
    display: inline-block;
    font-size: 18px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0em;
    color: #0b2850;
  }
}
</style>
