<template>
  <div style="margin-top: 32px">
    <!-- 密码修改 -->
    <!-- <a-modal
    title="密码修改"
    :keyboard="false"
    :maskClosable="false"
    :closable="false"
    v-model="show"
    @cancel="cancelUpdatePassword"
    @ok="handleUpdatePassword"> -->
    <div class="my-flex my-item-jc-sb">
      <div style="
          font-family: SourceHanSansCN-Bold-Bold;
          font-size: 18px;
          color: #0b2850;
        ">
        用户信息
      </div>
      <div class="my-flex">
        <a-button shape="round" size="small" style="
            border-radius: 32px !important;
            color: #fff;
            background-color: #13a8bf;
            border: 1px solid #13a8bf;
            padding: 6px 16px;
            width: 60px;
            height: 28px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          " @click="handleUpdatePassword">保存</a-button>
        <a-popconfirm title="确定放弃编辑？" @confirm="cancelUpdatePassword" okText="确定" cancelText="取消">
          <a-button shape="round" size="small" style="
            border-radius: 32px !important;
            color: #13a8bf;
            border: 1px solid #13a8bf;
            padding: 6px 16px;
            width: 60px;
            height: 28px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-left: 8px;
          ">取消</a-button>
        </a-popconfirm>
      </div>
    </div>
    <a-form :form="form" style="margin-top: 31px;">
      <a-form-item label='旧密码' v-bind="formItemLayout" :labelCol="{span: 6}">
        <a-input type="password" autocomplete="false" placeholder="请输入旧密码" class="input1"
          v-decorator="['oldPassword', { rules: [{ required: true, message: '请输入旧密码' }, { validator: this.handleOldPassowrd }], validateTrigger: ['blur'] }]"></a-input>
      </a-form-item>
      <a-popover placement="rightTop" trigger="click" :visible="state.passwordLevelChecked">
        <template slot="content">
          <div :style="{ width: '240px' }">
            <div :class="['update-password', passwordLevelClass]">强度：<span>{{ passwordLevelName }}</span></div>
            <a-progress :percent="state.percent" :showInfo="false" :strokeColor="passwordLevelColor" />
            <div style="margin-top: 10px;">
              <span>请至少输入8位密码并包含数字、英文字母或特殊符号中的两种，区分大小写。</span>
            </div>
          </div>
        </template>
        <a-form-item label='新密码' v-bind="formItemLayout" :labelCol="{span: 6}">
          <a-input type="password" @click="handlePasswordInputClick" autocomplete="false" class="input1"
            @blur="checkInput()"
            placeholder="需要8位以上密码并包含数字、英文字母或特殊符号中的两种，区分大小写"
            v-decorator="['password', { rules: [{ required: true, message: '需要8位以上密码并包含数字、英文字母或特殊符号中的两种，区分大小写' }, { validator: this.handlePasswordLevel }], validateTrigger: ['change', 'blur'] }]" ></a-input>
        </a-form-item>
      </a-popover>
      <a-form-item label='再次确认' v-bind="formItemLayout" :labelCol="{span: 6}">
        <a-input type="password" autocomplete="false" placeholder="确认密码" class="input1"
          v-decorator="['password2', { rules: [{ required: true, message: '需要8位以上密码并包含数字、英文字母或特殊符号中的两种，区分大小写' }, { validator: this.handlePasswordCheck }], validateTrigger: ['change', 'blur'] }]"></a-input>
      </a-form-item>
    </a-form>
    <!-- </a-modal> -->
  </div>
</template>
<script>
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 }
}
const levelNames = {
  0: '低',
  1: '低',
  2: '中',
  3: '强'
}
const levelClass = {
  0: 'error',
  1: 'error',
  2: 'warning',
  3: 'success'
}
const levelColor = {
  0: '#ff0000',
  1: '#ff0000',
  2: '#ff7e05',
  3: '#52c41a'
}

export default {
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  props: {
    updatePasswordModelVisible: {
      default: false
    },
    user: {
      required: true
    }
  },
  data() {
    return {
      // form: null,
      formItemLayout,
      state: {
        passwordLevel: 0,
        passwordLevelChecked: false,
        percent: 10,
        progressColor: '#FF0000'
      },
      // oldPassword: '',
      // newPassword: '',
      validateStatus: '',
      help: ''
    }
  },
  computed: {
    show: {
      get: function () {
        return this.updatePasswordModelVisible
      },
      set: function () {
      }
    },
    passwordLevelClass() {
      return levelClass[this.state.passwordLevel]
    },
    passwordLevelName() {
      return levelNames[this.state.passwordLevel]
    },
    passwordLevelColor() {
      return levelColor[this.state.passwordLevel]
    }
  },
  methods: {
    checkInput(){
      this.state.passwordLevelChecked = false
    },
    isMobile() {
      return this.$store.state.setting.isMobile
    },
    cancelUpdatePassword() {
      this.state.passwordLevelChecked = false
      this.form.resetFields()
      this.$emit('cancel')
    },
    handleUpdatePassword() {
      this.form.validateFields((err, values) => {
        if (!err) {
          let newPassword = this.form.getFieldValue('password')
          this.$put('user/password', {
            password: this.$aesEncrypt.encrypt(newPassword),
            username: this.user.username
          }).then(() => {
            this.state.passwordLevelChecked = false
            this.$emit('success')
            this.form.resetFields()
          })
        }
      })
    },
    handlePasswordLevel(rule, value, callback) {
      let level = 0
      // 判断这个字符串中有没有数字
      if (/[0-9]/.test(value)) {
        level++
      }
      // 判断字符串中有没有字母
      if (/[a-zA-Z]/.test(value)) {
        level++
      }
      // 判断字符串中有没有特殊符号
      if (/[^0-9a-zA-Z_]/.test(value)) {
        level++
      }
      this.state.passwordLevel = level
      this.state.percent = level * 30
      if (level >= 2 && value.length >= 8) {
        if (level >= 3) {
          this.state.percent = 100
        }
        callback()
      } else {
        if (level === 0 || level === 1 || value.length < 8) {
          this.state.percent = 10
        }
        callback(new Error('密码强度不够，需要8位以上密码并包含数字、英文字母或特殊符号中的两种'))
      }
    },
    handlePasswordCheck(rule, value, callback) {
      let password = this.form.getFieldValue('password')
      if (value === undefined) {
        callback(new Error('请输入密码'))
      }
      if (value && password && value.trim() !== password.trim()) {
        callback(new Error('两次密码不一致'))
      }
      callback()
    },
    handlePasswordInputClick() {
      if (!this.isMobile()) {
        this.state.passwordLevelChecked = true
        return
      }
      this.state.passwordLevelChecked = false
    },
    handleOldPassowrd(rule, value, callback) {
      let password = this.form.getFieldValue('oldPassword')
      if (typeof password !== 'undefined' && password.trim().length) {
        this.$get('user/password/check', {
          password: this.$aesEncrypt.encrypt(password),
          username: this.user.username
        }).then((r) => {
          if (r.data) {
            callback()
          } else {
            callback(new Error('旧密码不正确'))
          }
        })
      } else {
        callback()
      }
    }
  }
}
</script>
<style lang="less" scoped>
.update-password {
  &.error {
    color: #ff0000;
  }

  &.warning {
    color: #ff7e05;
  }

  &.success {
    color: #52c41a;
  }
}

/deep/ .ant-form-item-label {
  margin-top: -30px;
  font-family: SourceHanSansCN-Regular-Regular;
  font-size: 12px;
  color: #6e7070 !important;
}

/deep/ .ant-col-18 {
  width: 100%;
}

.input1 {
  background: #f3f6ff;
  border: none;
  height: 40px;

  margin-bottom: 10px;
}
</style>
