<template>
  <!-- <a-drawer
    title="编辑资料"
    :maskClosable="false"
    width=650
    placement="right"
    :closable="false"
    @close="onClose"
    :visible="profileEditVisiable"
    style="height: calc(100% - 55px);overflow: auto;padding-bottom: 53px;"> -->
  <div>
    <div class="my-flex my-item-jc-sb">
      <div
        style="
          font-family: SourceHanSansCN-Bold-Bold;
          font-size: 18px;
          color: #0b2850;
        "
      >
        用户信息
      </div>
      <a-button
        shape="round"
        size="small"
        style="
          border-radius: 32px !important;
          color: #13a8bf;
          border: 1px solid #13a8bf;
          padding: 6px 20px;
          width: 96px;
          height: 32px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
        @click="changeMassege()"
        >修改信息</a-button
      >
    </div>
    <a-form :form="form" style="margin-top: 20px">
      <!-- <a-form-item label='企业名称' v-bind="formItemLayout" v-if="currentUser.userType == 1">
        <a-input
          v-decorator="[
          'enterpriseName',
          {rules: [
            { required: true, message: '请输入企业名称' },
            { max: 50, message: '长度不能超过50个字符'}
          ]}
        ]"/>
      </a-form-item> -->
      <a-form-item v-bind="formItemLayout">
        <span slot="label">昵称</span>
        <a-input
          class="input1"
          disabled
          v-decorator="[
            'nickname',
            {
              rules: [
                { required: true, message: '请输入昵称' },
                { max: 50, message: '长度不能超过50个字符' },
              ],
            },
          ]"
        />
      </a-form-item>
      <!-- <a-form-item label='对外简称' v-bind="formItemLayout">
        <a-input disabled
          v-decorator="[
          'abbreviation',
          {rules: [
            { max: 50, message: '长度不能超过50个字符'}
          ]}
        ]"/>
      </a-form-item> -->
      <a-form-item label="邮箱" v-bind="formItemLayout">
        <a-input
          disabled
          class="input1"
          v-decorator="[
            'email',
            {
              rules: [
                { type: 'email', message: '请输入正确的邮箱' },
                { max: 50, message: '长度不能超过50个字符' },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="手机" v-bind="formItemLayout">
        <a-input
          disabled
          class="input1"
          v-decorator="[
            'mobile',
            {
              rules: [
                {
                  pattern:
                    '^0?(13[0-9]|15[012356789]|17[013678]|18[0-9]|14[57])[0-9]{8}$',
                  message: '请输入正确的手机号',
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <!-- <a-form-item label='部门' v-bind="formItemLayout">
        <a-tree-select
          :allowClear="true"
          :dropdownStyle="{ maxHeight: '220px', overflow: 'auto' }"
          :treeData="deptTreeData"
          @change="onDeptChange"
          :value="userDept">
        </a-tree-select>
      </a-form-item> -->
      <a-form-item label="性别" v-bind="formItemLayout">
        <a-select
          @change="handleChange"
          disabled
          v-decorator="[
            'ssex',
            { rules: [{ required: true, message: '请选择性别' }] },
          ]"
        >
          <a-select-option value="0"> 男 </a-select-option>
          <a-select-option value="1"> 女 </a-select-option>
          <a-select-option value="2"> 保密 </a-select-option>
        </a-select>
        <!-- <a-radio-group
        v-decorator="[
          'ssex',
          { rules: [{ required: true, message: '请选择性别' }] },
        ]"
      >
        <a-radio value="0">男</a-radio>
        <a-radio value="1">女</a-radio>
        <a-radio value="2">保密</a-radio>
      </a-radio-group> -->
      </a-form-item>
      <a-form-item label="描述" v-bind="formItemLayout">
        <a-textarea
          disabled
          class="input1"
          style="height: 54px"
          :rows="4"
          v-decorator="[
            'description',
            { rules: [{ max: 100, message: '长度不能超过100个字符' }] },
          ]"
        >
        </a-textarea>
      </a-form-item>
    </a-form>
  </div>
  <!-- <div class="drawer-bootom-button">
      <a-popconfirm title="确定放弃编辑？" @confirm="onClose" okText="确定" cancelText="取消">
        <a-button style="margin-right: .8rem">取消</a-button>
      </a-popconfirm>
      <a-button @click="handleSubmit" type="primary" :loading="loading">提交</a-button>
    </div> -->
  <!-- </a-drawer> -->
</template>
<script>
import { mapState, mapMutations } from "vuex";

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 },
};
export default {
  props: {
    profileEditVisiable: {
      default: false,
    },
  },
  data() {
    return {
      formItemLayout,
      form: this.$form.createForm(this),
      deptTreeData: [],
      userDept: [],
      userId: "",
      roleId: "",
      status: "",
      username: "",
      loading: false,
    };
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.account.user,
    }),
  },
  methods: {
    changeMassege(){
     this.$emit('changeMassege')
    },
    ...mapMutations({
      setUser: "account/setUser",
    }),
    onClose() {
      this.loading = false;
      this.form.resetFields();
      this.$emit("close");
    },
    setFormValues({ ...user }) {
      this.userId = user.userId;
      this.roleId = this.currentUser.roleId;
      let fields = ["email", "ssex", "description", "mobile", "nickname"];
      Object.keys(user).forEach((key) => {
        if (fields.indexOf(key) !== -1) {
          this.form.getFieldDecorator(key);
          let obj = {};
          obj[key] = user[key];
          this.form.setFieldsValue(obj);
        }
      });
      if (user.deptId) {
        this.userDept = [user.deptId];
      }
      this.status = user.status;
      this.username = user.username;
    },
    onDeptChange(value) {
      this.userDept = value;
    },
    handleChange(){

    },
    handleSubmit() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          let user = this.form.getFieldsValue();
          user.userId = this.userId;
          // user.deptId = this.userDept
          user.roleId = this.roleId;
          user.status = this.status;
          user.username = this.username;
          this.$put("user/profile", {
            ...user,
          })
            .then((r) => {
              this.loading = false;
              this.$emit("success");
              // 更新其state
              this.$get(`user/${user.username}`).then((r) => {
                this.setUser(r.data);
              });
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },
  },
  watch: {
    // profileEditVisiable () {
    //   if (this.profileEditVisiable) {
    //     this.$get('dept').then((r) => {
    //       this.deptTreeData = r.data.rows.children
    //     })
    //   }
    // }
  },
};
</script>
<style scoped lang="less">
/deep/ .ant-form-item-label {
  margin-top: -20px;
  font-family: SourceHanSansCN-Regular-Regular;
  font-size: 12px;
  color: #6e7070 !important;
}

/deep/ .ant-col-18 {
  width: 100%;
}

.input1 {
  background: #f3f6ff;
  border: none;
  height: 40px;
  margin-bottom: 10px;
}

/deep/.ant-select-selection {
  background: #f3f6ff;
  border: none;
  height: 40px;
}
</style>
