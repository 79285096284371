<!--
费用明细
-->
<template>
  <div class="container">
    <div class="content">
      <div class="headerBox" style="padding-bottom: 8px">
        <div class="headerTitle">已选调查项</div>
      </div>
      <a-divider />
      <div style="width: 100%;padding: 16px;">
        <a-space direction="vertical"  style="width: 100%;">
          <div class="my-flex-column my-item-jc-sb">
            <h3>主套餐核查项</h3>
            <div class="my-flex my-item-jc-sb my-padding-10-0" v-for="(item, packageItemUuid) in costInfo.mainPackageCheckItemList" :key="packageItemUuid">
              <div>{{ item.itemName }}</div>
              <div>{{ item.countryName || '中国' }} X{{ item.quantity || 1 }}</div>
            </div>
            <h3>搭配套餐核查项</h3>
            <div class="my-flex my-item-jc-sb my-padding-10-0" v-for="(item, packageItemUuid) in costInfo.collocationPackageCheckItemList" :key="packageItemUuid">
              <div>{{ item.itemName }}</div>
              <div>{{ item.countryName || '中国' }} X{{ item.quantity || 1 }}</div>
            </div>
          </div>
        </a-space>
      </div>
      <div class="headerBox" style="padding-bottom: 8px">
        <div class="headerTitle">最快交付</div>
      </div>
      <a-divider />
      <div class="my-primary-padding my-width-100">最快交付：{{ costInfo.fastestDeliveryDay || '0' }} 个工作日，授权后的第二个工作日起算</div>
      <div class="headerBox" style="padding-bottom: 8px">
        <div class="headerTitle">支付</div>
      </div>
      <a-divider />
      <div class="my-primary-padding my-width-100">
        <a-space direction="vertical" :size="20" class="my-width-100">
          <div class="my-flex my-item-jc-sb">
            <div>支付方式</div>
            <a-radio-group v-model="form.paymentMethod" :defaultValue="0">
              <a-radio :value="0">微信</a-radio>
              <a-radio :value="1">支付宝</a-radio>
              <a-radio :value="2">钱包支付</a-radio>
            </a-radio-group>
          </div>
          <div class="my-flex my-item-jc-sb">
            <div>应付金额</div>
            <div>{{ costInfo.totalPayment || '--' }}元</div>
          </div>
          <div class="my-flex my-item-jc-sb">
            <div>平台会员抵扣</div>
            <div>本次订单已为您节省{{ costInfo.totalDiscount || '0' }}元</div>
          </div>
          <a-checkbox v-model="checkFlag">我已阅读并同意<span class="my-primary-color" @click="show">《背调服务协议》</span></a-checkbox>
          <div class="my-flex my-item-jc-sb">
            <a-button type="primary" @click="handlePay">确认支付</a-button>
          </div>
          <div class="my-flex my-item-jc-sb">
            <div>如需其他额外背调资料收集，请联系<span class="my-primary-color">{{ $store.state.setting.info.mobile }}</span></div>
          </div>
        </a-space>
      </div>
    </div>
    <TextModal ref="textRef" />
  </div>
</template>
<script>
import TextModal from '../../startBack/components/TextModal.vue'
const formItemLayout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 7 }
}
export default {
  name: 'CostDetail',
  components: {
    TextModal
  },
  props: {
    costInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      formItemLayout,
      form: {
        paymentMethod: 0
      },
      itemList: [],
      checkFlag: false
    }
  },
  created () {},
  mounted () {
  },
  methods: {
    tabChange (e) {
    },
    add () {
    },
    del (index) {
    },
    show () {
      this.$get('web/platform-param/selectByKey/serviceAgreement')
        .then((r) => {
          let data = r.data.data
          this.$refs.textRef.text = data.paramDetail
          this.$refs.textRef.title = data.paramName
          this.$refs.textRef.visiable = true
        })
        .catch(() => {})
    },
    handlePay () {
      if (!this.checkFlag) {
        this.$message.warning('请勾选同意背调服务协议')
      } else {
        this.$emit('pay')
      }
    },
    cancel () {
      this.$router.push('/startBack?flag=1')
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;
}

.content {
  width: 100%;
  margin: 16px;
  background-color: #fff;
}

.headerBox {
  background-color: #fff;
  padding-top: 16px;
  .headerTitle {
    border-left: 3px solid #248AA3;
    padding-left: 8px;
    margin-left: 16px;
    margin-bottom: 16px;
    font-weight: bold;
  }
}
.ant-divider {
  margin: 0 !important;
}
.ant-row {
  margin: 0 !important;
}
// .ant-col {
//   padding: 0 !important;
// }
.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.box1 {
  .subTitle1 {
    font-size: 12px;
    color: #248AA3;
  }
  .subTitle2 {
    font-size: 16px;
    color: #248AA3;
  }
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  background: #F7F8FA;
  border: 1px solid #248AA3;
}
.tableHeader {
  width: 70%;
  height: 32px;
  background: #FAFAFA;
}
.tableContent {
  width: 70%;
  background-color: #fff;
}
.addBox {
  width: 100%;
  text-align: center;
  border: 1px dashed #D9D9D9;
  border-radius: 2px;
  margin: 10px 0px;
}
.ant-form-item-label /deep/ {
  margin-top: 10px !important;
}
</style>
