<template>
  <div class="answer">
    <div class="answer-content">
      <div class="answer-content-text">{{ answerText }}</div>
      <img
        @click="recPlay"
        src="../../assets/img/ansaudio.svg"
        style="display: inline-block; height: 15px"
      />
      <div class="answer-undo"><a-icon type="undo"></a-icon>重新回答</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MQuestion',
  props: {
    answerIndex: {
      type: Number,
      default: 0
    },
    answerText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {

    }
  },
  methods: {
    recPlay () {
      //本地播放录音试听，可以直接用URL把blob转换成本地播放地址，用audio进行播放
      var localUrl = URL.createObjectURL(this.recBlob)
      var audio = document.createElement("audio")
      audio.controls = true
      document.body.appendChild(audio)
      audio.src = localUrl
      audio.play() //这样就能播放了

      //注意不用了时需要revokeObjectURL，否则霸占内存
      setTimeout(function () { URL.revokeObjectURL(audio.src) }, 5000)
    }
  }
}
</script>

<style scoped lang="less">
.answer-undo {
  position: absolute;
  bottom: -20px;
  right: 0;
  color: #6e7070;
  font-size: 15px;
  font-weight: normal;
  line-height: 15px;
}
.answer {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 30px;
}
.answer-content {
  max-width: 280px;
  width: fit-content;
  padding: 10px 15px;
  background: #13a8bf;
  margin-top: 20px;
  border-radius: 10px 10px 0px 10px;

  &-point {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #13a8bf;
    margin-right: 10px;
    display: inline-block;
  }
  &-text {
    display: inline-block;
    font-size: 15px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: 0em;

    color: white;
  }
}
</style>
