<!--
首页
-->
<template>
  <div class="container">
    <a-spin :spinning="loading">
      <BasicStatistics :chartData="statisData" />
      <OrderStatistics :chartData="statisData" />
      <RiskItem :chartData="statisData" />
      <a-row>
        <a-col :span="12">
          <PostOrder :chartData="statisData" />
        </a-col>
        <a-col :span="12">
          <BackTonePackage :chartData="statisData" />
        </a-col>
      </a-row>
    </a-spin>
    <AuthModal ref="authRef" />
  </div>
</template>
<script>
import AuthModal from './components/AuthModal.vue'
import BackTonePackage from './components/BackTonePackage.vue'
import BasicStatistics from './components/BasicStatistics.vue'
import OrderStatistics from './components/OrderStatistics.vue'
import PostOrder from './components/PostOrder.vue'
import RiskItem from './components/RiskItem.vue'
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 }
}
export default {
  name: 'Home',
  components: {
    BasicStatistics,
    OrderStatistics,
    RiskItem,
    PostOrder,
    BackTonePackage,
    AuthModal
  },
  data () {
    return {
      loading: false,
      formItemLayout,
      statisData: {},
      userInfo: {}
    }
  },
  created () {},
  mounted () {
    this.getStatistics()
    this.getAuthStatus()
    this.getMessageNum()
  },
  methods: {
    getStatistics () {
      this.loading = true
      this.$get('web/statistics/enterpriseStatistics')
        .then((r) => {
          this.loading = false
          this.statisData = r.data.data
          this.$forceUpdate()
        })
        .catch(() => {})
    },
    getAuthStatus () {
      this.$get('web/user/selectUserBasicInfo')
        .then((r) => {
          let data = r.data.data
          this.userInfo = data
          if (data.authStatus !== 2) {
            this.$refs.authRef.userInfo = this.userInfo
            this.$refs.authRef.authVisible = true
          }
        })
        .catch(() => {})
    },
    getMessageNum () {
      this.$get('/web/message-notify/selectUnreadMessageNum')
        .then((r) => {
          let messageNum = r.data.data
          this.$db.save('MESSAGENUM', messageNum)
        })
        .catch(() => {})
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;
}

.content {
  margin: 16px;
  background-color: #fff;
}

.headerBox {
  background-color: #fff;
  padding-top: 16px;
  .headerTitle {
    border-left: 3px solid #248AA3;
    padding-left: 8px;
    margin-left: 16px;
    margin-bottom: 16px;
    font-weight: bold;
  }
}
.ant-divider {
  margin: 0px !important;
}
.ant-row {
  margin: 0 !important;
}
.authModal {
  color: #8C8C8C;
  // padding-bottom: 40px;
  span {
    color: #FF4D4F;
  }
}

</style>
