import moment from 'moment'
import 'moment/locale/zh-cn'

export default {
  data () {
    return {
      BankCreditAppendix:
        `银行信用报告获取方式:<br/>
        一、线上获取:(24小时内可收到附件，收到附件后点击授权链接上传附件)<br/>
        1、进入中国人民银行征信中心官方网站(https://dashboard.checkey.net)注册登录后提交个人信用报告申请:<br/>
        2、商业银行手机APP查询:开通中国工商银行、中国农业银行、中国银行、中国建设银行、中国邮政储蓄银行、招商银行等手机银行，下载登录后可以申请。<br/>
        二、线下获取:人民银行分支机构查询点提供柜台查询，查询打印后上传照片或扫描件。`
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    onPassportOcr (data, index1, index2) {
      const item = this.candidateFillIns[index1].candidateNecessaryInfos.find(item => item.key === 'passport_no')
      if (item) {
        item.candidateFillIn = data.passportNumber
        this.$forceUpdate()
      }
    },
    onResumeOcr (data, index1, index2) {
      console.log('on resume ocr', data, index1, index2, this.candidateFillIns[index1].candidateNecessaryInfos)
      const basicInfo = data.parsing_result?.basic_info
      const educationExperience = data.parsing_result?.education_experience
      const workExperience = data.parsing_result?.work_experience

      this.candidateFillIns[index1].candidateNecessaryInfos.forEach(item => {
        if (item.key === 'name') {
          item.candidateFillIn = basicInfo?.name
        }
        if (item.key === 'sex') {
          const res = item.config.options.find(item => item.value === basicInfo?.gender)
          if (res) {
            item.candidateFillIn = res.value
          }
        }
        if (item.key === 'birthday') {
          item.candidateFillIn = moment(basicInfo?.date_of_birth).format('YYYY/MM/DD')
        }
        if (item.key === 'ancestral_home') {
          const res = item.config.options.find(item => item.value === basicInfo?.current_location)
          if (res) {
            item.candidateFillIn = res.value
          }
        }
        if (item.key === 'marrage') {
          const res = item.config.options.find(item => item.value === basicInfo?.marrage)
          if (res) {
            item.candidateFillIn = res.value
          }
        }
        if (item.key === 'address') {
          item.candidateFillIn = basicInfo?.current_location_norm
        }
        if (item.key === 'school_name') {
          item.candidateFillIn = educationExperience[0]?.school_name
        }
        if (item.key === 'learn_style') {
          const res = item.config.options.find(item => item.value === educationExperience[0]?.study_model)
          if (res) {
            item.candidateFillIn = res.value
          }
        }
        if (item.key === 'academic_degree') {
          item.candidateFillIn = basicInfo?.degree
        }
        if (item.key === 'study_time') {
          const startYear = educationExperience?.[0]?.start_time_year
          const startMonth = educationExperience?.[0]?.start_time_month
          const endYear = educationExperience?.[0]?.end_time_year
          const endMonth = educationExperience?.[0]?.end_time_month
          let startTime = ''
          let endTime = ''
          if (startYear) {
            startTime = `${startYear}/${startMonth ?? '01'}`
          }
          if (endYear) {
            endTime = `${endYear}/${endMonth ?? '01'}`
          }
          if (startYear && endYear) {
            item.candidateFillIn = `${startTime}-${endTime}`
          }
        }
        if (item.key === 'enterprise_name') {
          item.candidateFillIn = workExperience?.[0]?.company_name
        }
        if (item.key === 'entry_date') {
          const year = workExperience?.[0]?.start_time_year
          const month = workExperience?.[0]?.start_time_month
          const day = workExperience?.[0]?.start_time_day
          if (year) {
            item.candidateFillIn = `${year}/${month ?? '01'}/${day ?? '01'}`
          }
        }
        if (item.key === 'leave_date') {
          const year = workExperience?.[0]?.end_time_year
          const month = workExperience?.[0]?.end_time_month
          const day = workExperience?.[0]?.end_time_day
          if (year) {
            item.candidateFillIn = `${year}/${month ?? '01'}/${day ?? '01'}`
          }
        }
        if (item.key === 'position') {
          item.candidateFillIn = workExperience?.[0]?.job_title
        }
      })

      this.$forceUpdate()

      setTimeout(() => {
        this.$forceUpdate()
      }, 1000)
    },
    isResumeOcr (info) {
      if (info.key === 'resume_copy') {
        return true
      }

      return false
    },
    isPassportOcr (info) {
      if (info.key === 'passport_attachment') {
        return true
      }

      return false
    }
  }
}
