export const protocol = {
  title: '实名认证服务协议',
  text: `欢迎使用实名认证服务！ 为使用实名认证服务（以下称“本服务”），更好地保障你的权益，你应当阅读并遵守《实名认证服务协议》（以下称“本协议”），同时遵守《7氪背调用户协议》 《7氪背调隐私政策》 《人脸信息处理规则》及平台不时公布的其它各项制度规范。 平台已经以字体加粗或其他合理方式提示用户重点阅读协议中相关免除或限制责任条款，用户和平台均认可其合法性及有效性。如你不同意本协议，或拒绝提供个人信息进行实名认证，这将导致我们无法为你提供完整的产品和服务，你也可以选择停止使用。如你自主选择同意或使用本服务，则视为你已充分理解本协议，并同意作为本协议的一方当事人接受本协议以及其他与本服务相关的协议和规则的约束。<br />
  1 定义 <br />
  1.1 平台：本协议所称"平台"是指宁波七氪信息科技有限公司及其关联公司（以下简称“公司”）拥有或实际运营的域名为"checkey.net"的网站以及系列版本客户端，以下合称“平台”。 <br />
  1.2 实名认证服务：是指由平台或平台指定的第三方机构提供的，通过人脸信息比对，核验用户身份，并将认证结果及认证相关信息反馈至向你提供产品或服务的主体。 <br />
  2 用户信息处理与用途 <br />
  2.1 为对申请实名认证的账号进行审核和安全验证，你同意使用公司提供的实名认证服务，即公司通过企业营业执照、身份证件信息验证、人脸信息比对核验你的身份，以确保统一服务平台体系账号实际使用主体和该账号实名认证主体为同一主体。 <br />
  2.2 为提升实名认证服务的准确性和安全性，你同意授权公司采集你的公司单位信息、身份信息、视频图像信息、面部特征信息并做出必要处理。你理解并同意公司可以将你在使用本服务时，向公司提供的营业执照信息(例如：名称、信用代码)、身份信息（例如：姓名、身份证号）和人脸信息，在法律法规允许或政府机关授权机构存储的信息进行比对核验。关于人脸信息的收集、使用、存储、传输与保护，我们将按照法律法规的规定，遵循《人脸信息处理规则》保护你的个人信息及隐私安全。<br /> 
  2.3 你承诺提供的信息合法、有效、真实、准确并完整，不采取任何违法违规、不正当或欺骗手段使用实名认证服务。 <br />
  2.4 公司将严格按照法律法规保护你的个人信息或所在单位信息。 <br />
  3 用户使用须知 <br />
  3.1 我们努力在现有技术能力基础上保障实名认证服务有效、稳定运行，但是，由于技术的限制，以及采集人脸信息过程中容易受到环境光线、表情、妆容等不特定因素的影响，公司暂时无法保证每次实名认证都能成功。 <br />
  3.2 如果你发现实名认证不成功，你可重新发起认证流程，或通过“页面预留电话或邮箱”联系我们。 <br />
  3.3 为保护你或他人的合法权益，公司有权在认为可能存在风险时暂停向你提供实名认证相关服务。 <br />
  3.4 为保障你的账号及财产安全，在你使用部分平台功能及第三方服务时，可能需满足平台账号与第三方账号实名信息一致的要求。 <br />
  4 附则 <br />
  4.1 为给你提供更好的服务或因国家法律法规、政策调整、技术条件、产品功能等变化需要，公司会适时对本协议进行修订，平台将不定期对本协议进行修订，并根据相关法律法规进行公示。如你对修订后的内容存有异议的，请立即停止使用本服务。若你知晓并继续使用平台及相关服务，即视为你认可并接受修订后的本服务协议内容。 <br />
  4.2 用户应遵守国家法律法规及相关部门规章制度等规范性文件。对任何涉嫌违反国家法律法规、行政法规及相关部门规范的行为，本协议已有约定的适用本协议，本协议尚未涉及的，平台有权依据各项平台制度规范酌情处理，但不免除用户应承担的法律责任。用户在平台的全部行为，都应同时遵守与平台及其关联公司签订的各项协议及规则。 <br />
  4.3 本协议的签订地为中华人民共和国宁波市鄞州区。若你与公司发生任何争议，双方应尽量友好协商解决，如协商不成，你同意应将争议提交至本协议签订地的人民法院诉讼解决。<br /> 
  4.4 本协议中的标题仅为方便阅读而设，并不影响本协议中任何规定的含义或解释。`
}
