<!--
富文本内容
-->
<template>
  <a-modal
    :title="title"
    :visible="visiable"
    :width="950"
    @cancel="onClose"
  >
    <YtQuillEditorPreview :value="text" />
    <template slot="footer">
      <a-button @click="onClose">关闭</a-button>
    </template>
  </a-modal>
</template>
<script>
import YtQuillEditorPreview from '@/components/YtQuillEditor/YtQuillEditorPreview.vue'
export default {
  name: 'TextModal',
  components: {
    YtQuillEditorPreview
  },
  data () {
    return {
      loading: false,
      title: '',
      text: '',
      visiable: false
    }
  },
  created () {},
  methods: {
    onClose () {
      this.visiable = false
    }
  }
}
</script>
<style scoped>
</style>
