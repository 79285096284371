<!--
订单设置
-->
<template>
  <div class="container">
    <div class="content">
      <div class="headerBox" style="padding-bottom: 8px">
        <div class="headerTitle">订单设置</div>
      </div>
      <a-divider />
      <div class="my-primary-padding my-flex-column">
        <a-form :form="form" layout='vertical' hideRequiredMark>
          <a-form-item label="允许背调专家直接联系候选人">
            <a-radio-group :disabled="freeze" v-model="form.contactCandidate">
              <a-radio :value="true">可以</a-radio>
              <a-radio :value="false">不可以</a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="授权候选人获得背调报告">
            <h5 class="my-gray-color">(注: 每次授权可获checkey雇主之星，雇主之星用于免费查看平台信托的候选人报告，或兑换背调现金)</h5>
            <a-radio-group :disabled="freeze" v-model="form.authorizeCandidateView">
              <a-radio :value="true">是</a-radio>
              <a-radio :value="false">否</a-radio>
            </a-radio-group>
          </a-form-item>
          <div class="my-margin-10-0">订单联系人<span>（请选择该订单背调过程中接收异常反馈、确认解决方案、接收报告的负责人）</span></div>
          <a-form-item>
            <div class="tableHeader">
              <a-row :gutter="20">
                <a-col :span="8">姓名</a-col>
                <a-col :span="12">邮箱</a-col>
              </a-row>
            </div>
            <div class="tableContent">
              <a-row :gutter="20">
                <a-col :span="8">
                  <a-input :disabled="freeze" v-model="form.receiveUser" placeholder="请输入内容" style="width: 70%;"/>
                </a-col>
                <a-col :span="12">
                  <a-input :disabled="freeze" v-model="form.receiveEmail" placeholder="请输入内容" style="width: 70%;"/>
                </a-col>
              </a-row>
            </div>
          </a-form-item>
          <div class="my-margin-10-0">报告同步发送<span>（报告生成或更新时，同步抄送的邮箱地址）</span></div>
          <a-form-item>
            <div class="tableHeader">
              <a-row :gutter="20">
                <a-col :span="2">#</a-col>
                <a-col :span="8">姓名</a-col>
                <a-col :span="10">邮箱</a-col>
                <a-col :span="4">操作</a-col>
              </a-row>
            </div>
            <div class="tableContent">
              <a-row :gutter="20" class="my-flex my-flex-column">
                <div v-for="(item, index) in form.otherNotifyUser" :key="index" class="my-margin-t-5">
                  <a-col :span="2">{{ index + 1 }}</a-col>
                  <a-col :span="8">
                    <a-input :disabled="freeze" v-model="item.name" placeholder="请输入内容" style="width: 70%;"/>
                  </a-col>
                  <a-col :span="12">
                    <a-input :disabled="freeze" v-model="item.email" placeholder="请输入内容" style="width: 70%;"/>
                  </a-col>
                  <a-col :span="2">
                    <a-space :size="20">
                      <a @click="del(index)">删除</a>
                    </a-space>
                  </a-col>
                </div>
                <div class="addBox" @click="add">
                  + 添加
                </div>
              </a-row>
            </div>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
const formItemLayout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 7 }
}
export default {
  name: 'OrderSetting',
  components: {
  },
  props: {
    freeze: {
      type: Boolean,
      default: false
    },
    collocationBackTonePackageUuid: {
      type: String,
      default: ''
    },
    packageType: {
      type: Number,
      default: 0
    },
    flag: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      formItemLayout,
      form: {
        contactCandidate: true,
        authorizeCandidateView: true,
        receiveUser: '',
        receiveEmail: '',
        otherNotifyUser: []
      }
    }
  },
  created () {},
  computed: {
    ...mapState({
      user: state => state.account.user
    })
  },
  watch: {
    form: {
      deep: true,
      handler (val) {
        this.$emit('change')
      }
    }
  },
  mounted () {
    this.form.receiveUser = this.user.mobile
    this.form.receiveEmail = this.user.email
  },
  methods: {
    tabChange (e) {
    },
    add () {
      this.form.otherNotifyUser.push(
        { name: '', email: '' }
      )
    },
    del (index) {
      this.form.otherNotifyUser.splice(index, 1)
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;
}

.content {
  margin: 16px;
  background-color: #fff;
}

.headerBox {
  background-color: #fff;
  padding-top: 16px;
  .headerTitle {
    border-left: 3px solid #248AA3;
    padding-left: 8px;
    margin-left: 16px;
    margin-bottom: 16px;
    font-weight: bold;
  }
}
.ant-divider {
  margin: 12px 0 !important;
}
.ant-row {
  margin: 0 !important;
}
// .ant-col {
//   padding: 0 !important;
// }
.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.box1 {
  .subTitle1 {
    font-size: 12px;
    color: #248AA3;
  }
  .subTitle2 {
    font-size: 16px;
    color: #248AA3;
  }
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  background: #F7F8FA;
  border: 1px solid #248AA3;
}
.tableHeader {
  width: 70%;
  height: 32px;
  background: #FAFAFA;
  margin: 5px 0;
}
.tableContent {
  width: 70%;
  background-color: #fff;
}
.addBox {
  width: 100%;
  min-width: 100px;
  text-align: center;
  border: 1px dashed #D9D9D9;
  border-radius: 2px;
  margin: 10px 0px;
}
.ant-form-item-label /deep/ {
  margin-top: 10px !important;
}
</style>
