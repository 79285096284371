<!--
确认订单
-->
<template>
  <div class="container">
    <a-spin :spinning="loading">
      <a-row :gutter="20" >
        <a-col :span="16">
          <!-- <PersonList ref="personList" @addInfo="addInfo" /> -->
          <AddInfo ref="addInfo" :index="personIndex" @change="infoChange" v-if="showAddInfo"/>
          <ItemList :costInfo="costInfo" />
          <AmongOptions :status="costInfo.continousBackToneStatus" :id="costInfo.id" ref="amongOptions" ></AmongOptions>
          <OrderSetting :freeze="true" ref="orderSetting" flag="among" @change="checkOrderInfo" :collocationBackTonePackageUuid="collocationBackTonePackageUuid" :packageType="form.packageType" />
        </a-col>
        <a-col :span="7">
        </a-col>
      </a-row>
      <div class="footer-button">
        <a-button class="btn" type="primary" @click="handleEdit">确认编辑</a-button>
        <a-button class="btn" type="danger" @click="handleCancel">{{costInfo.continousBackToneStatus ? '取消连续背调' : '开启连续背调'}}</a-button>
      </div>
    </a-spin>
  </div>

</template>

<script>
import PersonList from './components/PersonList.vue'
import AddInfo from './components/AddInfo.vue'
import OrderSetting from './components/OrderSetting.vue'
import CostDetail from './components/CostDetail.vue'
import ItemList from './components/ItemList.vue'
import AmongOptions from './components/AmongOptions.vue'
import RedisTerminalVue from '../../monitor/RedisTerminal.vue'
export default {
  name: 'AmongPayEmail',
  components: {
    PersonList,
    AddInfo,
    OrderSetting,
    ItemList,
    AmongOptions
  },
  data () {
    return {
      loading: false,
      form: {
        packageType: 0,
        candidateInfoList: []
      },
      personIndex: 0,
      mainBackTonePackageUuid: '', // 主套餐uuid
      collocationBackTonePackageUuid: '', // 搭配套餐uuid
      imgVisible: false,
      imgUrl: '', // 微信支付二维码
      totalAmount: 0,
      costInfo: {}, // 订单信息
      orderStatus: 0, // 订单状态,
      orderNo: '', // 订单编号
      orderUuid: '', // 订单uuid
      payLoading: false,
      statusEvent: null, // 订单状态监控定时任务
      showAddInfo: false
    }
  },
  created () {
  },
  computed: {},
  mounted () {
    console.log('among pay')
    if (this.statusEvent) {
      clearInterval(this.statusEvent)
    }
    if (this.$route.query.mainOrderUuid) {
      this.mainOrderUuid = this.$route.query.mainOrderUuid
      this.checkOrderInfo(1)
    }
    this.form = this.$db.get('orderInfo')
    console.log('info', this.$db.get('orderInfo'))
  },
  beforeDestroy () {
    if (this.statusEvent) {
      clearInterval(this.statusEvent)
    }
  },
  methods: {
    handleEdit () {
      this.$refs.amongOptions.edit()
    },
    handleCancel () {
      this.loading = RedisTerminalVue
      this.$post('/web/back-tone-order/upOrCloseMiddleEmployee/' + this.costInfo.uuid)
        .then((res) => {
          const data = res.data
          if (data.code === 200) {
            this.$message.success('操作成功')
            setTimeout(() => {
              this.checkOrderInfo(1)
            }, 1000)
          } else {
            this.$message.error(data.message)
            this.loading = false
          }
        })
    },
    tabChange (e) {
    },
    // 确定订单信息
    checkOrderInfo (value) {
      if (value) {
        this.loading = true
      }
      const data = {}
      data.collocationPackageUuid = this.collocationBackTonePackageUuid
      data.mainPackageUuid = this.mainBackTonePackageUuid

      this.$get('web/back-tone-order/selectBackToneOrderDetails/' + this.mainOrderUuid)
        .then((r) => {
          this.loading = false
          console.log(r.data.data)
          if (value) {
            this.$refs.orderSetting.form = r.data.data
            this.mainBackTonePackageUuid = r.data.data.uuid
          }
          this.costInfo = r.data.data
          // this.$refs.personList.dataSource = this.form.candidateInfoList
        })
        .catch((data) => {
          this.$message.error(data.message)
        })
    },

    // 定时请求获取订单状态
    statusInterval (orderNo) {
      if (this.statusEvent) {
        clearInterval(this.statusEvent)
      }
      this.statusEvent = setInterval(() => {
        this.checkOrderStauts(orderNo)
      }, 3000)
    },
    // 根据订单号查询订单状态
    checkOrderStauts (orderNo) {
      this.$post('web/back-tone-order/selectOrderStatusByOrderNO/' + orderNo, {
      })
        .then((r) => {
          this.orderStatus = r.data.data
          if (this.orderStatus !== 0) {
            this.$message.success('支付成功')
            this.payLoading = false
            clearInterval(this.statusEvent)
            this.$router.push('/order/all')
          }
        })
        .catch((data) => {
          this.$message.error(data.message)
        })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  position: relative;
  width: 100%;
}
.payBox {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 5px;
}
.money {
  font-size: 24px;
  margin: 0px 10px;
  color: #248AA3;
}

.footer-button {
  position: absolute;
  display: flex;
  bottom: 20px;
  right: 20px;
  width: 400px;
  height: 80px;
  background: white;
  justify-content: space-around;
  align-items: center;
  .btn {
    width: 120px;
    height: 40px;
  }
}
</style>
