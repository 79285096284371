<template>
    <a-modal v-model="picVisible" title="图片预览" :footer="null">
      <a-carousel arrows>
        <div
          slot="prevArrow"
          slot-scope="props"
          class="custom-slick-arrow"
          style="left: 10px;zIndex: 1"
        >
          <a-icon type="left-circle" theme="twoTone" :style="{ fontSize: '32px' }" />
        </div>
        <div slot="nextArrow" slot-scope="props" class="custom-slick-arrow" style="right: 10px">
          <a-icon type="right-circle" theme="twoTone" :style="{ fontSize: '32px' }" />
        </div>
        <div v-for="(item, index) in picList" :key="index">
          <div>
            <img :src="item" width="100%"/>
          </div>
        </div>
      </a-carousel>
    </a-modal>
</template>
<script>
export default {
  name: 'Carousel',
  props: {
    // 初始化列表
    // picList: {
    //   type: Array,
    //   required: false,
    //   default: () => ([])
    // }
  },
  data () {
    return {
      picVisible: false,
      picList: []
    }
  },
  created () {},
  methods: {
  }
}
</script>
<style scoped>
/* For demo */
.ant-carousel >>> .slick-slide {
  text-align: center;
  /* height: 500px; */
  /* line-height: 500px; */
  /* background: #364d79; */
  overflow: hidden;
}

.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
</style>
