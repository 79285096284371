<template>
  <div class="for-left-inner-2">
    <img :src="img" alt="">
    <div class="text-wrapper">
      <div class="text-inner-1">{{text1}}</div>
      <div class="text-inner-2">{{text2}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeftInner2',
  props: {
    img: {
      type: String,
      default: ''
    },
    text1: {
      type: String,
      default: ''
    },
    text2: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="less" scoped>
.for-left-inner-2 {
  display: flex;
  align-items: center;
  margin-top: 32px;
  img {
    width: 48px;
    height: 48px;
    margin-right: 24px;
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .text-inner-1 {
      font-size: 18px;
      font-weight: bold;
      line-height: 18px;
      letter-spacing: 0px;

      /* logo绿 */
      color: #13A8BF;
    }

    .text-inner-2 {
      margin-top: 12px;
      font-size: 14px;
      font-weight: normal;
      line-height: 14px;
      letter-spacing: 0px;

      /* 文字1 */
      color: #0B2850;
      }
  }
}
</style>
