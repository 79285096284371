<template>
  <div class="complete-page">
    <div class="logo-box">
      <img src="../../assets/img/new-logo1.png" class="logo-img" />
    </div>
    <div class="qrcode-box">
      <img src="../../assets/img/new-qrcode.jpg" class="qrcode-img" />
      <div class="qrcode-text">公众号二维码</div>
    </div>
    <div class="conclusion">
      <div>所有问题已回答完毕，</div>
      <div>感谢您配合本次背调访谈！</div>
    </div>
    <div class="btn-box">
      <!-- <a-button class="return-btn" @click="handleReturn" type="primary"
        >返回</a-button
      > -->
      <div class="end-btn" @click="handleEnd">结束</div>
      <div class="return-btn" @click="handleReturn">返回</div>
      <!-- <a-button class="return-btn" @click="handleEnd" type="primary"
        >结束</a-button
      > -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'CompletePage',
  components: {},
  data () {
    return {
    }
  },
  methods: {
    handleReturn () {
      this.$router.push(
        `/mrecord?identity=identity&code=${this.$route.query.code}`
      )
    },
    handleEnd () {
      window.open('https://www.checkey.net', '_self')
    }
  }
}
</script>

<style scoped lang="less">
.complete-page {
  .logo-box {
    width: 100%;
    /* 自动布局 */
    display: flex;
    justify-content: center;
    padding: 32px 116px 0 116px;
    background: #ffffff;
    .logo-img {
      width: 216px;
      height: 96px;
    }
  }
  .conclusion {
    width: 100%;
    // padding: 0 104px;
    font-size: 20px;
    text-align: center;
    margin-top: 40px;
    color: #182230;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
  }
  .btn-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 30px;
    width: 100%;
    margin-top: 20px;
    padding: 0 40px;
    .return-btn {
      text-align: center;
      background: #fff;
      border-radius: 8px;
      line-height: 40px;
      color: #344054;
      width: 100%;
      height: 40px;
      font-size: 18px !important;
      border: 1px solid var(--Gray-300, #D0D5DD);
    }
    .end-btn {
      text-align: center;
      background: #13a8bf;
      border-radius: 8px;
      line-height: 40px;
      color: #fff;
      width: 100%;
      height: 40px;
      font-size: 18px !important;
      margin-bottom: 16px;
    }
  }
  .qrcode-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 0 135px;
    margin-top: 40px;
    .qrcode-img {
      width: 180px;
      height: 180px;
    }
    .qrcode-text {
      font-size: 20px;
      color: #999;
    }
  }
}
</style>
