import Vue from 'vue'
import Router from 'vue-router'
import MenuView from '@/views/common/MenuView'
import PageView from '@/views/common/PageView'
import LoginView from '@/views/newLogin/Login'
import RegisterView from '@/views/newLogin/Register'
import AuthView from '@/views/auth/Auth'
import ReportAuthView from '@/views/reportAuth/ReportAuth'
import MreportAuthView from '../mobile/MreportAuth.vue'
import MRecorder from '../views/recorder/NewMRecorder.vue'
import IdentityVerification from '../views/recorder/IdentityVerification.vue'
import CompletePage from '../views/recorder/completePage.vue'
import Msuccess from '../mobile/components/Msuccess.vue'
import TextInfoView from '@/views/TextInfo/TextInfo'
import EmptyPageView from '@/views/common/EmptyPageView'
import PDFView from '@/views/common/PDFView'
import CheckView from '@/views/data/page'
import DataView from '@/views/data/page'
import HomePageView from '@/views/manage/home/Home'
import AmongEmail from '@/views/manage/order/AmongPayEmail'
import db from 'utils/localstorage'
import request from 'utils/request'
import { determineIsMobile } from '@/utils/tools.js'
import { isCandidatePage } from '@/utils/utils'
import SoundDetailsView from '../mobile/soundDetails.vue'

// 全局Router异常处理
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  // return originalPush.call(this, location).catch(err => { if (typeof err !== 'undefined') console.log(err) })
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)

const constRouter = [
  {
    path: '/login',
    name: '登录页',
    component: LoginView
  },
  {
    path: '/register',
    name: '注册页',
    component: RegisterView
  },
  {
    path: '/index',
    name: '首页',
    redirect: '/home'
  },
  {
    path: '/auth',
    name: '认证',
    component: AuthView
  },
  {
    path: '/reportAuth',
    name: '报告认证',
    component: ReportAuthView
  },
  {
    path: '/mreportAuth',
    name: '手机端认证',
    component: MreportAuthView
  },
  {
    path: '/msuccess',
    name: '信息授权成功',
    component: Msuccess
  },
  {
    path: '/textinfo',
    name: '协议',
    component: TextInfoView
  },
  {
    path: '/amongeEmail',
    name: '编辑连续背调',
    component: AmongEmail
  },
  {
    path: '/soundDetails',
    name: '录音详情',
    component: SoundDetailsView
  },
  {
    path: '/mrecord',
    name: '录音访谈',
    component: MRecorder
  },
  {
    path: '/identityVerification',
    name: '录音身份验证',
    component: IdentityVerification
  },
  {
    path: '/completePage',
    name: '录音结束页面',
    component: CompletePage
  }
]

const router = new Router({
  routes: constRouter
})

const whiteList = isCandidatePage() ? ['/login'] : ['/login', '/register', '/reportAuth', '/mreportAuth', '/msuccess', '/amongeEmail', '/soundDetails', '/mrecord', '/identityVerification', '/completePage']

let asyncRouter

// 导航守卫，渲染动态路由
router.beforeEach((to, from, next) => {
  if (whiteList.indexOf(to.path) !== -1) {
    if (to.path === '/reportAuth' && determineIsMobile()) {
      if (to.query.reportUuid) {
        return next({
          path: '/mreportAuth',
          query: {
            reportUuid: to.query.reportUuid
          }
        })
      } else {
        return next('/mreportAuth')
      }
    }
    // if (to.path === '/soundDetails') {
    //   if (to.query.reportId) {
    //     console.log('进来了111111111111111')
    //     return next({
    //       path: '/soundDetails',
    //       query: {
    //         reportId: to.query.reportId,
    //         type: to.query.type,
    //         company: to.query.company,
    //         certifier: to.query.certifier,
    //       }
    //     })
    //   }
    // }
    return next()
  }

  const token = db.get('USER_TOKEN')
  const user = db.get('USER')
  const userRouter = get('USER_ROUTER')

  if (token.length && user) {
    request.changeRole(user.userType)
    if (!asyncRouter) {
      if (!userRouter) {
        request.get(`menu/${user.username}`).then((res) => {
          asyncRouter = res.data
          asyncRouter[0].children[0].icon = 'https://fe-center.afunapp.com/page-center/assets/ixBPQf9R/dOEjMfu1.png?_w=57&_h=56'
          save('USER_ROUTER', asyncRouter)
          go(to, next)
        }).catch(err => { console.error(err) })
      } else {
        if (to.path === '/reportAuth' || to.path === '/mreportAuth') {
          if (to.path === '/reportAuth' && determineIsMobile()) {
            if (to.query.reportUuid) {
              next({
                path: '/mreportAuth',
                query: {
                  reportUuid: to.query.reportUuid
                }
              })
            } else {
              next('/mreportAuth')
            }
          } else {
            next()
          }
        } else {
          asyncRouter = userRouter
          go(to, next)
        }
      }
    } else {
      next()
    }
  } else {
    if (to.path === '/reportAuth' || to.path === '/mreportAuth' || to.path === '/msuccess' || to.path === '/textinfo' || to.path === '/login' || to.path === '/register') {
      if (to.path === '/reportAuth' && determineIsMobile()) {
        if (to.query.reportUuid) {
          next({
            path: '/mreportAuth',
            query: {
              reportUuid: to.query.reportUuid
            }
          })
        } else {
          next('/mreportAuth')
        }
      } else {
        next()
      }
    } else {
      next('/login')
    }
  }
})

function go (to, next) {
  asyncRouter = filterAsyncRouter(asyncRouter)
  router.addRoutes(asyncRouter)
  console.log(asyncRouter)
  next({ ...to, replace: true })
}

function save (name, data) {
  localStorage.setItem(name, JSON.stringify(data))
}

function get (name) {
  return JSON.parse(localStorage.getItem(name))
}

function filterAsyncRouter (routes) {
  return routes.filter((route) => {
    const component = route.component
    if (component) {
      switch (route.component) {
        case 'MenuView':
          route.component = MenuView
          break
        case 'PageView':
          route.component = PageView
          break
        case 'EmptyPageView':
          route.component = EmptyPageView
          break
        case 'HomePageView':
          route.component = HomePageView
          break
        case 'CheckView':
          route.component = CheckView
          break
        case 'DataView':
          route.component = DataView
          break
        default:
          route.component = view(component)
      }

      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children)
      }
      return true
    }
  })
}

function view (path) {
  return function (resolve) {
    import(`@/views/${path}.vue`).then(mod => {
      resolve(mod)
    })
  }
}

export default router
