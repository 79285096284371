<template>
  <a-modal
    class="update-avatar"
    title="选择头像"
    @cancel="handleCancel"
    :width="400"
    :footer="null"
    :visible="updateAvatarModelVisible"
    >
    <a-form>
      <a-form-item label='头像' v-bind="formItemLayout">
        <UploadImage :initList="avatarList" :fileSize="100" @callback="avatarCallback" />
      </a-form-item>
      <a-form-item label=' ' :colon="false" v-bind="formItemLayout">
        <div class="my">
          <a-popconfirm
            title="确定放弃编辑？"
            @confirm="onClose"
            okText="确定"
            cancelText="取消"
          >
            <a-button style="margin-right: 0.8rem">取消</a-button>
          </a-popconfirm>
          <a-button @click="handleSubmit" type="primary" :loading="updating"
            >提交</a-button
          >
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import UploadImage from '../../components/upload/UploadImage.vue'
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 }
}
export default {
  components: { UploadImage },
  props: {
    // updateAvatarModelVisible: {
    //   default: false
    // },
    user: {
      required: true
    }
  },
  data () {
    return {
      updateAvatarModelVisible: false,
      formItemLayout,
      updating: false,
      avatarList: [],
      avatar: ''
    }
  },
  computed: {
  },
  methods: {
    setList () {
      this.avatarList = []
      this.avatarList.push(this.user.avatar)
    },
    handleCancel () {
      this.$emit('cancel')
    },
    avatarCallback (imgList) {
      this.avatar = imgList[0]
    },
    handleSubmit () {
      if (this.updating) {
        this.$message.warning('更换头像中，请勿重复点击')
        return
      }
      let avatar = this.avatar
      this.updating = true
      this.$post('web/user/updateUserBasicInfo', {
        avatar
      }).then(() => {
        this.$emit('success', avatar)
        this.updating = false
      }).catch((r) => {
        console.error(r)
        this.$message.error('更新头像失败')
        this.updating = false
      })
    },
    onClose(){
      this.$emit('cancel')
    }
  }
}
</script>
<style lang="less">
  .update-avatar {
    .ant-modal-body {
      padding: 0 1rem 1rem 1rem!important;
      .avatar-tabs {
        .avatar-wrapper {
          display: inline-block;
          img {
            width: 6rem;
            border-radius: 2px;
            display: inline-block;
            margin: .5rem;
            cursor: pointer;
          }
        }
      }
    }
  }
</style>
