<!--
候选人列表
-->
<template>
  <div class="container">
    <div class="content">
      <div class="headerBox" style="padding-bottom: 8px">
        <div class="headerTitle">候选人信息</div>
        <div class="">候选人数：{{ dataSource.length }}人</div>
      </div>
      <div style="padding: 16px">
        <a-table
          ref="TableInfo"
          :pagination="pagination"
          :columns="columns"
          :dataSource="dataSource"
          :rowKey="record => record.mobile"
          @change="handleTableChange"
        >
          <template slot="operations" slot-scope="text, record, index">
            <a-space :size="20">
              <a @click="addInfo(index)">补充资料</a>
            </a-space>
          </template>
        </a-table>
      </div>
    </div>
    <!--编辑弹框-->
    <a-modal v-model="editVisible" @ok="editSuccess">
      <a-form :form="personInfo" hideRequiredMark>
        <a-form-item label="姓名" v-bind="formItemLayout">
          <a-input
            v-model="personInfo.name"
            placeholder="姓名"
          />
        </a-form-item>
        <a-form-item label="手机号" v-bind="formItemLayout">
          <a-input
            v-model="personInfo.mobile"
            placeholder="手机号"
          />
        </a-form-item>
        <a-form-item label="邮箱" v-bind="formItemLayout">
          <a-input
            v-model="personInfo.email"
            placeholder="邮箱"
          />
        </a-form-item>
        <a-form-item label="应聘职位" v-bind="formItemLayout">
          <a-input
            v-model="personInfo.position"
            placeholder="应聘职位"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 }
}
export default {
  name: 'PersonList',
  components: {
  },
  data () {
    return {
      formItemLayout,
      loading: false,
      dataSource: [],
      selectedRowKeys: [],
      queryParams: {},
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          customRender: (text, row, index) => {
            // 当前页数减1乘以每一页页数再加当前页序号+1 `${(pagination.current - 1) * (pagination.pageSize) + (index + 1)}`
            if (this.paginationInfo) {
              return `${(this.paginationInfo.current - 1) * (this.paginationInfo.pageSize) + (index + 1)}`
            } else {
              return index + 1
            }
          }
        },
        {
          title: '姓名',
          dataIndex: 'name'
        },
        {
          title: '手机号',
          dataIndex: 'mobile'
        },
        {
          title: '邮箱',
          dataIndex: 'email'
        },
        {
          title: '应聘职位',
          dataIndex: 'position'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operations' }
        }
      ],
      paginationInfo: null,
      pagination: {
        pageSizeOptions: ['10', '20', '30', '40', '100'],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      personInfo: {},
      personInfoIndex: 0,
      editVisible: false
    }
  },
  created () {},
  mounted () {
  },
  methods: {
    edit (e, index) {
      this.personInfo = e
      this.personInfoIndex = index
      this.editVisible = true
    },
    del (record) {
      if (this.dataSource.length > 1) {
        this.dataSource.forEach((e, index) => {
          if (e.email === record.email && e.name === record.name &&
          e.mobile === record.mobile && e.position === record.position) {
            this.dataSource.splice(index, 1)
            this.$emit('del', index)
          }
        })
      } else {
        this.$message.warning('请至少保留一位候选人')
      }
    },
    // 补充资料
    addInfo (index) {
      this.$emit('addInfo', index)
    },
    handleTableChange () {
    },
    editSuccess () {
      this.dataSource[this.personInfoIndex] = this.personInfo
      this.personInfo = {}
      this.personInfoIndex = 0
      this.editVisible = false
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  width: 100%;
}

.content {
  margin: 16px;
  background-color: #fff;
}

.headerBox {
  background-color: #fff;
  padding-top: 16px;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
  .headerTitle {
    border-left: 3px solid #248AA3;
    padding-left: 8px;
    margin-left: 16px;
    margin-bottom: 16px;
    font-weight: bold;
  }
}
.ant-divider {
  margin: 6px 0 !important;
}
.ant-row {
  margin: 0 !important;
}
// .ant-col {
//   padding: 0 !important;
// }
.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.box1 {
  .subTitle1 {
    font-size: 12px;
    color: #248AA3;
  }
  .subTitle2 {
    font-size: 16px;
    color: #248AA3;
  }
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
  background: #F7F8FA;
  border: 1px solid #248AA3;
}
</style>
