import Vue from 'vue'
import YtVue from './YtVue'
import router from './router'
import Antd from 'ant-design-vue'
// import Vant from 'vant'
import store from './store'
import request from 'utils/request'
import db from 'utils/localstorage'
import AesEncrypt from 'utils/aesEncrypt'
import VueMeta from 'vue-meta'
import VueClipboard from 'vue-clipboard2'

import 'ant-design-vue/dist/antd.less'
// import 'vant/lib/index.css'
import * as echarts from 'echarts'
import 'utils/install'
import 'utils/my.less'
import 'utils/modify-antd.less'
document.body.style.zoom = '80%'
Vue.config.productionTip = false
Vue.use(Antd)
// Vue.use(Vant)
Vue.use(db)
Vue.use(VueMeta)
Vue.use(VueClipboard)
console.log(process.env)

Vue.prototype.$echarts = echarts

Vue.use({
  install (Vue) {
    Vue.prototype.$db = db
  }
})

Vue.prototype.$post = request.post
Vue.prototype.$get = request.get
Vue.prototype.$put = request.put
Vue.prototype.$changeRole = request.changeRole
Vue.prototype.$delete = request.delete
Vue.prototype.$export = request.export
Vue.prototype.$download = request.download
Vue.prototype.$upload = request.upload
Vue.prototype.$resumeOcr = request.resumeOcr
Vue.prototype.$originalGet = request.originalGet
Vue.prototype.$aesEncrypt = AesEncrypt
Vue.prototype.$checkIsNotNull = function checkIsNotNull (value) {
  if (value === '' || value === undefined || value === null) {
    return false
  } else {
    return true
  }
}
Vue.prototype.$checkPhone = function checkPhone (value) {
  const reg = /^[1][0-9][0-9]{9}$/
  if (!reg.test(value)) {
    return false
  } else {
    return true
  }
}
Vue.prototype.$checkEmail = function checkEmail (value) {
  const reg = /^([a-zA-Z]|[0-9])(\w|\-|\.)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/ // eslint-disable-line
  if (!reg.test(value)) {
    return false
  } else {
    return true
  }
}

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(YtVue)
}).$mount('#ytvue')
