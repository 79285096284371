<!--
订单统计
-->
<template>
  <div class="container">
    <div class="content">
      <div class="headerBox" style="padding-bottom: 12px">
        <div class="headerTitle">背调订单数统计</div>
      </div>
      <div class="my-primary-padding my-flex my-item-jc-sb main my-item-center ">
        <div class="my-flex-column">
          <span class="title">本月背调</span>
          <a class="value" @click="toOrder">{{
            chartData.thisMonthOrderNum || 0
          }}</a>
        </div>
        <div class="my-flex-column line">
          <span class="title">90天内背调</span>
          <a class="value" @click="toOrder">{{
            chartData.ninetyDaysOrderNum || 0
          }}</a>
        </div>
        <div class="my-flex-column line">
          <span class="title">本年内背调</span>
          <a class="value" @click="toOrder">{{
            chartData.thisYearOrderNum || 0
          }}</a>
        </div>
        <div class="my-flex-column line">
          <span class="title">累计背调</span>
          <a class="value" @click="toOrder">{{
            chartData.totalOrderNum || 0
          }}</a>
        </div>
        <div class="my-flex-column line">
          <span class="title">国内累计背调</span>
          <a class="value" @click="toOrder">{{
            chartData.guQianTotalOrderNum || 0
          }}</a>
        </div>
        <!-- <div class="my-flex-column line">
          <span class="title">雇中累计背调</span>
          <a class="value" @click="toOrder">{{
            chartData.guZhongTotalOrderNum || 0
          }}</a>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 },
};
export default {
  name: "OrderStatistics",
  components: {},
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      formItemLayout,
      form: {},
    };
  },
  created() {
  },
  mounted() {},
  methods: {
    toOrder() {
      this.$router.push("order/all", "");
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  width: 100%;
}

.content {
  margin: 8px 24px;
}
.main {
  background-color: #fff;
  border-radius: 12px;
}

.headerBox {
  // background-color: #fff;
  // padding-top: 16px;
  .headerTitle {
    font-family: SourceHanSansCN-Medium-Medium;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0px;
    color: #0b2850;
  }
}
.title {
  color: #6E7070;
  font-family: SourceHanSansCN-Regular-Regular;
  font-size: 16px;
  line-height: 16px;
}
.value {
  color: #0B2850;
  font-family: SourceHanSansCN-Bold-Bold;
  font-size: 20px;
  line-height: 20px;
  margin-top: 16px;
  font-weight: bold;
}
.ant-divider {
  margin: 0px !important;
}
.ant-row {
  margin: 0 !important;
}
.line {
    border-left: 1px solid #E0EAF3;;
    padding-left: 21px;
    margin-left: 16px;
  }
</style>
