<template>
  <div class="container">
    <div class="content">
      <div class="headerBox" style="padding-bottom: 8px">
        <div class="headerTitle">已选调查项</div>
      </div>
      <a-divider />
      <div style="width: 100%;padding: 16px;">
        <div class="item-list my-flex-column my-item-jc-sb">
          <h3>雇中核查项</h3>
          <div v-for="items in formatItemList" :key="items.title">
            <div>{{ items.title }}</div>
            <div class="check-item my-flex my-item-jc-sb my-padding-10-0" v-for="item in items.items" :key="item.itemKey">
              <div>{{ item.itemName }}</div>
              <div>{{ item.realPrice }} X{{ item.quantity || 1 }}</div>
              <!-- <div>{{ item.countryName || '中国' }} X{{ item.quantity || 1 }}</div> -->
            </div>
          </div>
          <h3 style="text-align: right">价格 <span>{{totalPrice}}</span></h3>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

const ItemTypeMap = {
  0: '身份信息',
  1: '专业资质',
  2: '职业技能',
  3: '社会负面',
  4: '金融风险',
  5: '个人诚信'
}

export default {
  name: 'AmongItemList',
  props: ['costInfo'],
  data () {
    return {
      totalPrice: 0
    }
  },
  computed: {
    formatItemList () {
      const itemList = this.costInfo.continousItems
      if (!itemList || !itemList.length) return []
      const itemTypeList = {}
      let totalPrice = 0
      itemList.forEach(item => {
        item.selectSurveyTypes.forEach(selectItem => {
          const itemKey = ItemTypeMap[item.itemType]
          totalPrice += selectItem.realPrice
          const modifiedItem = { ...item, ...selectItem }
          if (itemTypeList[itemKey]) {
            itemTypeList[itemKey].push(modifiedItem)
          } else {
            itemTypeList[itemKey] = [modifiedItem]
          }
        })
      })
      // eslint-disable-next-line
      this.totalPrice = totalPrice
      return Object.keys(itemTypeList).map(itemKey => {
        return {
          title: itemKey,
          items: itemTypeList[itemKey]
        }
      })
    }
  }
}
</script>

<style scoped lang="less">

.container {
  width: 100%;
}

.content {
  margin: 16px;
  background-color: #fff;
  box-sizing: content-box;
}

.lr-item {
  display: flex;
  justify-content: space-between;
}

.check-item {
  margin-left: 10px;
}

.headerBox {
  background-color: #fff;
  padding-top: 16px;
  .headerTitle {
    border-left: 3px solid #248AA3;
    padding-left: 8px;
    margin-left: 16px;
    margin-bottom: 16px;
    font-weight: bold;
  }
}
.ant-divider {
  margin: 0 !important;
}
.ant-row {
  margin: 0 !important;
}
// .ant-col {
//   padding: 0 !important;
// }
.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
</style>
