const db = {
  save (key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  get (key, defaultValue = {}) {
    try {
      return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : defaultValue
    } catch (e) {
      this.clear()
      console.error(e)
      console.warn('read local storage failed, fallback to login page')
      location.reload()
    }
  },
  remove (key) {
    localStorage.removeItem(key)
  },
  clear () {
    localStorage.clear()
  }
}

export default db
