<template>
  <div>
    <iframe id="pdfViewer" width="100%" height="600px"></iframe>
  </div>
</template>

<script>
export default {
  data () {
    return {
      pdfUrl: ''
    }
  },
  mounted () {
    this.$route.query.pdfUrl && (this.pdfUrl = this.$route.query.pdfUrl)
    document.getElementById('pdfViewer').src = this.pdfUrl
  }
}
</script>

<style>

</style>
