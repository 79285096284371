<!--

-->
<template>
  <div class="container">
    <div class="content">
      <div class="headerBox" style="padding-bottom: 8px">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <div class="remark">{{ remark }}</div>
          </template>
          <div class="headerTitle">{{ title }}</div>
        </a-tooltip>
        <template v-if="title === '银行信用记录'">
          <a-tooltip placement="topLeft">
            <template slot="title">
              <span v-html="BankCreditAppendix"></span>
            </template>
            <a-icon type="question-circle" :style="{ fontSize: '17px', color: '#13A8BF', marginLeft: '6px' }" />
          </a-tooltip>
        </template>
      </div>
      <a-divider />
      <div class="my-primary-padding my-flex-column">
        <div v-for="(fillItem, index1) in candidateFillIns" :key="index1">
          <div class="company-name" v-if="companyList[index1]">
            {{ "公司" + (index1 + 1) + "名称：" }}
            <span>{{ companyList[index1] }}</span>
          </div>
          <!-- 工作表现和工作履历 start -->
          <div v-if="fillItem.workExperienceInfos">
            <a-form-model labelAlign="left">
              <div
                v-for="(info, index2) in fillItem.workExperienceInfos"
                :key="index2"
              >
                <div v-if="info.itemDetails">
                  <div
                    v-for="(info1, index3) in info.itemDetails"
                    :key="index3"
                  >
                    <template>
                      <div class="item-label">
                        <span style="color: red">*</span>
                        {{ info1.name }}
                      </div>
                      <a-form-model-item v-bind="formItemLayout">
                        <!-- 输入 -->
                        <div class="my-flex">
                          <a-input :disabled="true" v-model.trim="info1.answer" />
                        </div>
                      </a-form-model-item>
                    </template>
                  </div>
                </div>

                <div v-if="info.singleInformations">
                  <div
                    v-for="(info1, index3) in info.singleInformations"
                    :key="index3"
                  >
                    <template>
                      <div class="item-label">
                        <span style="color: red">*</span>
                        {{ info1.name }}
                      </div>
                      <a-form-model-item v-bind="formItemLayout">
                        <!-- 输入 -->
                        <div class="my-flex">
                          <a-input :disabled="true" v-model.trim="info1.content" />
                        </div>
                      </a-form-model-item>
                    </template>
                  </div>
                </div>
              </div>
            </a-form-model>
          </div>
          <div v-if="fillItem.workPerformanceInfos">
            <a-form-model labelAlign="left">
              <div
                v-for="(info, index2) in fillItem.workPerformanceInfos"
                :key="index2"
              >
                <div v-if="info.singleInformations">
                  <div
                    v-for="(info1, index3) in info.singleInformations"
                    :key="index3"
                  >
                    <template>
                      <div class="item-label">
                        <span style="color: red">*</span>
                        {{ info1.name }}
                      </div>
                      <a-form-model-item v-bind="formItemLayout">
                        <!-- 输入 -->
                        <div class="my-flex">
                          <a-input :disabled="true" v-model.trim="info1.content" />
                        </div>
                      </a-form-model-item>
                    </template>
                  </div>
                </div>
              </div>
            </a-form-model>
          </div>
          <!-- 工作表现和工作履历 end -->
          <div v-if="fillItem.candidateNecessaryInfos">
            <a-form-model labelAlign="left">
              <div
                v-for="(info, index2) in fillItem.candidateNecessaryInfos"
                :key="index2"
              >
                <template
                  v-if="
                    info.inputType !== 0 ||
                    info.key !== 'random_interview' ||
                    fillItem.autoSearchCertifier
                  "
                >
                  <div class="item-label">
                    <span style="color: red" v-if="requiredList[index1][index2]"
                      >*</span
                    >
                    {{ info.name }}
                  </div>
                  <a-form-model-item v-bind="formItemLayout">
                    <!--选择-->
                    <div
                      v-if="
                        info.inputType === 0 &&
                        (info.key !== 'random_interview' ||
                          fillItem.autoSearchCertifier)
                      "
                      class="my-flex-column"
                    >
                      <a-radio-group
                        v-model="info.candidateFillIn"
                        :disabled="disableAll"
                        :defaultValue="0"
                      >
                        <a-radio
                          v-for="op in info.config.options"
                          :key="op.key"
                          :value="op.value"
                          >{{ op.key }}</a-radio
                        >
                      </a-radio-group>
                      <div
                        class="my-gray-color hint"
                        v-if="info.config && info.config.hint"
                      >
                        {{ `(${info.config.hint})` || "" }}
                      </div>
                    </div>
                    <!--下拉选择-->
                    <a-select
                      style="width: 200px"
                      v-model="info.candidateFillIn"
                      v-if="info.inputType === 1"
                      :disabled="info.config.disableFill || disableAll"
                    >
                      <a-select-option
                        v-for="op in info.config.options"
                        :key="op.key"
                        :value="op.value"
                      >
                        {{ op.key }}
                      </a-select-option>
                    </a-select>
                    <!-- 输入 -->
                    <div v-if="info.inputType === 2" class="my-flex">
                      <a-input
                        :disabled="info.config.disableFill || disableAll"
                        v-model.trim="info.candidateFillIn"
                      /><span class="my-margin-l-10" v-if="info.config">{{
                        info.config.unit || ""
                      }}</span>
                    </div>
                    <!-- 文件上传 -->
                    <div v-if="info.inputType === 3">
                      <upload-file
                        :disabled="disableAll"
                        fileType="all"
                        :fileSize="7"
                        @callback="imgCallback($event, index1, index2)"
                      />
                    </div>
                    <!-- 数值输入 -->
                    <div v-if="info.inputType === 5" class="my-flex">
                      <a-input-number
                        :disabled="disableAll"
                        v-model="info.candidateFillIn"
                        :min="1"
                        :default-value="1"
                      /><span class="my-margin-l-10" v-if="info.config">{{
                        info.config.unit || ""
                      }}</span>
                    </div>
                    <!-- 时间选择 -->
                    <div v-if="info.inputType === 6">
                      <a-month-picker
                        :disabled="disableAll"
                        :value="defaultDateValue(info.candidateFillIn)"
                        @change="timeChange($event, index1, index2)"
                      />
                    </div>
                    <!-- 时间段选择 -->
                    <div v-if="info.inputType === 7">
                      <a-range-picker
                        :disabled="disableAll"
                        ref="datePicker"
                        :show-time="false"
                        format="YYYY-MM"
                        :mode="['month', 'month']"
                        :value="defaultDateRangeValue(info.candidateFillIn)"
                        :placeholder="['开始时间', '结束时间']"
                        @change="handleTimeChange($event, index1, index2)"
                        @ok="handleTimeChange($event, index1, index2)"
                        @panelChange="handleTimeChange($event, index1, index2)"
                      >
                        <a-icon slot="suffixIcon" type="calendar" />
                      </a-range-picker>
                    </div>
                    <!-- 多文件上传 -->
                    <div v-if="info.inputType === 8">
                      <upload-file
                        :disabled="disableAll"
                        fileType="all"
                        :fileNum="999"
                        :fileSize="1024"
                        :resumeOcr="isResumeOcr(info)"
                        :passportOcr="isPassportOcr(info)"
                        @onPassportOcr="onPassportOcr($event, index1, index2)"
                        @onResumeOcr="onResumeOcr($event, index1, index2)"
                        @callback="mulTiCallback($event, index1, index2)"
                        :initList="info.candidateFillIn ? info.candidateFillIn.split(',') : []"
                      />
                    </div>
                  </a-form-model-item>
                </template>
              </div>
            </a-form-model>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import 'moment/locale/zh-cn'
import UploadFile from '../../../components/upload/UploadFile.vue'
import Mixins from '../../../mixins/reportAuth'
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 }
}
export default {
  name: 'InfoBox',
  mixins: [Mixins],
  components: {
    UploadFile
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    disableAll: {
      type: Boolean,
      default: false
    },
    itemKey: {
      type: String,
      default: ''
    },
    remark: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    },
    candidateFillInsList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: false,
      formItemLayout,
      form: {},
      candidateFillIns: []
    }
  },
  created () { },
  mounted () {
    this.candidateFillIns = this.candidateFillInsList
  },
  computed: {
    companyList () {
      return this.candidateFillIns.map(item1 => {
        return this.title === '工作履历' || this.title === '工作表现' ? item1.initiatorFillIn : ''
      })
    },
    requiredList () {
      return this.candidateFillIns.map(item1 => {
        const flag = false
        return item1.candidateNecessaryInfos.map(item2 => {
          // if (item2.key === 'random_interview' && item2.candidateFillIn === 'true') {
          //   flag = true
          // }
          // if (flag && (
          //   item2.key === 'work_relationship' ||
          //   item2.key === 'certifier' ||
          //   item2.key === 'certifier_mobile' ||
          //   item2.key === 'certifier_position' ||
          //   item2.key === 'work_time' ||
          //   item2.key === 'work_time_begin_date' ||
          //   item2.key === 'work_time_end_date'
          // )) {
          //   return false
          // }
          return item2.required
        })
      })
    }
  },
  watch: {
    candidateFillInsList: {
      deep: true,
      handler (val) {
        this.candidateFillIns = this.candidateFillInsList
      }
    },
    candidateFillIns: {
      deep: true,
      handler (val) {
        this.$emit('change', val)
      }
    }
  },
  methods: {
    imgCallback (imgList, index1, index2) {
      this.candidateFillIns[index1].candidateNecessaryInfos[index2].candidateFillIn = imgList[0]
    },
    defaultDateValue (input) {
      return input ? moment(input) : undefined
    },
    defaultDateRangeValue (input) {
      if (input) {
        const dates = input.split('-')
        return [moment(dates[0]), moment(dates[1])]
      } else return []
    },
    mulTiCallback (imgList, index1, index2) {
      this.candidateFillIns[index1].candidateNecessaryInfos[index2].candidateFillIn = imgList.join(',')
    },
    handleTimeChange (value, index1, index2) {
      if (value.length === 0) {
        this.candidateFillIns[index1].candidateNecessaryInfos[index2].candidateFillIn = ''
      } else if (value.length === 2) {
        this.candidateFillIns[index1].candidateNecessaryInfos[index2].candidateFillIn = moment(value[0]).format('YYYY/MM') + '-' + moment(value[1]).format('YYYY/MM')
      }
      this.$forceUpdate()
    },
    timeChange (value, index1, index2) {
      this.candidateFillIns[index1].candidateNecessaryInfos[index2].candidateFillIn = moment(value).format('YYYY/MM')
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  width: 99%;
  margin: 24px 0;
  padding: 24px;

  /* 纯白 */
  background: #ffffff;
  border-radius: 12px;
  box-sizing: border-box;
  /* 分割线 */
  border: 1px solid #e0eaf3;
}

.hint {
  font-size: 10px;
}

.item-label {
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;

  /* 文字1 */
  color: #6e7070;
  margin-top: 16px;
  margin-bottom: 5px;
}

.item-label-remark {
  font-size: 14px;
  font-weight: bold;
}

.company-name {
  width: 100%;
  height: 40px;
  border-radius: 8px 8px 0px 0px;
  line-height: 40px;
  opacity: 1;
  text-align: center;
  /* BG disable */
  background: #f3f6ff;
  color: #6e7070;
  font-size: 14px;
  margin-top: 20px;
  span {
    font-size: 14px;
    font-weight: 500;
    color: #0b2850;
  }
}

.headerBox {
  display: flex;
  background-color: #fff;
  padding-top: 16px;
  .headerTitle {
    border-left: 5px solid #248aa3;
    padding-left: 8px;
    margin-left: 16px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: bold;
    line-height: 18px;
    letter-spacing: 0em;

    /* 文字1 */
    color: #0b2850;
  }
}
.ant-divider {
  margin: 6px 0 !important;
}
.ant-row {
  margin: 0 !important;
}

/deep/ .ant-form-item-control {
  position: relative;
  line-height: 1.5;
  zoom: 1;
}

/deep/ .ant-select-selection {
  background: inherit;
}
</style>
