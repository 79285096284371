<!--
背调列表
-->
<template>
  <div class="container">
    <!-- 平台套餐使用次数&&自定义套餐使用次数 -->
		<!-- :chartData="orderData.orderDefinedPackageUseList" -->
    <PackageUsage :chartData="orderData" />
    <!-- 背调项占比 -->
    <RiskCheckItems :chartData="orderData"/>
    <!-- 订单数量 -->
    <OrderNum :chartData="orderData"/>
    <!-- 订单效率 -->
    <OrderEfficiency :chartData="orderData"/>
    <!-- 订单金额 -->
    <OrderAmount :chartData="orderData"/>
  </div>
</template>

<script>
import moment from 'moment'
import 'moment/locale/zh-cn'
import PackageUsage from './components/packageUsage.vue'
import RiskCheckItems from './components/riskCheckItems.vue'
import OrderNum from './components/orderNum.vue'
import OrderEfficiency from './components/orderEfficiency.vue'
import OrderAmount from './components/orderAmount.vue'

export default {
  name: 'OrderData',
  components: {
    PackageUsage,
    RiskCheckItems,
    OrderNum,
    OrderEfficiency,
    OrderAmount
  },
  data () {
    return {
      orderData: {}
    }
  },
  computed: {
  },
  mounted () {
    this.init()
  },
  methods: {
    // 初始化订单数据
    init () {
      this.$get('/web/dataBoard/orderData').then(res => {
        if (res.data) {
          this.orderData = res.data.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
}
</style>
