<template>
  <div class="tr-container">
    <input
    @change="handleChange"
    class="start-date date"
    placeholder="起始时间"
    type="month" v-model="date1"
    :min="minDate"
    :max="date2 || currentDate"
    >
    <div>~</div>
    <input
    @change="handleChange"
    class="end-date date"
    type="month"
    v-model="date2"
    :min="date1"
    :max="currentDate">

  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'MRangePicker',
  props: ['defaultValue'],
  data () {
    return {
      date1: '',
      date2: '',
      minDate: '2000-01',
      currentDate: `${new Date().getFullYear()}-${new Date().getMonth() < 10 ? '0' + new Date().getMonth() : new Date().getMonth()}`
    }
  },
  created () {},
  mounted () {
    this.date1 = this.defaultValue?.[0]?.format('YYYY-MM') || moment().format('YYYY-MM')
    this.date2 = this.defaultValue?.[1]?.format('YYYY-MM') || moment().format('YYYY-MM')
  },
  watch: {},
  computed: {
    formatedDate1 () {
      return moment(this.date1).format('YYYY-MM')
    }
  },
  methods: {
    handleChange () {
      this.$emit('change', [moment(this.date1), moment(this.date2)])
    }
  }
}
</script>
<style lang="less" scoped>
.tr-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #d9d9d9;

}
input[type="month"] {
    height: 100%;
    -webkit-appearance: none; /* Safari 和 Chrome */
    -moz-appearance: none; /* Firefox */
    appearance: none; /* 默认样式 */
    background: none; /* 去掉背景 */
    border: none; /* 添加边框 */
    padding: 5px; /* 设置内边距 */
    font-size: 1rem; /* 设置字体大小 */
    outline: none; /* 去掉焦点样式 */
    width: 200px; /* 设置输入框宽度 */
    border-radius: 4px; /* 设置圆角 */
    box-sizing: border-box; /* 包括内边距和边框在内的盒子模型 */
}

/* 通过伪元素添加自定义的下拉箭头 */
input[type="month"]::-webkit-calendar-picker-indicator {
    display: none; /* 隐藏日历图标 */
}
</style>
