var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-container"},[_c('a-menu',{attrs:{"mode":"horizontal"},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}},[(_vm.role !== 'candidate')?_c('a-menu-item',{key:"psw"},[_vm._v("密码登录")]):_vm._e(),_c('a-menu-item',{key:"msg"},[_vm._v(_vm._s(_vm.role === 'candidate' ? '验证码登录' : '短信验证码登录'))])],1),(_vm.current[0] === 'psw')?_c('a-form',{attrs:{"form":_vm.pswForm},on:{"submit":function($event){$event.preventDefault();return _vm.handleLogin.apply(null, arguments)}}},_vm._l((_vm.loginWithPswForm),function(item){return _c('a-form-item',{key:item.key},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            item.key,
            {
              rules: item.rules,
            },
          ]),expression:"[\n            item.key,\n            {\n              rules: item.rules,\n            },\n          ]"}],style:({ borderRadius: '8px !important' }),attrs:{"size":"large","type":item.type,"placeholder":item.placeholder}})],1)}),1):_vm._e(),(_vm.current[0] === 'msg')?_c('a-form',{attrs:{"form":_vm.msgForm},on:{"submit":function($event){$event.preventDefault();return _vm.handleLogin.apply(null, arguments)}}},[_c('a-form-item',{key:_vm.loginWithMsgForm[0].key},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            _vm.loginWithMsgForm[0].key,
            {
              rules: _vm.loginWithMsgForm[0].rules,
            },
          ]),expression:"[\n            loginWithMsgForm[0].key,\n            {\n              rules: loginWithMsgForm[0].rules,\n            },\n          ]"}],style:({ borderRadius: '8px !important' }),attrs:{"size":"large","placeholder":_vm.loginWithMsgForm[0].placeholder}})],1),_c('div',{staticClass:"validate-code-wrapper"},[_c('a-form-item',{key:_vm.loginWithMsgForm[1].key},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              _vm.loginWithMsgForm[1].key,
              {
                rules: _vm.loginWithMsgForm[1].rules,
              },
            ]),expression:"[\n              loginWithMsgForm[1].key,\n              {\n                rules: loginWithMsgForm[1].rules,\n              },\n            ]"}],style:({ borderRadius: '8px !important' }),attrs:{"size":"large","placeholder":_vm.loginWithMsgForm[1].placeholder}})],1),_c('a-button',{staticClass:"validate-code-btn",attrs:{"loading":_vm.codeLoading,"disabled":_vm.codeFlag,"size":"large","type":"primary"},on:{"click":_vm.getValidateCode}},[_vm._v("获取验证码 "+_vm._s(_vm.codeFlag ? `(${_vm.codeTime})` : ""))])],1)],1):_vm._e(),_c('div',{staticClass:"options"},[_c('a-checkbox',{attrs:{"checked":_vm.nextTimeAutoLogin},on:{"change":_vm.onChange}},[_vm._v("下次自动登录")]),(_vm.current[0] === 'psw')?_c('a',{on:{"click":_vm.handleForgetPassword}},[_vm._v("忘记密码?")]):_vm._e()],1),_c('a-button',{staticClass:"login-button",attrs:{"loading":_vm.loading},on:{"click":_vm.handleLogin}},[_vm._v(_vm._s(_vm.role === "personal" ? "个人" : _vm.role === "enterprise" ? "" : "候选人")+"登录")]),(_vm.role !== 'candidate')?_c('div',{staticClass:"login-register-text"},[_vm._v("没有帐号？"),_c('a',{attrs:{"href":`/#/register?role=${_vm.role}`}},[_vm._v("直接注册")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }