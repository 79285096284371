<!-- 查看协议 -->
<template>
  <div class="container">
    <div class="header">
      <div class="title">
        <img src="static\img\logo.png" style="height: 30px;">
        <div class="my-margin-l-10">求职问是 雇佣归真</div>
      </div>
    </div>
    <a-spin :spinning="loading">
      <div class="content">
        <YtQuillEditorPreview :value="data.paramDetail" />
      </div>
    </a-spin>
  </div>
</template>

<script>
import YtQuillEditorPreview from '@/components/YtQuillEditor/YtQuillEditorPreview.vue'
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 }
}
export default {
  name: 'TextInfo',
  components: { YtQuillEditorPreview },
  data () {
    return {
      formItemLayout,
      loading: false,
      data: {}
    }
  },
  created () {},
  mounted () {
    this.show()
  },
  methods: {
    // 查看隐私协议
    show () {
      this.loading = true
      this.$get('web/platform-param/selectByKey/authProtocol')
        .then((r) => {
          this.data = r.data.data
          this.loading = false
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="less" scoped>
  .container {
    display: flex;
    flex-direction: column;
    // height: 100vh;
    overflow: auto;
    background-size: 100%;
    .header {
      width: 100%;
      height: 48px;
      padding: 4px 20px;
      background: #002853;
      .title {
        font-size: 20px;
        color: #fff;
        line-height: 40px;
        display: flex;
        align-items: center;
      }
    }
    .header2 {
      width: 100%;
      height: 90px;
      padding: 30px 20%;
      background: #248AA3;
      .title {
        font-size: 30px;
        color: #fff;
        line-height: 30px;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 50px 20%;
      // background: #F0F0F0;
    }
  }
</style>
