<!--
背调列表
-->
<template>
  <div class="container">
    <!-- 报告统计 -->
    <ReportStatistics :chartData="reportData"/>
    <!-- 单项风险统计 -->
    <SingleRiskStatistics :chartData="reportData"/>
  </div>
</template>

<script>
import moment from 'moment'
import 'moment/locale/zh-cn'
import ReportStatistics from './components/reportStatistics.vue'
import SingleRiskStatistics from './components/singleRiskStatistics.vue'
export default {
  name: 'ReportData',
  components: {
    SingleRiskStatistics,
    ReportStatistics
  },
  data () {
    return {
      reportData: {}
    }
  },
  computed: {
  },
  mounted () {
    this.init()
  },
  methods: {
    // 初始化报告数据
    init () {
      this.$get('/web/dataBoard/reportData').then(res => {
        if (res.data) {
          console.log('res===', res.data.data)
          this.reportData = res.data.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
}
</style>
