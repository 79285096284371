<template>
  <div class="container">
    <div class="header">
      <div class="title">
        <img src="../../assets/img/logo.png" style="height: 30px;">
        <div class="my-margin-l-10">求职问是 雇佣归真</div>
      </div>
    </div>
    <div class="header2">
      <div class="title">{{ flag === 'person' ? '个人用户认证' : '企业用户认证' }}</div>
    </div>
    <div class="content">
      <Person v-if="flag === 'person'" />
      <Enterprise v-if="flag === 'enterprise'" />
    </div>
  </div>
</template>

<script>
import Enterprise from './Enterprise.vue'
import Person from './Person.vue'
export default {
  name: 'Auth',
  components: { Person, Enterprise },
  data () {
    return {
      flag: 'person'
    }
  },
  created () {},
  mounted () {
    if (this.$route.query.flag) {
      this.flag = this.$route.query.flag
      this.$forceUpdate()
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
  .container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
    background-size: 100%;
    .header {
      width: 100%;
      height: 48px;
      padding: 4px 20px;
      background: #002853;
      .title {
        font-size: 20px;
        color: #fff;
        line-height: 40px;
        display: flex;
        align-items: center;
      }
    }
    .header2 {
      width: 100%;
      height: 90px;
      padding: 30px 20%;
      background: #248AA3;
      .title {
        font-size: 30px;
        color: #fff;
        line-height: 30px;
      }
    }
    .content {
      display: flex;
      justify-content: center;
      padding: 50px 20%;
      background: #F0F0F0;
    }
  }
</style>
