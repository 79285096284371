<template>
  <div class="container">
    <div class="float-left-text">求职问是 雇佣归真</div>
    <a
      ><img @click="toWeb" class="logo" src="../../assets/img/logo3.svg" alt=""
    /></a>
    <div class="left">
      <div class="left-text">
        <div class="left-text-1">
          欢迎使用Checkey背调系统
        </div>
        <div class="left-text-2">
          让职场信用被看见
        </div>
      </div>
      <img v-if="role === 'candidate'" src="../../assets/img/candreg.png" />
      <img v-else src="../../assets/img/newLogin.png" />
    </div>
    <div class="right">
      <div class="mobile-titile">
        <div class="mobile-titile-text-1">CHECKEY</div>
        <div class="mobile-titile-text-2">
          致力于为企业提供最快、最公平的背景核查
        </div>
      </div>
      <div class="right-inner">
        <div class="right-inner-header">
          <div v-if="forgetPassword">
            <div class="header-text">忘记密码</div>
            <!-- <div class="header-subtext">请联系管理员</div> -->
          </div>
          <div v-else>

            <div class="header-text">{{ role === 'candidate' ? '欢迎登录候选人门户！' : '欢迎登录！'}}</div>
            <!-- <div class="header-subtext">让职场信用被看见</div> -->
          </div>
          <a-tooltip overlayClassName="login-tooltip" placement="left" v-if="forgetPassword">
            <template slot="title">
              <div style="background: #13A8BF;">{{forgetPassword ? "返回登录" : "返回身份选择"}}</div>
            </template>
            <a @click="handleClickImg">
              <img  src="../../assets/img/qrback.png" alt="">
            </a>
          </a-tooltip>
        </div>
        <div class="right-content">
          <!-- <role-selector v-if="role !== 'candidate'" :role="role" @select-role="handleSelectRole"></role-selector> -->
          <div class="right-content-main">
            <forget-password v-if="forgetPassword" @forget="handleForgetPassword"></forget-password>
            <login-info-form v-else :role="role" @forget="handleForgetPassword"></login-info-form>
          </div>
        </div>
        <div v-if="forgetPassword" class="text-below-btn">已有账号？
            <a @click="forgetPassword = false">去登录</a>
          </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-text">©CHECKEY2023 <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2023024978号-1</a><a href= "https://beian.mps.gov.cn/#/query/webSearch?code=33021202002472" rel="noreferrer" target="_blank">浙公网安备33021202002472</a ></div>
    </div>
  </div>
</template>

<script>
import LoginInfoForm from './LoginInfoForm'
import ForgetPassword from './ForgetPassword'
import db from '../../utils/localstorage'
import RoleSelector from './RoleSelector.vue'
import { isCandidatePage, isDev } from '../../utils/utils'

export default {
  name: 'LoginEntrance',
  components: { LoginInfoForm, ForgetPassword, RoleSelector },
  data () {
    return {
      forgetPassword: false,
      componentName: 'LoginEntrance',
      role: ''
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (to, from) {
        if (isCandidatePage()) {
          this.handleSelectRole('candidate')
        } else {
          if (this.$route.query.role && this.$route.query.role === 'candidate') {
            this.handleSelectRole('candidate')
            return
          }
          if (this.$route.query.role &&
            (this.$route.query.role === 'personal' || this.$route.query.role === 'enterprise')) {
            this.role = this.$route.query.role
          } else {
            this.handleSelectRole('enterprise')
          }
        }
      }
    }
  },
  computed: {
    systemName () {
      return this.$store.state.setting.systemName
    },
    copyright () {
      return this.$store.state.setting.copyright
    }
  },
  methods: {
    handleClickImg () {
      if (this.forgetPassword) {
        this.forgetPassword = false
      } else {
        this.$router.push('/login')
      }
    },
    handleForgetPassword (bool) {
      this.forgetPassword = bool
    },
    handleSelectRole (role) {
      if (role === 'candidate' && !isCandidatePage()) {
        if (!isDev()) {
          role = 'enterprise'
        }
      }
      if (role !== 'candidate') {
        role = 'enterprise'
      }
      if (this.$route.query.role !== role) {
        this.$router.push('/login?role=' + role)
      }
      this.role = role
      if (role === 'personal') {
        this.$changeRole(2)
      } else if (role === 'enterprise') {
        this.$changeRole(1)
      } else if (role === 'candidate') {
        this.$changeRole(0)
      }
    },
    toWeb () {
      window.open('https://www.checkey.net/#/webHome', '_self')
    },
    notKeepLogin () {
      window.addEventListener('onbeforeunload', () => {
        db.clear()
      })
    }
  }
}
</script>

<style lang="less" scoped>
  // @media screen and (max-width: 768px) {
  //   .container {
  //     flex-direction: column;
  //     height: auto !important;
  //     flex-direction: column-reverse;
  //     padding-top: 100px;
  //   }
  // }
@media screen and (max-width: 768px) {
  .float-left-text {
    display: none !important;
  }
  .logo {
    right: calc(50% - 60px) !important;
  }
  .container {
    flex-direction: column;
    height: auto !important;
    flex-direction: column-reverse;
    padding-top: 50px;
  }
  .header-text {
    text-align: center !important;
    width: 100%;
  }
  .left-inner-1 {
    display: none;
  }
  .left-inner-2 {
    margin-top: -100px !important;
  }
  .mobile-titile {
    display: flex !important;
    flex-direction: column;
    text-align: center;
    margin-bottom: 50px;
    background: #F5FCFD;
    height: 100px;
    justify-content: center;
    border-radius: 8px;
    &-text-1 {
      font-size: 25px;
      font-weight: 500;
      line-height: 25px;
      color: #0b2850;
    }
    &-text-2 {
      margin-top: 12px;
      font-size: 15px;
      font-weight: 500;
      line-height: 1;
      color: #0b2850;
    }
  }
}
.mobile-titile {
  display: none;
}
.container {
  position: relative;
  display: flex;
  min-height: 100%;
  background: white;
  .float-left-text {
    position: absolute;
    left: 40px;
    top: 20px;

    font-size: 26px;
    font-weight: 500;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: 0px;

    /* logo绿 */
    color: #13A8BF;
  }
  .logo {
    position: absolute;
    right: 78px;
    top: 20px;
    width: 120px;
  }
  .left {
    flex: 1 1 780px;
    background: #F5FCFD;
    flex-direction: column;
    padding: 3% 8%;
    display: flex;
    justify-content: center;
    &-text {
      &-1 {
        font-size: 28px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0px;
        color: #0B2850;
      }
      &-2 {
        font-family: Source Han Sans CN;
        font-size: 20px;
        font-weight: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #0B2850;
        margin-top: 16px;
      }
    }
    img {
      margin-top: 40px;
      width: 90%;
    }
  }
  .right {
    flex: 0.85 1 660px;
    background: white;
    padding:10%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .header-text {
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;

      /* 文字1 */
      color: #0B2850;
    }
    .header-subtext {
      margin-top: 12px;
      font-size: 14px;
      font-weight: normal;
      line-height: 14px;
      letter-spacing: 0px;

      /* 文字1 */
      color: #0B2850;
    }
    .right-inner {
      max-width: 500px;
      width: 100%;
      .right-inner-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        white-space: nowrap;
      }
      .right-content {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        &-main {
          margin-top: 40px;
        }
      }
    }
    .text-below-btn {
      margin-top: 12px;
      font-size: 16px;
      font-weight: normal;
      line-height: 16px;
      letter-spacing: 0px;

      /* 文字2 */
      color: #6E7070;
    }
  }
  .footer {
    position: absolute;
    bottom: 14px;
    width: 100%;
    justify-content: center;
    text-align: center;
    &-text {
    font-size: 12px;
    font-weight: normal;
    line-height: 1;
    letter-spacing: 0px;

    /* 文字3 */
    color: #969FB6;
  }
  }
}
</style>

<style lang="less">
.login-tooltip {
  .ant-tooltip-inner {
    background-color: #13A8BF;
  }
  .ant-tooltip-arrow::before {
    background-color: #13A8BF;
  }
}
</style>
