<!--
背调列表
-->
<template>
  <div class="container">
    <div class="package-usage-title">
      <div class="package-title">订单金额</div>
    </div>
    <div class="package-usage-item">
      <div class="date-query">
        <a-range-picker
          :show-time="{ format: 'HH:mm' }"
          format="YYYY-MM-DD HH:mm"
          :placeholder="['开始时间', '结束时间']"
          v-model="rangetime"
          @change="handleTimeClear"
          @ok="handleTimeChange"
        />
        <a-button class="search-btn btn" type="primary" @click="search"
          >查询</a-button
        >
        <a-button class="btn" @click="handleExport">导出</a-button>
      </div>
      <div
        id="orderAmount"
        :style="{ zoom: '125%' }"
        class="platform-item"
      ></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as echarts from 'echarts'
export default {
  name: 'OrderAmount',
  props: {
    chartData: {
      type: Object,
      default: () => [],
    },
  },
  components: {
  },
  data () {
    return {
      rangetime: [],
      orderAmount: null
    }
  },
  computed: {
  },
  mounted () {
    setTimeout(() => {
      this.drawLine()
    }, 200)
  },
  methods: {
    drawLine () {
      // 基于准备好的dom，初始化echarts实例
      let orderAmount = this.$echarts.init(document.getElementById('orderAmount'))
      this.orderAmount = orderAmount
      const option = {
        tooltip: {
          trigger: 'item',
          // formatter (name) {
          //   console.log(name)
          //   return (
          //     `订单数量${name.value}个`
          //   )
          // }
        }, // 鼠标移动到图表上时显示的提示信息
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: { bottom: 16, data: ['最大金额', '最小金额', '平均金额'] }, // 图例，指明每条曲线对应的名称
        xAxis: {
          type: 'category',
          data: ['2023-01', '2023-02', '2023-03', '2023-04', '2023-05', '2023-06', '2023-07', '2023-08', '2023-09', '2023-10', '2023-11'],
          axisPointer: {
            type: 'shadow'
          }
        }, // X轴数据
        yAxis: [{
          type: 'value',
          // min: 0,
          // max: 10000,
        },
        {
          type: 'value',
          // min: 0,
          // max: 10000,
        }], // Y轴数据
        series: [
          {
            name: '最大金额',
            type: 'bar',
            color: '#46B8FF',
            barWidth: 22,
            tooltip: {
              valueFormatter: function (value) {
                return value + '元'
              }
            },
            data: [
              2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
            ]
          },
          {
            name: '最小金额',
            type: 'bar',
            color: '#0CD9B5',
            barWidth: 22,
            tooltip: {
              valueFormatter: function (value) {
                return value + '元'
              }
            },
            data: [
              2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
            ]
          },
          {
            name: '平均金额',
            type: 'line',
            color: '#FFA444',
            yAxisIndex: 1,
            barWidth: 22,
            tooltip: {
              valueFormatter: function (value) {
                return value + '元'
              }
            },
            data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
          }
        ]
      }
      option.xAxis.data = this.chartData.orderAmountList.map(item => {
        return item.months
      })
      option.series[0].data = this.chartData.orderAmountList.map(item => {
        return item.maxPayAmount
      })
      option.series[1].data = this.chartData.orderAmountList.map(item => {
        return item.minPayAmount
      })
      option.series[2].data = this.chartData.orderAmountList.map(item => {
        return item.avgPayAmount
      })
      // 绘制图表
      orderAmount.setOption(option)
    },
    handleTimeClear () {

    },
    handleTimeChange () {

    },
    search () {

    },
    handleExport () {
      const img = new Image()
      img.src = this.orderAmount.getDataURL({
        type: 'png',
        pixelRatio: 1,
        backgroundColor: '#fff'
      })
      img.onload = function () {
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0)
        const dataUrl = canvas.toDataURL('image/png')
        const a = document.createElement('a')
        const event = new MouseEvent('click')
        a.download = "订单金额.png"
        a.href = dataUrl
        a.dispatchEvent(event)
        a.remove()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  .package-usage-title {
    display: flex;
    flex-direction: row;
    color: #0b2850;
    font-size: 18px;
    font-weight: bold;
    .package-title {
      margin-bottom: 12px;
    }
    .package-title:last-child {
      margin-right: 0;
    }
  }
  .package-usage-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px rgba(112, 144, 176, 0.12);
    .date-query {
      padding: 16px 32px 2px 0px;
      text-align: right;
      .search-btn {
        margin: 0 8px 0px 16px;
      }
      .btn {
        width: 88px;
      }
    }
    .platform-item {
      width: 100%;
      height: 400px;
    }
  }
}
</style>
