var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forget-container"},[_c('a-form',{attrs:{"form":_vm.forgetForm},on:{"submit":function($event){$event.preventDefault();return _vm.handleLogin.apply(null, arguments)}}},_vm._l((_vm.forgetFormModel),function(item){return _c('div',{key:item.key},[_c('div',{staticClass:"form-label"},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("* ")]),_vm._v(_vm._s(item.name)+" ")]),(item.key !== 'code')?_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  item.key,
                  {
                    rules: item.rules,
                  },
                ]),expression:"[\n                  item.key,\n                  {\n                    rules: item.rules,\n                  },\n                ]"}],style:({ borderRadius: '8px !important' }),attrs:{"size":"large","type":item.type,"placeholder":item.placeholder}})],1):_c('div',{staticClass:"validate-code-wrapper"},[_c('a-form-item',{key:item.key},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    item.key,
                    {
                      rules: item.rules,
                    },
                  ]),expression:"[\n                    item.key,\n                    {\n                      rules: item.rules,\n                    },\n                  ]"}],style:({ borderRadius: '8px !important' }),attrs:{"size":"large","placeholder":item.placeholder}})],1),_c('a-button',{staticClass:"validate-code-btn",attrs:{"loading":_vm.codeLoading,"disabled":_vm.codeFlag,"size":"large","type":"primary"},on:{"click":_vm.getValidateCode}},[_vm._v("获取验证码 "+_vm._s(_vm.codeFlag ? `(${_vm.codeTime})` : ""))])],1)],1)}),0),_c('a-button',{staticClass:"login-button",attrs:{"loading":_vm.loading},on:{"click":_vm.handleResetPassword}},[_vm._v("提交修改")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }