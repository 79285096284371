var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"my-flex my-item-jc-sb"},[_c('div',{staticStyle:{"font-family":"SourceHanSansCN-Bold-Bold","font-size":"18px","color":"#0b2850"}},[_vm._v(" 用户信息 ")]),_c('a-button',{staticStyle:{"border-radius":"32px !important","color":"#13a8bf","border":"1px solid #13a8bf","padding":"6px 20px","width":"96px","height":"32px","display":"flex","flex-direction":"column","justify-content":"center","align-items":"center"},attrs:{"shape":"round","size":"small"},on:{"click":function($event){return _vm.changeMassege()}}},[_vm._v("修改信息")])],1),_c('a-form',{staticStyle:{"margin-top":"20px"},attrs:{"form":_vm.form}},[_c('a-form-item',_vm._b({},'a-form-item',_vm.formItemLayout,false),[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("昵称")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'nickname',
          {
            rules: [
              { required: true, message: '请输入昵称' },
              { max: 50, message: '长度不能超过50个字符' },
            ],
          },
        ]),expression:"[\n          'nickname',\n          {\n            rules: [\n              { required: true, message: '请输入昵称' },\n              { max: 50, message: '长度不能超过50个字符' },\n            ],\n          },\n        ]"}],staticClass:"input1",attrs:{"disabled":""}})],1),_c('a-form-item',_vm._b({attrs:{"label":"邮箱"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          {
            rules: [
              { type: 'email', message: '请输入正确的邮箱' },
              { max: 50, message: '长度不能超过50个字符' },
            ],
          },
        ]),expression:"[\n          'email',\n          {\n            rules: [\n              { type: 'email', message: '请输入正确的邮箱' },\n              { max: 50, message: '长度不能超过50个字符' },\n            ],\n          },\n        ]"}],staticClass:"input1",attrs:{"disabled":""}})],1),_c('a-form-item',_vm._b({attrs:{"label":"手机"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'mobile',
          {
            rules: [
              {
                pattern:
                  '^0?(13[0-9]|15[012356789]|17[013678]|18[0-9]|14[57])[0-9]{8}$',
                message: '请输入正确的手机号',
              },
            ],
          },
        ]),expression:"[\n          'mobile',\n          {\n            rules: [\n              {\n                pattern:\n                  '^0?(13[0-9]|15[012356789]|17[013678]|18[0-9]|14[57])[0-9]{8}$',\n                message: '请输入正确的手机号',\n              },\n            ],\n          },\n        ]"}],staticClass:"input1",attrs:{"disabled":""}})],1),_c('a-form-item',_vm._b({attrs:{"label":"性别"}},'a-form-item',_vm.formItemLayout,false),[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'ssex',
          { rules: [{ required: true, message: '请选择性别' }] },
        ]),expression:"[\n          'ssex',\n          { rules: [{ required: true, message: '请选择性别' }] },\n        ]"}],attrs:{"disabled":""},on:{"change":_vm.handleChange}},[_c('a-select-option',{attrs:{"value":"0"}},[_vm._v(" 男 ")]),_c('a-select-option',{attrs:{"value":"1"}},[_vm._v(" 女 ")]),_c('a-select-option',{attrs:{"value":"2"}},[_vm._v(" 保密 ")])],1)],1),_c('a-form-item',_vm._b({attrs:{"label":"描述"}},'a-form-item',_vm.formItemLayout,false),[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'description',
          { rules: [{ max: 100, message: '长度不能超过100个字符' }] },
        ]),expression:"[\n          'description',\n          { rules: [{ max: 100, message: '长度不能超过100个字符' }] },\n        ]"}],staticClass:"input1",staticStyle:{"height":"54px"},attrs:{"disabled":"","rows":4}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }