<template>
  <div class="container">
    <div class="header">
      <img src="../../assets/img/logo3.svg" class="logoimg" />
    </div>
    <div class="header2">
      <div class="title">录音访谈</div>
    </div>
    <div class="header3">
      <span
        >为了保证录音效果，请在安静环境下进行背调问题回答，并检查麦克风是否正常，如录音不清晰或需要重新回答，请单击问题下方的重录按钮。
        本次录音将保存后提供给企业做招聘决策参考，如您有任何疑问，请联系客服：<a
          href=""
          >service@checkey.net</a
        ></span
      >
    </div>
    <div class="processing-container">
      <div class="processing-bg"></div>
      <div
        class="processing-bar"
        :style="{ width: 100 * processingWidth + '%' }"
      ></div>
      <div class="processing-text">
        {{ (100 * processingWidth).toFixed() + "%" }}
      </div>
    </div>
    <a-spin :spinning="loading">
      <div class="main-content" id="content_overflow">
        <!-- <div v-if="stepShow < 3" class="initial-tips">
          <div>
            关于候选人{{
              candidateName
            }}的本次背调访谈共计10个问题，请依次进行语音作答。
          </div>
          <div style="margin-top: 20px">温馨提示：</div>
          <div>
            客观公正的背调证明信息对企业和候选人都非常重要，checkey通常会对您的回复进行交叉验证，请您如实回答访谈问题，谢谢！
          </div>
        </div> -->
        <!-- <a-divider v-if="stepShow < 3 && stepShow > 0"></a-divider>
        <div v-if="stepShow < 3 && stepShow > 1" class="initial-start">
          <img
            src="../../assets/img/chat.svg"
            style="display: inline-block; width: 15px; height: 15px"
          />
          开始录音回答问题
        </div> -->
        <!-- v-if="stepShow >= 3" -->
        <div class="q-a">
          <div v-for="(item, index) in audioQuestionsList" :key="item.question">
            <MQuestion
              :questionIndex="index + 1"
              :questionText="item.question"
              :questionActive="questionActive"
            ></MQuestion>
            <!-- <MAnswer :answerText="content"></MAnswer> -->
            <div class="answer" v-if="item.url">
              <div class="answer-content">
                <div v-if="item.content" class="answer-content-text">
                  {{ item.content }}
                </div>
                <img
                  v-if="item.url"
                  @click="recPlay(index)"
                  src="../../assets/img/ansaudio.svg"
                  style="display: inline-block; height: 15px"
                />
                <audio :ref="`audioPlayer${index}`">
                  <source :src="item.url" type="audio/mp3" />
                  您的浏览器不支持 audio 元素。
                </audio>
                <div class="answer-undo" @click="reAnswer(index)">
                  <a-icon type="undo"></a-icon>重新回答
                </div>
              </div>
            </div>
          </div>
          <!-- v-if="audioQuestionsList && audioQuestionsList[currentQuestionIndex].url" -->
          <div class="initial-box" v-if="isProblemShow">
            <div class="initial-start">
              <img
                src="../../assets/img/chat.svg"
                style="display: inline-block; width: 15px; height: 15px"
              />
              感谢您的积极配合，你已完成所有背调问题，如需重新回答，请上滑查找
            </div>
          </div>
        </div>
      </div>
      <!-- <MRecordFooter></MRecordFooter> -->
      <div class="footer">
        <div class="wave-container">
          <div class="wave"></div>
        </div>
        <div class="footer-content">
          <!-- <div class="footer-center" @click="handleSound">
            <img src="../../assets/img/audio-mic.svg" alt="" />
            <div class="text">点击开始录音</div>
          </div> -->
          <div class="footer-center">
            <img
              v-if="isShow"
              style="margin-bottom: 10px"
              src="../../assets/img/audio-stop.svg"
              alt=""
              @click="handleSound"
            />
            <img
              style="margin-bottom: 10px"
              @click="suspendRecording"
              v-else
              src="../../assets/img/audio-mic.svg"
              alt=""
            />
            <div class="operation">
              <!-- @click="reRecording" -->
              <div class="text-color" @click="nextQuestion">下一个问题</div>
              <div v-if="isShow" @click="handleSound">点击开始录音</div>
              <div v-else @click="suspendRecording">
                {{ !isPaused ? "已暂停录音" : "再次点击结束录音" }}
              </div>
              <div class="text-color" v-if="isPaused" @click="pause">
                暂停&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              <div class="text-color" v-else @click="handleContinue">
                继续&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              <!-- <div class="text-color" @click="suspendRecording">发送录音</div> -->
            </div>
          </div>
        </div>
      </div>
    </a-spin>
    <MModal
      ref="modal"
      :title="'身份验证'"
      :btn1="'拒绝'"
      :btn2="'同意'"
      @click1="handleClick1"
      @click2="handleClick2"
    >
      <div>
        <div style="margin: 20px 0">
          关于候选人{{
            candidateName
          }}的本次背调访谈共计10个问题，请依次进行语音作答。
        </div>
        <div>温馨提示：</div>
        <div style="margin-bottom: 20px">
          客观公正的背调证明信息对企业和候选人都非常重要，checkey通常会对您的回复进行交叉验证，请您如实回答访谈问题，谢谢！
        </div>
        <div style="margin-bottom: 35px">
          回答问题前需要进行身份验证，您是否同意？
        </div>
      </div>
    </MModal>
  </div>
</template>

<script>
import MModal from './MModal.vue'
import MQuestion from './MQuestion.vue'
import MAnswer from './MAnswer.vue'
import MRecordFooter from './MRecordFooter.vue'
//必须引入的核心
import Recorder from 'recorder-core'
//引入mp3格式支持文件；如果需要多个格式支持，把这些格式的编码引擎js文件放到后面统统引入进来即可
import 'recorder-core/src/engine/mp3'
import 'recorder-core/src/engine/mp3-engine'
//录制wav格式的用这一句就行
//import 'recorder-core/src/engine/wav'
//可选的插件支持项，这个是波形可视化插件
import 'recorder-core/src/extensions/waveview'
//ts import 提示：npm包内已自带了.d.ts声明文件（不过是any类型）

export default {
  components: { MModal, MQuestion, MAnswer, MRecordFooter },
  async mounted () {
    document.body.style.zoom = 1
    if (this.$route.query.identity) {
      this.$refs.modal.close()
      //   this.stepOnShow()
    } else {
      this.$refs.modal.open()
    }
    this.recOpen()
    this.getName()
    this.getAudioQuestionsList()
    // this.getAudioData()
    this.getStorageData()
  },
  data () {
    return {
      loading: false,
      processingWidth: 0,
      stepShow: 1,
      audioQuestionsList: [],
      storageAudio: [],
      currentQuestionIndex: 0, // 当前展示的问题索引
      recorder: null,
      isRecording: false,
      stream: null,
      isShow: true,
      isReAnswer: false,
      currentAudioIndex: 0,
      candidateName: '',
      questionActive: 1,  // 控制问题选中样式
      isPaused: true,
      isProblemShow: false
    }
  },
  computed: {
  },
  watch: {
    // 监听当前消息列表，更新时，保持滚动条位于底部
    audioQuestionsList: function scrollToBottom () {
      this.$nextTick(() => {
        var message = document.getElementById('content_overflow')
        // 滚动滑钮到滚动条顶部的距离=滚动条的高度
        message.scrollTop = message.scrollHeight
      })
    },
    isProblemShow: function scrollToBottom () {
      this.$nextTick(() => {
        var message = document.getElementById('content_overflow')
        // 滚动滑钮到滚动条顶部的距离=滚动条的高度
        message.scrollTop = message.scrollHeight
      })
    }
  },
  methods: {
    // 获取暂存数据
    getStorageData () {
      const params = {
        code: this.$route.query.code,
      }
      this.$get('web/check-report/queryTmpAudio', params)
        .then((r) => {
          if (r.data.data) {
            this.audioQuestionsList = r.data.data
            this.questionActive = this.audioQuestionsList.length
            this.currentQuestionIndex = this.audioQuestionsList.length - 1
          } else {
            this.getAudioQuestionsList('set')
          }
        })
        .catch(() => { })

    },
    // 获取授权人名字
    getName () {
      this.$get('web/check-report/paramByCode', {
        code: this.$route.query.code,
      })
        .then((r) => {
          this.candidateName = r.data.data.candidateName
        })
        .catch(() => { })
    },
    // 获取录音问题
    getAudioQuestionsList (type) {
      setTimeout(() => {
        const params = {
          code: this.$route.query.code,
        }
        this.$get('web/check-report/audioQuestions', params)
          .then((r) => {
            if (type === 'set') {
              this.audioQuestionsList.push(r.data.data[0])
            }
            // this.currentQuestionIndex = 0
            this.storageAudio = r.data.data
            this.processingWidth = (this.audioQuestionsList.length / this.storageAudio.length)
          })
          .catch(() => { })
      }, 100)

    },
    // 返回对应录音数据
    getAudioData () {
      const params = {
        code: this.$route.query.code,
        // company: this.$route.query.ename,
        // reportId: this.$route.query.id,
        // certifier: this.$route.query.name,
        // type: this.$route.query.type,
      }
      this.$get('/web/check-report/audioData', params)
        .then((r) => {
          this.$nextTick(() => {
            // r.data.data.forEach((item, index) => {
            //   if (item.audioFile && item.audioFile.url) {
            //     console.log('进来1', index)
            //     this.audioQuestionsList.push(r.data.data[index])
            //     this.questionActive = 0
            //   }
            // })
            r.data.data.forEach(item => {
              this.audioQuestionsList.forEach(jitem => {
                if (item.question === jitem.question) {
                  jitem.content = item.audioFile.content
                  jitem.url = item.audioFile.url
                }
              })
            })
          })
          //   if (this.audioQuestionsList.length !== this.storageAudio.length) {
          //     // this.audioQuestionsList.push(this.storageAudio[this.currentQuestionIndex + 1])
          //     // this.processingWidth = this.processingWidth + 0.1
          //     if (this.isReAnswer) {
          //       this.currentQuestionIndex = this.audioQuestionsList.length - 1
          //       // this.audioQuestionsList.push(this.storageAudio[this.currentQuestionIndex])
          //       this.isReAnswer = false
          //     } else {
          //       this.audioQuestionsList.push(this.storageAudio[this.currentQuestionIndex + 1])
          //       this.processingWidth = this.processingWidth + 0.1
          //       this.currentQuestionIndex++
          //     }
          //   }
          this.$forceUpdate()
        })
        .catch(() => { })
    },
    stepOnShow () {
      setTimeout(() => {
        if (this.stepShow < 3) {
          this.stepShow++
          this.stepOnShow()
        }
      }, 3000)
    },
    handleClick1 () {
      this.$refs.modal.close()
      this.stepOnShow()
    },
    handleClick2 () {
      this.$router.push(`/identityVerification?code=${this.$route.query.code}`)
    },
    // 录音
    handleSound () {
      if (!this.rec) { console.error("未打开录音"); return }
      this.rec.start()
      this.isRecording = true
      this.isShow = !this.isShow
    },
    recOpen () {
      //创建录音对象
      this.rec = Recorder({
        type: "mp3" //录音格式，可以换成wav等其他格式
        , sampleRate: 16000 //录音的采样率，越大细节越丰富越细腻
        , bitRate: 16 //录音的比特率，越大音质越好
        , onProcess: (buffers, powerLevel, bufferDuration, bufferSampleRate, newBufferIdx, asyncEnd) => {
          //录音实时回调，大约1秒调用12次本回调
          //可实时绘制波形，实时上传（发送）数据
          if (this.wave) this.wave.input(buffers[buffers.length - 1], powerLevel, bufferSampleRate)
        }
      })

      //打开录音，获得权限
      this.rec.open(() => {
        console.log("录音已打开")
        // if (this.$refs.recwave) {//创建音频可视化图形绘制对象
        //   this.wave = Recorder.WaveView({ elem: this.$refs.recwave })
        // }
      }, (msg, isUserNotAllow) => {
        //用户拒绝了录音权限，或者浏览器不支持录音
        console.log((isUserNotAllow ? "UserNotAllow，" : "") + "无法录音:" + msg)
      })
    },
    // 重新录音
    reRecording () {
      console.log('重新录音')
      this.isShow = !this.isShow
    },
    // 结束录音
    suspendRecording () {
      if (!this.rec) { console.error("未打开录音"); return }
      if (!this.isPaused) {
        return this.$message.error('已暂停录音！')
      }
      this.rec.stop((blob, duration) => {
        //blob就是我们要的录音文件对象，可以上传，或者本地播放
        this.recBlob = blob
        //简单利用URL生成本地文件地址，此地址只能本地使用，比如赋值给audio.src进行播放，赋值给a.href然后a.click()进行下载（a需提供download="xxx.mp3"属性）
        var localUrl = (window.URL || webkitURL).createObjectURL(blob)
        console.log("录音成功", blob, localUrl, "时长:" + duration + "ms")

        this.upload(blob)//把blob文件上传到服务器
        this.isShow = !this.isShow
        // this.rec.close()//关闭录音，释放录音资源，当然可以不释放，后面可以连续调用start
        // this.rec = null
      }, (err) => {
        console.error("结束录音出错：" + err)
        this.rec.close()//关闭录音，释放录音资源，当然可以不释放，后面可以连续调用start
        this.rec = null
      })
    },
    // 发送录音
    upload (blob) {
      let formData = new FormData()
      formData.append('file', blob)
      this.$upload('/minio-file/uploadAudio', formData).then((res) => {
        this.uploadAudio(res)
        this.$message.success('上传成功')

      }).catch((r) => {
        console.error(r)
        this.$message.error('上传失败')
        this.loading = false
      })
    },
    // 上传自动录音问题
    uploadAudio (res) {
      // 获取暂存录音问题最后一条
      let audioParams = {}
      // else if (!this.isReAnswer && this.currentQuestionIndex != 0) {
      //   audioParams = this.audioQuestionsList[this.currentQuestionIndex - 1]
      // } 
      if (this.isReAnswer) {
        audioParams = this.audioQuestionsList[this.currentQuestionIndex - 1]
      } else {
        if (this.currentQuestionIndex == 0) {
          audioParams = this.audioQuestionsList[this.currentQuestionIndex]
        } else {
          audioParams = this.audioQuestionsList[this.audioQuestionsList.length - 1]
        }
      }
      const params = {
        // enterpriseName: this.$route.query.ename,
        // id: this.$route.query.id,
        // name: this.$route.query.name,
        // type: this.$route.query.type,
        code: this.$route.query.code,
        questionAnswers: [
          {
            content: res.data.data.content,
            itemKey: audioParams.itemKey,
            name: res.data.data.filename,
            question: audioParams.question,
            url: res.data.data.urlPath
          }
        ],
      }
      //   web/check-report/uploadAudioQuestion
      this.$post('web/check-report/tmpSaveAudio', params)
        .then((r) => {
          const data = r.data
          if (data.code === 200) {
            this.$nextTick(() => {
              this.getAudioData()
            })
          }
        })
        .catch((data) => {
          this.$message.error(data.message)
        })
    },
    // 本地试听
    recPlay (index) {
      const audioPlayer = eval(`this.$refs.audioPlayer${index}`)[0]
      if (audioPlayer) {
        audioPlayer.play()
      }
      // //本地播放录音试听，可以直接用URL把blob转换成本地播放地址，用audio进行播放
      // var audio = document.createElement("audio")
      // audio.controls = true
      // document.body.appendChild(audio)

      // //注意不用了时需要revokeObjectURL，否则霸占内存
      // setTimeout(function () { URL.revokeObjectURL(audio.src) }, 5000)
    },
    // 重新回答
    reAnswer (index) {
      if (!this.isShow) {
        return this.$message.error('请先回答完录音！')
      }
      this.audioQuestionsList[index].content = ''
      this.audioQuestionsList[index].url = ''
      this.currentQuestionIndex = index + 1
      this.questionActive = this.currentQuestionIndex
      this.isReAnswer = true
      this.$forceUpdate()
    },
    // 下一个问题
    nextQuestion () {
      if (this.isReAnswer) {
        this.currentQuestionIndex = this.audioQuestionsList.length
        this.audioQuestionsList.push(this.storageAudio[this.audioQuestionsList.length])
        this.questionActive = this.currentQuestionIndex + 1
        // this.processingWidth = this.processingWidth + 0.1
        this.processingWidth = (this.audioQuestionsList.length / this.storageAudio.length)
        this.isReAnswer = false
      } else {
        if (!this.audioQuestionsList[this.currentQuestionIndex].url) {
          return this.$message.error('请先回答完录音！')
        }
        if (this.audioQuestionsList.length == this.storageAudio.length && this.audioQuestionsList[this.storageAudio.length - 1].url) {
          this.isProblemShow = true
          this.$router.push(`/completePage?code=${this.$route.query.code}`)
          return this.$message.success('您已完成所有背调问题！')
        }
        this.audioQuestionsList.push(this.storageAudio[this.currentQuestionIndex + 1])
        // this.processingWidth = this.processingWidth + 0.1
        this.processingWidth = (this.audioQuestionsList.length / this.storageAudio.length)
        this.currentQuestionIndex++
        this.questionActive++
      }
    },
    // 暂停
    pause () {
      if (this.isRecording) {
        this.rec.pause()
        this.isRecording = false
        this.isPaused = !this.isPaused
      }

    },
    // 继续
    handleContinue () {
      this.rec.resume()
      this.isRecording = true
      this.isPaused = !this.isPaused
    }
  }
}
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.container {
  /* iPhone 14 Pro */
  // position: relative;
  width: 100%;
  // height: 852px;
  height: calc(100% - 120px);
  background: #999;

  /* logo头部 */
  .header {
    width: 100%;
    height: 48px;
    // position: fixed;
    // left: 0;
    // top: 0;
    /* 自动布局 */
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    background: #ffffff;
    z-index: 100;
    .logoimg {
      height: 27.56px;
      width: 96px;
    }
    .header_text {
      font-size: 14px;
      font-weight: 700;
      color: #0b2850;
      line-height: 37px;
      font-family: Source Han Sans;
      font-feature-settings: "smcp" on;
    }
  }

  /* 标题栏 */
  .header2 {
    height: 45px;
    width: 100%;
    // position: fixed;
    // left: 0;
    // top: 48px;
    background: #13a8bf;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    .show-more {
      position: absolute;
      left: 24px;
    }
    .title {
      font-size: 17px;
      font-weight: bold;
      line-height: 17px;
      letter-spacing: 0em;
      font-family: Source Han Sans;
      font-feature-settings: "kern" on;
      color: #ffffff;
    }
  }
  .header3 {
    padding: 10px;
    padding-bottom: 0px;
    height: 120px;
    width: 100%;
    // position: fixed;
    // left: 0;
    // top: 93px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  .processing-container {
    // position: fixed;
    // left: 0px;
    // top: 213px;
    width: 100%;
    height: 40px;
    opacity: 1;
    padding: 12px 12px;
    z-index: 100;
    display: fixed;
    align-items: center;
    justify-content: center;
    background: white;
    .processing-bg {
      left: 0;
      top: 0;
      background: #e0eaf3;
      width: 100%;
      height: 5px;
      border-radius: 100px;
    }
    .processing-bar {
      position: relative;
      left: 0;
      top: -5px;
      background: #13a8bf;
      height: 5px;
      border-radius: 100px;
    }
    .processing-text {
      text-align: right;
      font-size: 12px;
    }
  }

  /* 主体内容 */
  .main-content {
    width: 100%;
    // height: 100%;
    // position: absolute;
    // left: 0;
    // top: 117px;
    // display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    overflow-y: auto;
    height: 260px;
    overflow-x: hidden;
    // margin-top: 230px;
    // margin-bottom: 61px;
    padding-bottom: 61px;
    background: #999;
    .card-content-inner {
      width: 100%;
      margin-top: 15px;
    }
  }
  .initial-tips {
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin-top: 15px;
    font-size: 14px;
    color: #3d3d3d;
    border-radius: 10px;
    background: #ffffed;

    box-sizing: border-box;
    /* 分割线 */
    border: 1px solid #e0eaf3;
  }

  .initial-start {
    // width: 171px;
    // height: 46px;
    border-radius: 10px;
    opacity: 1;

    /* 自动布局 */
    padding: 15px;

    /* 纯白 */
    background: #ffffff;

    font-size: 15px;
    font-weight: normal;
    line-height: 15px;
    letter-spacing: 0em;

    font-feature-settings: "kern" on;
    /* 成功色 */
    color: #38b865;
    text-align: center;
  }

  .q-a {
    width: 100%;
    // padding-bottom: 150px;
    .initial-box {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      border-top: 1px solid #fff;
      .initial-start {
        width: 300px;
        margin-top: 20px;
      }
    }
  }
  .footer {
    // position: fixed;
    // left: 0;
    // bottom: 0;
    width: 100%;
    // height: 167px;
    height: 150px;
    border-radius: 16px 16px 0px 0px;
    opacity: 1;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background: #ffffff;

    box-shadow: 0px 0px 6px 0px rgba(186, 192, 193, 0.3);
  }

  .footer-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .footer-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    img {
      width: 45px;
      height: 45px;
    }
    .text {
      font-size: 15px;
      font-weight: normal;
      line-height: 15px;
      letter-spacing: 0px;

      /* 文字3 */
      color: #969fb6;
      margin-top: 10px;
    }
    .operation {
      display: flex;
      width: 100%;
      justify-content: space-around;
      .text-color {
        color: #13a8bf;
      }
    }
  }
  .answer-undo {
    position: absolute;
    bottom: -20px;
    right: 0;
    color: #6e7070;
    font-size: 15px;
    font-weight: normal;
    line-height: 15px;
  }
  .answer {
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 30px;
  }
  .answer-content {
    max-width: 280px;
    width: fit-content;
    padding: 10px 15px;
    background: #13a8bf;
    margin-top: 20px;
    border-radius: 10px 10px 0px 10px;

    &-point {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #13a8bf;
      margin-right: 10px;
      display: inline-block;
    }
    &-text {
      display: inline-block;
      font-size: 15px;
      font-weight: normal;
      line-height: 20px;
      letter-spacing: 0em;

      color: white;
    }
  }
}
</style>
