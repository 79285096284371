<template>
  <div class="clearfix">
    <a-upload
      name="file"
      class="yt-uploader"
      :file-list="uploadFileList"
      :before-upload="beforeUpload"
      :remove="handleRemove"
      :customRequest="handleUploadImage"
      :showUploadList="showUploadList"
      @change="handleChange"
      @preview="handlePreview"
    >
      <a-button
        :disabled="disabled"
        :type="btnType"
      > <a-icon type="upload" /> {{text}} </a-button>
    </a-upload>
    <PdfModal ref="pdf" />
    <DocPreview ref="doc" :docValue="docValue" :docType="docType"/>
    <ImgPreview ref="img" :docValue="docValue"/>
  </div>
</template>

<script>
import PdfModal from '../../views/manage/report/components/PdfModal.vue'
import DocPreview from '../../components/DocPreview.vue'
import ImgPreview from '../../components/imgPreview.vue'
export default {
  name: 'UploadFile',
  components: {
    PdfModal,
    DocPreview,
    ImgPreview
  },
  props: {
    // 初始化列表
    initList: {
      type: Array,
      required: false,
      default: () => ([])
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    // 设置上传文本的显示
    text: {
      type: String,
      required: false,
      default: '上传'
    },
    // 文件类型：all-全部 image-图片 video-视频 audio-音频 zip-压缩包 pdf-PDF office-文档 custom-自己定义（使用fileTypeList 参考 https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept https://developer.mozilla.org/zh-CN/docs/Web/HTTP/Basics_of_HTTP/MIME_types）
    fileType: {
      type: String,
      required: false,
      default: 'image'
    },
    // 文件类型列表
    fileTypeList: {
      type: Array,
      required: false,
      default: () => ([])
    },
    // 设置上传文件的个数 默认1（当大于1时为列表展示）
    fileNum: {
      type: Number,
      required: false,
      default: 1
    },
    // 设置上传文件的大小 单位M，默认5M
    fileSize: {
      type: Number,
      required: false,
      default: 5
    },
    // icon: {
    //   type: Object,
    //   required: false,
    //   default: () => ({})
    // },
    // 携带的无效数据，用于数据返回（如指定某一列时可以传递某一列索引等）
    stamp: {
      required: false,
      default: ''
    },
    btnType: {
      required: false,
      default: ''
    },
    // 返回类型：object: 返回文件对象数组 url: 返回url数组
    returnType: {
      required: false,
      default: 'url'
    },
    showUploadList: {
      type: Boolean,
      required: false,
      default: true
    },
    resumeOcr: {
      type: Boolean,
      required: false,
      default: false
    },
    passportOcr: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      fileList: [],
      uploadFileList: [],
      iconDefaultScale: 1.2, // 默认icon缩放比例,
      docValue: '',
      docType: 'office'
    }
  },
  watch: {
    initList (newVal) {
      this.uploadFileList = this.handleFileData(newVal)
    }
  },
  created () {
    this.uploadFileList = this.handleFileData(this.initList)
  },
  methods: {
    showPdf (url) {
      this.$refs.pdf.pdfSrc = url
      this.$refs.pdf.getPdfSrc()
      this.$refs.pdf.showVisiable = true
    },
    showDoc (url) {
      this.docValue = url
      this.$refs.doc.showVisiable = true
    },
    showImg (url) {
      this.docValue = url
      this.$refs.img.showVisiable = true
    },
    handlePreview (e) {
      if (this.isPDF(e.url)) {
        this.showPdf(e.url)
      } else if (this.isDoc(e.url)) {
        this.showDoc(e.url)
      } else {
        this.showImg(e.url)
      }
    },
    isPDF (str) {
      return str.slice(-4) === '.pdf'
    },
    isDoc (str) {
      const suffix = str.slice(-5)
      return suffix === '.docx'
    },
    // 文件修改事件
    handleChange (info) {
      // change
    },
    // 娇艳文件
    beforeUpload (file) {
      return new Promise((resolve, reject) => {
        // 判断文件类型
        const fileTypeList = this.getFileTypeList(this.fileType)
        if (fileTypeList.length > 0) {
          const index = fileTypeList.indexOf(file.type)
          if (index < 0) {
            this.$message.error('您不能上传该格式的文件')
            return reject(new Error('您不能上传该格式的文件'))
          }
        }
        // 判断大小
        const fileSize = file.size / 1024 / 1024
        const isFileSizeLegal = fileSize < this.fileSize
        if (!isFileSizeLegal) {
          this.$message.error(`文件大小不能大于${this.fileSize}MB`)
          return reject(new Error(`文件大小不能大于${this.fileSize}MB`))
        }
        // 判断数量
        if (this.fileList.length >= this.fileNum) {
          this.$message.error('当前只能上传' + this.fileNum + '个文件')
          return reject(new Error('当前只能上传' + this.fileNum + '个文件'))
        }
        const formData = new FormData()
        formData.append('file', file)
        if (this.resumeOcr) {
          this.resumeOcr && this.$resumeOcr('/v1/ocr/parseResume', formData).then((res) => {
            const data = res.data
            if (data.code === 200) {
              this.$emit('onResumeOcr', res.data.data)
              this.$message.success('简历解析成功')
            } else {
              this.$message.error('简历解析失败')
            }
            this.callback()
          }).catch((r) => {
            console.error(r)
            this.$message.error('简历解析失败')
          })
        }
        this.$upload('/minio-file/upload', formData).then((res) => {
          const data = res.data
          if (data.code === 200) {
            const index = {
              uid: this.genId(5),
              name: file.name,
              status: 'done',
              url: data.data.urlPath
            }

            this.passportOcr && this.$get('/v1/ocr/passportOcr', {
              url: data.data.urlPath
            }).then(res => {
              this.$message.success('护照解析成功')
              this.$emit('onPassportOcr', res.data.data)
            }).catch((r) => {
              console.error(r)
              this.$message.error('护照解析失败')
            })
            this.uploadFileList = [...this.uploadFileList.filter(item => item.status === 'done'), index]
            if (this.uploadFileList.length > this.fileNum) this.uploadFileList.shift() // 移除超出的文件
            this.$message.success('上传成功')
          } else {
            this.$message.error('上传失败')
          }
          this.loading = false
          this.callback()
        }).catch((r) => {
          console.error(r)
          this.$message.error('上传失败')
          this.loading = false
        })
        return resolve(true)
      })
    },
    // 防止请求404 自定义请求
    handleUploadImage () {
      console.debug('empty upload')
    },
    // 处理移除文件
    handleRemove (file) {
      if (this.loading) {
        this.$message.warning('文件上传中，请勿删除')
        return
      }
      const index = this.uploadFileList.indexOf(file)
      const newFileList = this.uploadFileList.slice()
      newFileList.splice(index, 1)
      this.uploadFileList = newFileList
      this.callback()
    },
    // 生成随机ID
    genId (length) {
      return Number(Math.random().toString().substr(3, length) + Date.now()).toString(36)
    },
    // 处理文件数据
    handleFileData (list) {
      return list.map(item => {
        const items = item.split('/')
        const index = items[items.length - 1]
        return {
          uid: index,
          name: index,
          status: 'done',
          url: item
        }
      })
    },
    /**
     * 定义文件类型
     * @param fileType all-全部 image-图片 video-视频 audio-音频 zip-压缩包 custom-自定义
     * @returns {string[]}
     */
    getFileTypeList (fileType) {
      // MIME可通过 https://www.iana.org/assignments/media-types/media-types.xhtml 查询
      let fileTypeList = []
      switch (fileType) {
        case 'all':
          fileTypeList = []
          break
        case 'image':
          fileTypeList = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml']
          break
        case 'video':
          fileTypeList = ['video/mp4', 'video/ogg', 'video/MP4V-ES']
          break
        case 'audio':
          fileTypeList = ['audio/mpeg', 'audio/ogg', 'audio/mp4']
          break
        case 'zip':
          fileTypeList = ['application/zip', 'application/x-rar-compressed']
          break
        case 'pdf':
          fileTypeList = ['application/pdf']
          break
        case 'office':
          fileTypeList = ['application/vnd.ms-powerpoint', 'application/vnd.ms-excel', 'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation']
          break
        case 'custom':
          fileTypeList = this.fileTypeList
          break
        default:
          fileTypeList = []
          break
      }
      return fileTypeList
    },
    // 结束回调
    callback () {
      this.uploadFileList = [...this.uploadFileList.filter(item => item.status === 'done')]
      let callbackArray = [...this.uploadFileList]
      if (this.returnType === 'url') {
        // 返回精简的url格式
        callbackArray = this.uploadFileList.filter(item => item.url).map(item => {
          return item.url
        })
      }
      this.$emit('callback', callbackArray || [], this.stamp)
    }
  }
}
</script>

<style lang="less" scoped>

.yt-uploader {
  .ant-upload {
    width: 10em;
    height: 10em;
    background-color: transparent !important;
  }

  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }
}
</style>
