<!-- 用户信息授权填写 -->
<template>
  <div class="container">
    <div class="header">
      <img src="../assets/img/logo3.svg" class="logoimg" />
      <span class="header_text">求职问是 雇佣归真</span>
    </div>
    <div class="header2">
      <!-- <div class="show-more" @click="openAnchor"><a-icon style="font-size:24px;color: white;" type="ellipsis" />
      </div> -->
      <div class="title">个人授权信息表</div>
    </div>
    <!-- <div class="prompt">
      <div class="prompt_text">
        您可点击左侧👈图标，快速定位到需要填写的内容～
      </div>
    </div> -->
    <div class="processing-container">
      <div class="processing-bg"></div>
      <div
        class="processing-bar"
        :style="{ width: 100 * processingWidth + '%' }"
      ></div>
    </div>

    <a-spin :spinning="loading" class="spin-content">
      <div class="out-content" v-if="!isLastStage">
        <div
          v-if="currentStageItem && currentStageItem.candidateFillIns"
          class="card-content-inner"
        >
          <MInfoBox
            v-show="!currentStageItem.hide"
            :title="currentStageItem.itemName"
            :remark="currentStageItem.remark"
            :candidateFillInsList="currentStageItem.candidateFillIns"
            :index="currentStage"
            :checking="checking"
            ref="infoBox"
            @change="infoChange($event, currentStage)"
          />
        </div>
        <!-- </div> -->
      </div>
      <div class="out-content" v-else>
        <div
          v-for="(item, index) in this.reportInfo.checkItems"
          :key="item.itemName"
          class="card-content-inner"
        >
          <MInfoBox
            v-show="!item.hide"
            :title="item.itemName"
            :remark="item.remark"
            :candidateFillInsList="item.candidateFillIns"
            :index="index"
            :checking="checking"
            ref="infoBox"
            @change="infoChange($event,  index)"
          />
        </div>
        <a-checkbox
          v-model="checkFlag"
          class="bottom_mar"
          >您已知晓并同意<span class="my-primary-color" @click="show"
            >《候选人信息收集协议》</span
          ><span class="my-primary-color" @click="showPri"
            >《隐私政策》</span>
        </a-checkbox>
      </div>
    </a-spin>
    <a-spin :spinning="loading">
      <div class="bottom_bar">
        <!-- <a-button class="but" @click="submitTemp">暂存</a-button> -->
        <a-button
          v-if="currentStage > 0"
          class="but"
          @click="handlePreviousStep"
          >上一步</a-button
        >
        <a-button
          v-if="!isLastStage"
          class="but but_color"
          @click="handleNextStep"
          >下一步</a-button
        >
        <a-button v-if="isLastStage" class="but but_color" @click="check"
          >提交</a-button
        >
      </div>
    </a-spin>
    <TextModal ref="textRef" />
    <!-- 手机图标 -->
    <!-- <div class="phone-icon-box" @click="handlePhone">
      <img class="phone-icon" src="../assets/img/Phone.jpg" />
    </div> -->
  </div>
</template>

<script>
import TextModal from '../views/manage/startBack/components/TextModal.vue'
import MInfoBox from '../mobile/components/MInfoBox.vue'
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 14 }
}
export default {
  name: 'MreportAuth',
  components: { MInfoBox, TextModal },
  data () {
    return {
      formItemLayout,
      reportUuid: '',
      reportInfo: {},
      checkFlag: false,
      loading: false,
      touchX: 0, // 移动端点击时距左边的距离
      touchY: 0,
      odiv: '',
      targetOffset: undefined,
      showDialog: false,
      anchorIcon: true,
      currentStage: 0,
      checking: false
    }
  },
  created () { },
  mounted () {
    // 在线客服代码
    document.body.style.zoom = '100%'
    document.documentElement.style.fontSize = 'calc(100vw / 393 * 16)'
    var _hmt = _hmt || [];
    (function() {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?2a4ee32639e069481e7c1c057c4de0de";
      var s = document.getElementsByTagName("script")[0]; 
      s.parentNode.insertBefore(hm, s);
    })();
    // window.addEventListener("popstate", function () {
    //   this.goBack;
    // });
    document.body.style.zoom = 1
    this.targetOffset = window.innerHeight / 6.2
    if (this.$route.query.reportUuid) {
      this.reportUuid = this.$route.query.reportUuid
      this.checkReport()
    }
    // 添加监听事件, 监听页面退出事件;
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL)
      window.addEventListener('popstate', (e) => {
        this.goBack(e)
      })
    }
  },
  computed: {
    currentStageItem () {
      if (this.reportInfo && this.reportInfo.checkItems) {
        return this.reportInfo.checkItems[this.currentStage]
      }
      return null
    },
    isLastStage () {
      return this.reportInfo && this.reportInfo.checkItems && this.reportInfo.checkItems.length === this.currentStage
    },
    isFirstStage () {
      return this.currentStage === 0
    },
    processingWidth () {
      if (this.reportInfo && this.reportInfo.checkItems && this.reportInfo.checkItems.length > 0) {
        return (this.currentStage + 1) / this.reportInfo.checkItems.length
      }
      return 0
    }
  },
  methods: {
    // 播打电话
    // handlePhone () {
    //   window.location.href = `tel:0574-28801525`
    // },
    handlePreviousStep () {
      this.checking = false
      this.currentStage--
    },
    async handleNextStep () {
      try {
        this.checkItem(this.currentStageItem)
      } catch (e) {
        console.log(e)
        this.checking = true
        return
      }
      this.checking = false
      await this.submitTemp()
      this.currentStage++
      window.scrollTo(0, 0)
    },
    dialog_confirm () {
      this.$router.push('/')
    },
    goBack (e) {
      this.showDialog = true
    },
    // 点击锚点不记录历史
    handleClick (e, link) {
      console.log(e, link)
      e.preventDefault()
    },
    // 手指按下
    touchStart (e) {
      e.preventDefault()
      this.odiv = e.target // 获取目标元素
      // 算出鼠标相对元素的位置
      this.touchX = e.changedTouches[0].pageX - this.odiv.offsetLeft
      this.touchY = e.changedTouches[0].pageY - this.odiv.offsetTop
      console.log('x======' + this.touchX, 'y======' + this.touchY)
      console.log(e)
      console.log(
        'offsetLeft======' + this.odiv.offsetLeft,
        'offsetTop======' + this.odiv.offsetTop
      )
      console.log(
        'innerWidth=====' + window.innerWidth,
        'innerHeight=====' + window.innerHeight
      )
      console.log(this.odiv.offsetWidth, this.odiv.offsetHeight) // 元素宽高
    },
    // 手指拖动事件
    touchMove (e) {
      // 这三个是拖动图标的事件
      // @touchstart="touchStart"
      // @touchmove="touchMove"
      // @touchend="touchEnd"
      // 用手指的位置减去手指相对元素的位置，得到元素的位置
      let left = e.changedTouches[0].pageX - this.touchX
      let top = e.changedTouches[0].pageY - this.touchY
      console.log('left======' + left, 'top======' + top)

      if (left < 0) {
        left = 0
      }
      if (left > window.innerWidth - this.odiv.offsetWidth) {
        left = window.innerWidth - this.odiv.offsetWidth
      } // 可视区域宽度
      if (top < 0) {
        top = 0
      }
      if (top > window.innerHeight - this.odiv.offsetHeight) {
        top = window.innerHeight - this.odiv.offsetHeight
      } // 可视区域高度

      // 移动当前元素
      this.odiv.style.left = left + 'px'
      this.odiv.style.top = top + 'px'
    },
    // 手指抬起
    touchEnd () {
      console.log(123)
    },
    // 查看协议
    show () {
      this.$get('web/check-report/getCollectionAgreement/' + this.reportUuid)
        .then((r) => {
          const data = r.data.data
          this.$refs.textRef.text = data.paramDetail
          this.$refs.textRef.title = data.paramName
          this.$refs.textRef.visiable = true
        })
        .catch(() => { })
    },
    // 查看隐私协议
    showPri () {
      this.$get('web/platform-param/selectByKey/privacyPolicy')
        .then((r) => {
          const data = r.data.data
          this.$refs.textRef.text = data.paramDetail
          this.$refs.textRef.title = data.paramName
          this.$refs.textRef.visiable = true
        })
        .catch(() => { })
    },
    // 获取报告信息
    checkReport () {
      const that = this
      this.loading = true
      this.$get(
        'web/check-report/selectFillInCheckReportByCandidate/' + that.reportUuid
      )
        .then((r) => {
          const data = r.data.data
          this.loading = false
          // 逻辑不清楚 暂时先这样改
          data.checkItems = data.checkItems.filter(item => !item.hide)
          that.reportInfo = data
          switch (data.fillInStatus) {
            case 4:
              that.$warning({
                title: '当前报告尚未完成易签宝未授权',
                content: '是否前往授权？',
                okText: '前往授权',
                okType: 'danger',
                keyboard: false,
                maskClosable: false,
                onOk () {
                  that.getPsnAuthUrl()
                }
              })
              break
            case 5:
              this.loading = true
              that.$message.info('已完成该核查报告填写')
              setTimeout(() => {
                this.loading = false
                this.$router.push('/msuccess')
                // window.open("https://www.checkey.net/#/webHome", "_self");
              }, 1000)
              break
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 获取e签宝链接
    getPsnAuthUrl () {
      this.$get('web/check-report/getSignUrl/' + this.reportUuid)
        .then((r) => {
          const data = r.data.data
          window.open(data, '_self')
        })
        .catch(() => { })
    },
    // 核查项信息变更
    infoChange (e, index) {
      this.reportInfo.checkItems[index].candidateFillIns = e
    },
    // 勾选协议校验
    check () {
      console.log(this.reportInfo.checkItems)
      if (!this.checkFlag) {
        this.$message.warning('请勾选同意候选人信息收集协议议')
      } else {
        this.checkSub()
      }
    },
    checkSub () {
      this.reportInfo.checkItems.forEach(this.checkItem)
      this.handleSubmit()
    },
    checkItem (item) {
      if (item.candidateFillIns) {
        item.candidateFillIns.forEach((e2) => {
          let randomInterviewFlag = false
          if (e2.candidateNecessaryInfos) {
            e2.candidateNecessaryInfos.forEach((e3) => {
              if (e3.key === 'random_interview' && e3.candidateFillIn === 'true') {
                randomInterviewFlag = true
              }
              if (randomInterviewFlag &&
                (e3.key === 'work_time_begin_date' ||
                  e3.key === 'work_time_end_date')) {
                e3.candidateFillIn = ''
              }
              if (randomInterviewFlag &&
                (e3.key === 'work_relationship' ||
                  e3.key === 'certifier' ||
                  e3.key === 'certifier_mobile' ||
                  e3.key === 'certifier_position' ||
                  e3.key === 'work_time' ||
                  e3.key === 'work_time_begin_date' ||
                  e3.key === 'work_time_end_date')) {
                return
              }
              if (!e2.autoSearchCertifier && e3.key === 'random_interview') {
                return
              }
              if (!this.$checkIsNotNull(e3.candidateFillIn)) {
                this.$message.error('请补充完全信息: ' + e3.name)
                throw "error" // eslint-disable-line
              }
            })
          }
        })
      }
    },
    submitTemp () {
      this.loading = true
      return this.$post('web/check-report/userFillInStaging', {
        uuid: this.reportUuid,
        checkItems: this.reportInfo.checkItems
      })
        .then((r) => {
          if (r.data.code === 200) {
            this.$message.success('保存成功')
            // setTimeout(() => {
            this.loading = false
            //   // this.$router.push('home')
            //   window.open('https://www.checkey.net/#/webHome', '_self')
            // }, 1000)
          }
        })
        .catch((data) => {
          this.loading = false
          this.$message.error(data.message)
        })
    },
    // 提交报告信息
    handleSubmit () {
      this.loading = true
      this.$post('web/check-report/userFillInReport', {
        uuid: this.reportUuid,
        checkItems: this.reportInfo.checkItems
      })
        .then((r) => {
          if (r.data.code === 200) {
            this.$message.success('提交成功')
            setTimeout(() => {
              this.loading = false
              // this.$router.push('home')
              window.open(r.data.data, '_self')
              // window.open('https://checkey.web.yuntaitec.com/#/webHome', '_self')
            }, 1000)
          }
        })
        .catch((data) => {
          this.loading = false
          this.$message.error(data.message)
        })
    }
  },
  // 3、页面销毁时，取消监听
  destroyed () {
    window.removeEventListener('popstate', this.goBack, false)
  }
}
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.container {
  /* iPhone 14 Pro */
  position: relative;
  width: 100%;
  // height: 852px;
  height: 100%;

  /* logo头部 */
  .header {
    width: 100%;
    height: 48px;
    position: fixed;
    left: 0;
    top: 0;
    /* 自动布局 */
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    background: #ffffff;
    z-index: 100;
    .logoimg {
      height: 27.56px;
      width: 96px;
    }
    .header_text {
      font-size: 14px;
      font-weight: 700;
      color: #0b2850;
      line-height: 37px;
      font-family: Source Han Sans;
      font-feature-settings: "smcp" on;
    }
  }
  /* 锚点盒子 */
  .anchor_box {
    position: fixed;
    left: 0;
    top: 93px;
    background: rgba(16, 28, 46, 0.6);
    opacity: 0.9;
    width: 100%;
    height: 715px;
    transition: width 0.3s ease-in-out;
    z-index: 1000;
    .anchor_content {
      height: 100%;
      width: 242px;
      display: flex;
      flex-direction: column;
      padding: 20px 15px;
      opacity: 1;
      background: #ffffff;
    }
  }
  .dialog_title {
    height: 100px;
    text-align: center;
    padding: 20px;
  }
  /* 标题栏 */
  .header2 {
    height: 45px;
    width: 100%;
    position: fixed;
    left: 0;
    top: 48px;
    background: #13a8bf;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    .show-more {
      position: absolute;
      left: 24px;
    }
    .title {
      font-size: 17px;
      font-weight: bold;
      line-height: 17px;
      letter-spacing: 0em;
      font-family: Source Han Sans;
      font-feature-settings: "kern" on;
      color: #ffffff;
    }
  }
  /* 提示栏 */
  .prompt {
    height: 24px;
    width: 100%;
    position: fixed;
    left: 0;
    top: 93px;
    padding: 6px 15px;
    background: #fffaec;
    z-index: 100;
    .prompt_text {
      font-family: Source Han Sans;
      font-size: 12px;
      font-weight: normal;
      line-height: 12px;
      letter-spacing: 0em;

      font-feature-settings: "kern" on;
      color: #3d3d3d;
    }
  }
  .processing-container {
    position: fixed;
    left: 0px;
    top: 93px;
    width: 100%;
    height: 5px;
    opacity: 1;
    padding: 12px 12px;
    z-index: 100;
    display: fixed;
    align-items: center;
    justify-content: center;
    background: white;
    .processing-bg {
      left: 0;
      top: 0;
      background: #e0eaf3;
      width: 100%;
      height: 5px;
      border-radius: 100px;
    }
    .processing-bar {
      position: relative;
      left: 0;
      top: -5px;
      background: #13a8bf;
      height: 5px;
      border-radius: 100px;
    }
  }
  .spin-content {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  /* 主体内容 */
  .out-content {
    width: 100%;
    // height: 100%;
    // position: absolute;
    // left: 0;
    // top: 117px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    background: white;
    overflow-y: auto;
    margin-top: 150px;
    margin-bottom: 61px;
    padding-bottom: 61px;
    .card-content-inner {
      width: 100%;
      margin-top: 15px;
    }
  }
  .bottom_mar {
    margin-top: 15px;
    margin-bottom: 61px;
    font-size: 15px;
    text-align: center;
  }
  /deep/ .ant-checkbox-inner {
    width: 16px !important;
    height: 16px !important;
  }
  /* 底部按钮 */
  .bottom_bar {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 61px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    padding: 16px 15px 0 15px;
    border-radius: 16px;
    box-shadow: 0px 0px 6px 0px rgba(186, 192, 193, 0.3);
    .but {
      margin: 0 15px;
      flex: 1 0 auto;
      height: 41px;
      border-radius: 32px !important;
      font-family: SourceHanSansCN-Regular-Regular;
      font-size: 17px !important;
      font-weight: normal;
      line-height: 17px;
      letter-spacing: 0px;
      color: #13a8bf;
      border: 1px solid #13a8bf;
    }
    .but_color {
      color: #ffffff;
      background: #13a8bf;
    }
  }
  // 手机图标
  // .phone-icon-box {
  //   position: fixed;
  //   bottom: 100px;
  //   right: 10px;
  //   .phone-icon {
  //     /* 可以调整图标大小 */
  //     width: 40px;
  //     height: 40px;
  //   }
  // }
}
</style>
