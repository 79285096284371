<!--
报告详情(pdf预览)
-->
<template>
  <a-modal
    title="PDF预览"
    :visible="showVisiable"
    :footer="null"
    :width="950"
    @cancel="onClose"
  >
    <!-- <pdf v-for="item in numPages" :key="item" :page="item" :src="getPdfSrc()" ref="pdf"></pdf> -->
    <vue-office-pdf
        :src="pdfSrc"
        @rendered="renderedHandler"
        @error="errorHandler"
    />
    <template slot="footer">
      <a-button @click="onClose">关闭</a-button>
    </template>
  </a-modal>
</template>
<script>
import pdf from 'vue-pdf'
import VueOfficePdf from '@vue-office/pdf'

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 }
}
export default {
  name: 'PdfModal',
  components: {
    // pdf,
    VueOfficePdf
  },
  data () {
    return {
      loading: false,
      formItemLayout,
      form: {},
      showVisiable: false,
      pdfSrc: '',
      numPages: null // pdf 总页数
    }
  },
  created () {},
  mounted () {
    // this.getPdfSrc()
  },
  methods: {
    onClose () {
      this.showVisiable = false
    },
    renderedHandler () {
      this.$message.success('pdf加载成功')
    },
    errorHandler () {
      console.log('pdf加载失败')
    },
    getPdfSrc () {
      const loadingTask = pdf.createLoadingTask(
        {
          url: this.pdfSrc,
          cMapUrl: 'https://cdn.jsdelivr.net/npm/pdfjs-dist@2.5.207/cmaps/',
          cMapPacked: true
        }
      )
      loadingTask.promise.then(pdf => {
        this.numPages = pdf.numPages
      }).catch(err => {
        console.error('pdf 加载失败', err)
      })
      return loadingTask
    }
  }
}
</script>
<style scoped>
</style>
