<!--
背调列表
-->
<template>
  <div class="container">
    <div class="package-usage-title">
      <div class="package-title">单项风险统计</div>
    </div>
    <div class="package-usage-item">
      <div class="date-query">
        <a-range-picker
          :show-time="{ format: 'HH:mm' }"
          format="YYYY-MM-DD HH:mm"
          :placeholder="['开始时间', '结束时间']"
          v-model="rangetime"
          @change="handleTimeClear"
          @ok="handleTimeChange"
        />
        <a-button class="search-btn btn" type="primary" @click="search"
          >查询</a-button
        >
        <a-button class="btn" @click="handleExport">导出</a-button>
      </div>
      <!--  -->
      <div id="orderNum" :style="{ zoom: '125%' }" class="platform-item"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import * as echarts from 'echarts'
export default {
  name: 'SingleRiskStatistics',
  components: {
  },
  props: {
    chartData: {
      type: Object,
      default: () => { },
    },
  },
  data () {
    return {
      rangetime: [],
      orderNum:null
    }
  },
  computed: {
  },
  mounted () {
    setTimeout(() => {
      this.drawLine()
    }, 200)
  },
  methods: {
    drawLine () {
      // 基于准备好的dom，初始化echarts实例
      let orderNum = this.$echarts.init(document.getElementById('orderNum'))
      this.orderNum = orderNum
      const option = {
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          bottom: 16,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '14%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          // data: ['连续民事诉讼', '公民身份验证', '中国护照核查', '工作履历', '工作表现', '驾驶证核查', '海外教育背景核查']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '正常数',
            type: 'bar',
            stack: 'total',
            barWidth: 28,
            label: {
              show: true
            },
            color: '#0098FA',
            data: [320, 320, 320, 320, 320, 320, 320]
          },
          {
            name: '异常数',
            type: 'bar',
            stack: 'total',
            barWidth: 28,
            label: {
              show: true
            },
            color: '#0CD9B5',
            data: [320, 320, 320, 320, 320, 320, 320]
          },
        ]
      }
      option.xAxis.data = this.chartData.riskCheckItemList.map(item=>{
        return item.itemName
      })
      option.series[0].data = this.chartData.riskCheckItemList.map(item=>{
        return item.checkNormalNum
      })
      option.series[1].data = this.chartData.riskCheckItemList.map(item=>{
        return item.checkRiskNum
      })
      // 绘制图表
      orderNum.setOption(option)
    },
    handleTimeClear () {

    },
    handleTimeChange () {

    },
    search () {

    },
    handleExport () {
      const img = new Image()
      img.src = this.orderNum.getDataURL({
        type: 'png',
        pixelRatio: 1,
        backgroundColor: '#fff'
      })
      img.onload = function () {
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0)
        const dataUrl = canvas.toDataURL('image/png')
        const a = document.createElement('a')
        const event = new MouseEvent('click')
        a.download = "单项风险统计.png"
        a.href = dataUrl
        a.dispatchEvent(event)
        a.remove()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  .package-usage-title {
    display: flex;
    flex-direction: row;
    color: #0b2850;
    font-size: 18px;
    font-weight: bold;
    .package-title {
      margin-bottom: 12px;
    }
    .package-title:last-child {
      margin-right: 0;
    }
  }
  .package-usage-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px rgba(112, 144, 176, 0.12);
    .date-query {
      padding: 16px 32px 2px 0px;
      text-align: right;
      .search-btn {
        margin: 0 8px 0px 16px;
      }
      .btn {
        width: 88px;
      }
    }
    .platform-item {
      width: 100%;
      height: 400px;
    }
  }
}
</style>
