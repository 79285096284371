import { render, staticRenderFns } from "./HeaderMessage.vue?vue&type=template&id=790dd568&scoped=true"
import script from "./HeaderMessage.vue?vue&type=script&lang=js"
export * from "./HeaderMessage.vue?vue&type=script&lang=js"
import style0 from "./HeaderMessage.vue?vue&type=style&index=0&id=790dd568&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "790dd568",
  null
  
)

export default component.exports